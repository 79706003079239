import { colors } from '@/common/theme/variables'
import { VIOLATION_STATUSES } from '@/common/utils/const'

export const currentViolationStatus = (violation) => {
  return violation?.isPaid &&
    violation?.status === VIOLATION_STATUSES.PAID_IN_CASH
    ? VIOLATION_STATUSES.COMPLETE
    : violation?.status
}

// returns color acordingly to status
export const handleColorType = (status) => {
  switch (status) {
    case VIOLATION_STATUSES.NEW:
      return colors.darkBlue
    case VIOLATION_STATUSES.PAID_IN_CASH:
      return colors.yellowAction
    case VIOLATION_STATUSES.PENDING:
      return colors.orange
    case VIOLATION_STATUSES.COMPLETE:
      return colors.greenAction
    case VIOLATION_STATUSES.UNPAID:
      return colors.redAction
    default:
      return colors.darkGray
  }
}
