import { useMutation } from 'react-query'

import { accountApi } from '@/api'
import { runCallback } from '@/common/utils/helpers'

function useSignUp() {
  return useMutation(
    (values) => accountApi.signUp(values),
  )
}

function useChangePassword(successCallback) {
  return useMutation(() => accountApi.changePassword(), {
    onSuccess: (data) => {
      if (data.responseStatus === 200) {
        runCallback(() => {
          successCallback(data)
        })
      }
    },
  })
}


export {
  useSignUp,
  useChangePassword,
}