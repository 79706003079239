import * as yup from 'yup'

const lotRatesValidationSchema = yup.object().shape({
  validationCode: yup
    .string()
    .required('Validation Code Required')
    .typeError('Validation Code Required'),
  licensePlate: yup
    .string()
    .required('License Plate Number Required')
    .typeError('License Plate Number Required'),
})

export default lotRatesValidationSchema
