import { withQuery } from '@/common/utils/helpers'

function registerLotMessagesApi(api) {
  return {
    getMessages: (query) => api.get(withQuery(`/message`, query)),
    export: (query) => 
      api.get(
        withQuery(`/message/lot/${query.LotId}/export`, query), 
        {responseType: 'blob'}
      ),
    getAllMessageTemplates: () => api.get('/message-template'),
    getTemplate: (id) => api.get(`/message-template/${id}`),
    update: (data) => api.put('/message-template/lot', data),
  }
}

export default registerLotMessagesApi