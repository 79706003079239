import { withQuery } from '@/common/utils/helpers'

function registerValidationsApi(api) {
  return {
    get: (id) => api.get(`/validation/${id}`),
    create: (data) => api.post(`/validation`, data),
    update: (id, data) => api.put(`/validation/${id}`, data),//add lotId in data
    delete: (lotId, id) => api.delete(withQuery(`/validation/${id}`, lotId)),
  }
}

export default registerValidationsApi
