/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import Input from '@/components/Input'

const PhoneNumberInput = forwardRef((props, ref) => {
  const { value, ...rest } = props

  const isMonthStartsFromZero = String(value?.[0]) === '0'
  const isMonthStartsFromOne = String(value?.[0]) === '1'
  const isDayStartsFromThree = String(value?.[3]) === '3'

  const dateMask = [
    /[0-1]/,
    ...(isMonthStartsFromZero
      ? [/[0-9]/]
      : isMonthStartsFromOne
      ? [/[0-2]/]
      : [/[0-9]/]),
    '/',
    /[0-3]/,
    ...(isDayStartsFromThree ? [/[0-1]/] : [/[0-9]/]),
    '/',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ]

  return (
    <Input
      ref={ref}
      {...rest}
      value={value}
      mask={dateMask}
      maskPlaceholder={'MM/DD/YYYY'}
    />
  )
})

PhoneNumberInput.propTypes = {
  phoneNumberValue: PropTypes.string,
}

export default PhoneNumberInput
