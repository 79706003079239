import * as yup from 'yup'

const filterSchema = () => {
  return yup.object().shape({
    dateFrom: yup.string().dateValidation(yup.ref('dateTo')),
    dateTo: yup.string().dateValidation(yup.ref('dateFrom'), true),
  })
}

export default filterSchema
