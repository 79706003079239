import React, { useState } from 'react'

import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import { IconBarsLight } from '@/common/assets/icons'
import { DarkLogo } from '@/common/assets/images'
import { Image } from '@/common/baseStyledElements'
import useModal from '@/common/hooks/useModal'
import modalKeys from '@/common/utils/const/modalKeys'
import Button from '@/components/Button'
import UserProfile from '@/components/UserProfile'

import Sidebar from '../Sidebar'
import OpenAppModal from './OpenAppModal'
import { 
  Container, 
  BarContainer, 
  IconContainer, 
  LogoContainer, 
  HeadText 
} from './styled'

function Header({ anonym, isOfficer, isReferral, isCustomer }) {
  const [showSidebar, setShowSidebar] = useState(false)

  const modal = useModal(modalKeys.OPEN_APP)

  const closeSidebar = () => {
    setShowSidebar(!showSidebar)
  }

  if (anonym)
    return (
      <>
        <OpenAppModal id={modal.id}/>
        {/*<Container>
          <HeadText>Open in Application</HeadText>
          <Button
            $textAlignStyle="center"
            $width={'auto'}
            text={'Open'}
            onClick={modal.show}
          />
    </Container>*/}
        <Container>
          <BarContainer>
            <LogoContainer>
              <Image src={DarkLogo} $marginLeft={16} />
            </LogoContainer>
          </BarContainer>
        </Container>
      </>
    )

  return (
    <>
      <OpenAppModal id={modal.id}/>
      {(isOfficer) && 
        <Container>
          <HeadText>Open in Application</HeadText>
          <Button
            $textAlignStyle="center"
            $width={'auto'}
            text={'Open'}
            onClick={modal.show}
          />
        </Container>
      }  
      <Container>
        <BarContainer>
          {!isReferral && 
            <IconContainer onClick={closeSidebar}>
              <SVG src={IconBarsLight} />
            </IconContainer>
          }
          {!isOfficer && (
            <LogoContainer>
              <Image src={DarkLogo} />
            </LogoContainer>
          )}
        </BarContainer>

        <UserProfile />
      </Container>
      {!isReferral && <Sidebar open={showSidebar} close={closeSidebar} />}
    </>
  )
}

Header.propTypes = {
  anonym: PropTypes.bool,
  isOfficer: PropTypes.bool,
  isReferral: PropTypes.bool,
  isCustomer: PropTypes.bool,
}

Header.defaultProps = {
  anonym: false,
  isOfficer: false,
  isReferral: false,
  isCustomer: false,
}

export default Header
