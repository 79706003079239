import styled from 'styled-components'

import { colors } from '@/common/theme/variables'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 20px;
`

export const MainText = styled.div`
  color: ${colors.activeSidebarElem};
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: 24px;
`

export const DescriptionText = styled.div`
  margin-top: 8px;
`
