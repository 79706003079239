const FORMATS = {
  DATE_FORMAT: 'MM/dd/yyyy',
  SIMPLE_DATE_FORMAT: 'yyyy-MM-dd',
  MM_DD_YYYY_DOT: 'MM.dd.yyyy',
  TIME_FORMAT: "HH:mm",
  
  BY_SHORT_WEEK: "EEEE",
  BY_SHORT_MONTH: "MMM",
  BY_DATE_OF_WEEK: "e",
  BY_DATE_OF_MONTH: "d",
  DATE_TIME_FORMAT: "yyyy-MM-dd'_'HH:mm:ss",

  SOLDS_TOOLTIP_FORMAT: 'yyyy MMMM do'
}

export default FORMATS