import { useMutation, useQueryClient } from 'react-query'

import { paymentApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { USER_BILLING_HISTORY } = QUERY_KEYS

/**
 * Create Payment Hold
 * @returns useMutation instance
 */
function useHoldPayment() {
  return useMutation((data) => paymentApi.holdCreate(data))
}

/**
 * Create Payment Violation
 * @returns useMutation instance
 */
function usePaymentViolation() {
  return useMutation((data) => paymentApi.paymentViolation(data))
}

/**
 * Get export file fees report by referral
 * @param query
 * @returns useQuery instance
 */
function useFeesReport(successCallback) {
  return useMutation((query) => paymentApi.getFeesReport(query), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        successCallback(data)
      }
    },
  })
}

/**
 * Delete payment
 * @param successCallback
 * @returns useMutation instance
 */
function usePaymentDelete(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => paymentApi.deletePayment(id), {
    onMutate: async () => {
      await queryClient.cancelQueries(USER_BILLING_HISTORY)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
        await queryClient.invalidateQueries(USER_BILLING_HISTORY)
      }
    },
  })
}

export { useHoldPayment, usePaymentViolation, useFeesReport, usePaymentDelete }
