/* eslint-disable no-unused-vars */
import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { BaseInput } from '@/common/baseStyledElements'

const validate = (evt) => {
  var theEvent = evt || window.event
  var key = theEvent.keyCode || theEvent.which
  key = String.fromCharCode(key)
  if (key == 'e') {
    theEvent.returnValue = false
    if (theEvent.preventDefault) theEvent.preventDefault()
  }
}

const NumberInput = forwardRef(({ setValue, ...rest }, ref) => {
  return (
    <BaseInput
      type={'number'}
      ref={ref}
      min={0}
      {...rest}
      onKeyPress={validate}
    />
  )
})

NumberInput.propTypes = {
  setValue: PropTypes.func, // automatically passed with HOC *withExtraProps*
}

export default NumberInput
