function registerBankAccountApi(api) {
  return {
    get: (id) => api.get(`/bank-account/${id}`),
    getByOwner: (id) => api.get(`/bank-account/by-owner/${id}`),
    create: (data) => api.post(`/bank-account`, data),
    update: (id, data) => api.put(`/bank-account/${id}`, data),
    delete: (id) => api.delete(`/bank-account/${id}`),
  }
}

export default registerBankAccountApi