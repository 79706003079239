import { css } from 'styled-components'

const colorStyle = css`
  ${({ $color }) => {
    return $color && `color: ${$color}`
  }};
  ${({ $backgroundColor }) => {
    return $backgroundColor && `background-color: ${$backgroundColor}`
  }};
`

export default colorStyle
