import * as yup from 'yup'

const ltpContractsFilterSchema = yup.object().shape({
  StartDateFrom: yup.string().dateValidation(yup.ref('StartDateTo')),
  StartDateTo: yup.string().dateValidation(yup.ref('StartDateFrom'), true),
  ExpirationDateFrom: yup.string().dateValidation(yup.ref('ExpirationDateTo')),
  ExpirationDateTo: yup
    .string()
    .dateValidation(yup.ref('ExpirationDateFrom'), true),
})

export default ltpContractsFilterSchema
