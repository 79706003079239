import { toast } from 'react-toastify'

import api from '@/api/config'
import { deleteAllCookies } from '@/common/utils/helpers'

export function registerRequestInterceptor(accessToken) {
  api.interceptors.request.use((request) => {
    request.headers['Authorization'] = `Bearer ${accessToken}`
    return request
  })
}

export function registerResponseInterceptors() {
  api.interceptors.response.use(
    (response) => {
      let data = {}
      if (response?.data && typeof response?.data == 'object') {
        let reg = new RegExp('export|qrcode|report/referral')
        if (reg.test(response.config.url)) {
          data.data = response?.data
          data.responseHeaders = response?.headers
        } else data = response?.data
      } else if (response?.status === 200) data.data = response?.data
      data.responseStatus = response?.status || 0

      return data
    },
    (error) => {
      if (!error.response) return null

      if (error?.response?.status === 401) {
        deleteAllCookies()
        window.location.href = '/'
      }

      const {
        response: { data },
      } = error
      if (typeof data == 'object')
        Object.keys(data).forEach((key) => {
          const message = data[key][0]
          if (message) {
            toast.error(message)
          }
        })
      return error
    },
  )
}
