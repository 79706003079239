import { useQuery, useMutation, useQueryClient } from 'react-query'

import { permitStoreApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { ACTIVE_PASS, RATES_NOW, LOT_NAME } = QUERY_KEYS

/**
 * Fetch all rates for now
 * @param lotId
 * @returns useQuery instance
 */
function useRatesNow(query, enabled = true) {
  const { lotId, ...rest } = query
  return useQuery(
    [RATES_NOW, lotId, rest],
    () => permitStoreApi.getRatesForNow(lotId, rest),
    { enabled },
  )
}

/**
 * Fetch all rates for now
 * @param lotId
 * @returns useQuery instance
 */
 function useLotName(lotId) {
  return useQuery(
    [LOT_NAME, lotId],
    () => permitStoreApi.getLotName(lotId),
    )
}

/**
 * Fetch active pass for user
 * @param lotId
 * @param query
 * @returns useQuery instance
 */
function useActivePass(lotId, query, enabled = false) {
  return useQuery(
    [ACTIVE_PASS, lotId, query],
    () => permitStoreApi.getActivePass(lotId, query),
    { enabled },
  )
}

/**
 * Fetch active pass for user
 * @param successCallback
 * @returns useMutation instance
 */
function useValidationCode(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => permitStoreApi.postValidationCode(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(ACTIVE_PASS)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.invalidateQueries(ACTIVE_PASS)
        runCallback(successCallback)
      }
    },
  })
}

export { useRatesNow, useActivePass, useValidationCode, useLotName }
