import React from 'react'

import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { useDictionary } from '@/common/services/dictionaryService'
import {
  useUserCurrent,
  useUserEnforcerActions,
} from '@/common/services/userService'
import { theme } from '@/common/theme/variables'
import { isUserProfileNotFilled } from '@/common/utils/helpers'
import { 
  isViolationOfficer, 
  isCustomer, 
  isReferral 
} from '@/common/utils/roleAccesses'
import Header from '@/components/Header'
import LoadingScreen from '@/components/LoadingScreen'
import UserDetails from '@/pages/Profile/pages/UserDetails'

import ProtectedRoutes from '../../router/ProtectedRoutes'
import { Container, Content, ContentContainer } from './styled'

function HomeLayout(props) {
  const { isSignUp } = props

  const { data: user, isLoading } = useUserCurrent(isSignUp)
  const isOfficer = isViolationOfficer(user?.role)
  const isConsumer = isCustomer(user?.role)
  const isReferralUser = isReferral(user?.role)
  const isProfileNotFilled = isUserProfileNotFilled(user)
  const userApp = isOfficer || isConsumer

  const { isLoading: isLoadingEnforcerActions } = useUserEnforcerActions(
    isSignUp && !!isOfficer,
  )
  useDictionary()

  if (isLoading || isLoadingEnforcerActions || !isSignUp)
    return <LoadingScreen />

  if (isConsumer && isProfileNotFilled)
    return (
      <Container>
        <ThemeProvider theme={{}}>
          <Content>
            <ContentContainer>
              <UserDetails isProfileNotFilled={isProfileNotFilled} />
            </ContentContainer>
          </Content>
        </ThemeProvider>
      </Container>
    )

  return (
    <Container>
      <ThemeProvider theme={userApp ? theme?.userTheme : {}}>
        <Content>
          <Header 
            isOfficer={isOfficer} 
            isReferral={isReferralUser} 
            isCustomer={isConsumer} 
          />
          <ContentContainer>
            <Switch>
              <ProtectedRoutes
                isAuthenticated={!!user}
                isOfficer={isOfficer}
                isCustomer={isConsumer}
                userRole={user?.role?.name}
              />
            </Switch>
          </ContentContainer>
        </Content>
      </ThemeProvider>
    </Container>
  )
}

HomeLayout.propTypes = {
  children: PropTypes.any,
  isSignUp: PropTypes.bool,
}

HomeLayout.defaultProps = {
  children: null,
  isSignUp: false,
}

export default HomeLayout
