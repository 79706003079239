import React from 'react'

import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { IconProfileSettings } from '@/common/assets/icons'
import { CommonContainer } from '@/common/baseStyledElements'
import routes from '@/common/router/routes'
import { useUserCurrent, useUpdateUserCurrent } from 
'@/common/services'
import { toPhoneWithCountryCode } from '@/common/utils/helpers'
import { isViolationOfficer } from '@/common/utils/roleAccesses'

import PagePreview from '../components/pagePreview'
import PersonalDetailsForm from '../components/PersonalDetailsForm'

UserDetails.propTypes = {
  isProfileNotFilled: PropTypes.bool
}

function UserDetails({isProfileNotFilled}) {
  const history = useHistory()

  const {data: user, isLoading} = useUserCurrent()

  const isOfficer = isViolationOfficer(user?.role)

  const successUpdateUser = () => {
    toast.success('User updated')
    isProfileNotFilled
      ? history.push(`${routes.violationsSearch}`)
      : history.goBack()
  }

  const updatedUser = useUpdateUserCurrent(successUpdateUser)

  /**
   * Update edit when we click on save button
   * @param values
   */
  const onUpdate = (values) => {
    const plates = values.associatedPlates.map((item) => item.value)
    const address = {
      countryId: values.countryId,
      stateId: values.stateId,
      city: values.city,
      postalCode: values.postalCode,
      addressLine: values.addressLine,
      addressLine2: values.addressLine2,
    }
    
    if (!isOfficer) {
      updatedUser.mutate({
        ...values,
        phone: toPhoneWithCountryCode(values.phone),      
        associatedPlates: plates,
        address
      })
    } else {
      updatedUser.mutate({
        ...values,
        phone: toPhoneWithCountryCode(values.phone),      
      })
    }
  }

  return (
    <>
      <CommonContainer
        $width={688}
        $margin={'0 auto'}
        $padding={'36px 144px 24px 144px'}
        $display={'flex'}
        $flexDirection={'column'}
        $alignItems={'center'}
      >
        <PagePreview
          text={'Personal details'}
          description={'Enter your personal information here.'}
          svg={IconProfileSettings}
        />
        <PersonalDetailsForm 
          onSubmit={onUpdate} 
          user={user} 
          isLoading={isLoading}
        />
      </CommonContainer>
    </>
  )
}

export default UserDetails
