import { useQuery, useMutation, useQueryClient } from 'react-query'

import { ltpCustomersApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { LTP_CUSTOMERS, LTP_ADD_CUSTOMERS } = QUERY_KEYS

/**
 * Fetch all customers
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useLtpCustomers(query) {
  return useQuery([LTP_CUSTOMERS, query], () => ltpCustomersApi.getAll(query))
}

/**
 * Fetch customers by email or phoneNubmer
 * @param query
 * @returns success callback
 */
function useLtpCustomerByQuery(successCallback) {
  return useMutation(async (query) => {
    const res = await ltpCustomersApi.getByEmailOrPhoneNumber(query)
    return successCallback(res)
  })
}

function useCreateLtpCustomer(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => ltpCustomersApi.createLtpCustomer(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(LTP_ADD_CUSTOMERS)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
        await queryClient.refetchQueries(LTP_ADD_CUSTOMERS)
      }
    },
  })
}

/**
 * Get export file lots by query
 * @param query
 * @returns useQuery instance
 */
function useLtpCustomersExport(successCallback) {
  return useMutation(
    (query) => ltpCustomersApi.export(query),
    {
      onSuccess: async (data) => {
        if(data.responseStatus === 200){
          successCallback(data)
        }
      },
    }
  )
}

export {
  useLtpCustomers,
  useLtpCustomerByQuery,
  useLtpCustomersExport,
  useCreateLtpCustomer,
}
