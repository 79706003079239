import { withQuery } from '@/common/utils/helpers'

function registerEnforcementCompanyApi(api) {
  return {
    getCompanies: (query) => api.get(withQuery('/enforcement-company', query)),
    getCompany: (id) => api.get(`/enforcement-company/${id}`),
    createCompany: (data) => api.post('/enforcement-company', data),
    updateCompany: (id, data) => api.put(`/enforcement-company/${id}`, data),
    deleteCompany: (id) => api.delete(`/enforcement-company/${id}`),
    getMyCompany: () => api.get(`/enforcement-company/my-company`),
    export: (query) => 
      api.get(
        withQuery(`/enforcement-company/export`, query), {responseType: 'blob'}
      ),
  }
}

export default registerEnforcementCompanyApi