// eslint-disable-next-line
const phoneRegExp =
  /^\((\d{3})\)\s(\d{3})-(\d{4})$|^\(\+(\d{2})\s\((\d{3})\)\s(\d{3})-(\d{4})$/
///^\((\d{3})\)\s(\d{3})\-(\d{4})$|^\(\+(\d{2})\s\((\d{3})\)\s(\d{3})\-(\d{4})$/

const postalRegExp = /^[0-9]{5}(?:[-\s]?[0-9]{4})?$/

const emailRegExp =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const websiteRegExp =
  // eslint-disable-next-line max-len
  /^((ftp|http|https):\/\/)(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/

const digitRegExp = /^[0-9]+$/

const floatHulfHours = /^(0{1}|[0-9]{1,2})([.]5$|[.]0$|$)/

const HoursMoreDay = /(^24|48|72|96|120|144|168)$/

export default {
  phoneRegExp,
  emailRegExp,
  postalRegExp,
  websiteRegExp,
  digitRegExp,
  floatHulfHours,
  HoursMoreDay,
}
