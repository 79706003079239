import * as yup from 'yup'

import regExpValidations from '@/common/utils/const/regExp'

const LotRatesMockup = yup.object().shape({
  phoneNumber: yup
    .string()
    .required('Phone number required')
    .matches(regExpValidations.phoneRegExp, 'Phone number is not valid'),
  licensePlate: yup
    .string()
    .typeError('License Plate Number should be valid')
    .required('License Plate Number required'),
})

export default LotRatesMockup
