import { useMutation } from 'react-query'

import { QRcodeApi } from '@/api'

/**
 * Get QR code by lot ID
 * @param lotId for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useQRcode(successCallback) {
  return useMutation(
    (lotId) => QRcodeApi.getQRcode(lotId), 
    {
      onSuccess: (data) => {
        if(data.responseStatus === 200){
          successCallback(data)
        }
      },
    }
  )
}

export {
  useQRcode
}