import { useQuery, useQueryClient, useMutation} from 'react-query'

import { lotMessagesApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { LOT_MESSAGES, 
  MESSAGE_TEMPLATES,
  MESSAGE_TEMPLATE_INFO 
} = QUERY_KEYS

/**
 * Fetch all messages for lot
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useLotMessages(query) {
  return useQuery(
    [ LOT_MESSAGES, query ],
    () => lotMessagesApi.getMessages(query),
  )
}

/**
 * Get export file messages by query
 * @param query
 * @returns useQuery instance
 */
function useLotMessagesExport(successCallback) {
  return useMutation(
    (query) => lotMessagesApi.export(query),
    {
      onSuccess: async (data) => {
        if(data.responseStatus === 200){
          successCallback(data)
        }
      },
    }
  )
}

function useGetAllMessageTemplates() {
  return useQuery(
    [ MESSAGE_TEMPLATES ],
    () => lotMessagesApi.getAllMessageTemplates(),
  )
}

/**
 * Fetch template by id
 * @param templateId
 * @returns useQuery instance
 */
 function useTemplate(templateId) {
  return useQuery(
    [ MESSAGE_TEMPLATE_INFO, templateId ],
    () => lotMessagesApi.getTemplate(templateId),
  )
}

/**
 * Update template
 * @param successCallback
 * @returns useMutation instance
 */
 function useUpdateTemplate(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => lotMessagesApi.update(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(MESSAGE_TEMPLATES)
      await queryClient.cancelQueries(MESSAGE_TEMPLATE_INFO)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.refetchQueries(MESSAGE_TEMPLATES)
        await queryClient.refetchQueries(MESSAGE_TEMPLATE_INFO)
        runCallback(successCallback)
      }
    },
  })
}

export {
  useLotMessages,
  useLotMessagesExport,
  useGetAllMessageTemplates,
  useTemplate,
  useUpdateTemplate
}