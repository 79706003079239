import React from 'react'

import PropTypes from 'prop-types'
import { Redirect, Route, useLocation, Switch } from 'react-router-dom'

import routes from './routes'

const Profile = React.lazy(() => import('@/pages/Profile'))
const Dashboard = React.lazy(() => import('@/pages/Dashboard'))
const LotManagement = React.lazy(() => import('@/pages/LotManagement'))
const Violations = React.lazy(() => import('@/pages/Violations'))
const LTC = React.lazy(() => import('@/pages/LongtermContracts'))
const AdminPanel = React.lazy(() => import('@/pages/AdminPanel'))
const UserList = React.lazy(() => import('@/pages/UserList'))
const AccessDenied = React.lazy(() => import('@/pages/AccessDenied'))
const Messages = React.lazy(() => import('@/pages/Messages'))
const ViolationsCustomer = React.lazy(() =>
  import('@/pages/ViolationsCustomer'),
)
//const PermitStore = React.lazy(() => import('@/pages/PermitStoreCustomer'))
const MyPermits = React.lazy(() => import('@/pages/MyPermitsCustomer'))
const LTCCustomer = React.lazy(() =>
  import('@/pages/CustomerLongTermContracts'),
)
const BillingHistory = React.lazy(() => import('@/pages/BillingHistory'))
const ParkingLots = React.lazy(() => import('@/pages/ParkingLots'))
const PlateSearch = React.lazy(() => import('@/pages/PlateSearch'))
const ViolationsOfficer = React.lazy(() => import('@/pages/OfficerViolations'))
const NotFound = React.lazy(() => import('@/pages/NotFound'))

const PERMISSIONS = {
  violations: ['Violation Officer', 'PP Admin', 'Lot Super Manager'],
  LTC: ['PP Admin', 'Lot Super Manager'],
  messages: ['PP Admin', 'Lot Super Manager', 'Lot Manager', 'PP User'],
  adminPanel: ['PP Admin'],
  userList: [
    'PP Admin',
    'Lot Super Manager',
    'Lot Manager',
    'PP User',
    'Lot Owner',
  ],
  violationsCustomer: ['Customer'],
  permitStore: ['Customer'],
  myPermits: ['Customer'],
  LTCCustomer: ['Customer'],
  billingHistory: ['Customer'],
  parkingLots: ['Violation Officer'],
  plateSearch: ['Violation Officer'],
  violationsOfficer: ['Violation Officer'],
}

ProtectedRoutes.propTypes = {
  isAuthenticated: PropTypes.bool,
  isOfficer: PropTypes.bool,
  isCustomer: PropTypes.bool,
  userRole: PropTypes.string,
}

ProtectedRoutes.defaultProps = {
  isAuthenticated: false,
  isOfficer: false,
  isCustomer: false,
  userRole: '',
}

function ProtectedRoutes(props) {
  const { isAuthenticated, userRole, isOfficer, isCustomer } = props

  const location = useLocation()
  const needRedirect =
    location.pathname == '/dashboard' || location.pathname == '/signIn'
  const needNotFound = !(
    location.pathname.includes(routes.permitStoreAnonym) ||
    location.pathname.includes(routes.signOut)
  )
  const checkPermissions = (permissions, Component) => {
    if (!permissions.includes(userRole))
      return <Redirect to={routes.accessDenied} />

    return <Component />
  }

  if (!isAuthenticated) return <Redirect to={routes.signIn} />

  if (isCustomer || userRole == 'Referral')
    return (
      <Switch>
        <Route path={routes.userProfile}>
          <Profile />
        </Route>
        <Route path={routes.violationsCustomer}>
          <ViolationsCustomer />
        </Route>
        {/*<Route path={routes.permitStoreCustomer}>
          <PermitStore />
    </Route>*/}
        <Route path={routes.myPermitsCustomer}>
          <MyPermits />
        </Route>
        <Route path={routes.customerLtp}>
          <LTCCustomer />
        </Route>
        <Route path={routes.billingHistory}>
          <BillingHistory />
        </Route>
        <Route path={routes.notFound}>
          <NotFound />
        </Route>
        {needRedirect && <Redirect to={routes.userProfile} />}
        {needNotFound && (
          <Route path={'*'}>
            <Redirect to={routes.notFound} />
          </Route>
        )}
      </Switch>
    )

  if (isOfficer)
    return (
      <Switch>
        <Route path={routes.userProfile}>
          <Profile />
        </Route>
        <Route path={routes.parkingLots}>
          <ParkingLots />
        </Route>
        <Route path={routes.plateSearch}>
          <PlateSearch />
        </Route>
        <Route path={routes.officerViolationsList}>
          <ViolationsOfficer />
        </Route>
        <Route path={routes.notFound}>
          <NotFound />
        </Route>
        {needRedirect && <Redirect to={routes.parkingLots} />}
        {needNotFound && (
          <Route path={'*'}>
            <Redirect to={routes.notFound} />
          </Route>
        )}
      </Switch>
    )

  return (
    <Switch>
      <Route path={routes.userProfile}>
        <Profile />
      </Route>
      <Route path={routes.dashboard} exact>
        <Dashboard />
      </Route>
      <Route path={routes.lotManagement}>
        <LotManagement />
      </Route>
      <Route path={routes.adminPanel}>
        {checkPermissions(PERMISSIONS.adminPanel, AdminPanel)}
      </Route>
      <Route path={routes.userList}>
        {checkPermissions(PERMISSIONS.userList, UserList)}
      </Route>
      <Route path={routes.violations}>
        {checkPermissions(PERMISSIONS.violations, Violations)}
      </Route>
      <Route path={routes.ltp}>{checkPermissions(PERMISSIONS.LTC, LTC)}</Route>
      <Route path={routes.messages}>
        {checkPermissions(PERMISSIONS.messages, Messages)}
      </Route>
      <Route path={routes.accessDenied} exact>
        <AccessDenied />
      </Route>
      <Route path={routes.notFound}>
        <NotFound />
      </Route>
      {needRedirect && <Redirect to={routes.dashboard} />}
      {needNotFound && (
        <Route path={'*'}>
          <Redirect to={routes.notFound} />
        </Route>
      )}
    </Switch>
  )
}

export default ProtectedRoutes
