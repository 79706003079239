import React from 'react'

import PropTypes from 'prop-types'

import { Logo, AuthImage, DarkLogo } from '@/common/assets/images'
import { Image } from '@/common/baseStyledElements'

import { 
  Container,
  AuthContentWrapper,
  AuthContent,
  AuthContentChildren, 
  AuthImageContainer, 
  LogoContainer, 
  HeaderLogoContainer 
} from './styled'

function AuthLayout(props) {
  return (
    <Container>
      <AuthImageContainer src={AuthImage}>
        <Image src={AuthImage} objectFit={'cover'}/>
        <LogoContainer> 
          <img src={Logo} />
        </LogoContainer>
      </AuthImageContainer>
      <AuthContentWrapper>
        <HeaderLogoContainer>
          <Image src={DarkLogo} />
        </HeaderLogoContainer>
        <AuthContent>
          <AuthContentChildren>
            {props.children}
          </AuthContentChildren>
        </AuthContent>
      </AuthContentWrapper>
    </Container>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.any,
}

AuthLayout.defaultProps = {
  children: null,
}

export default AuthLayout
