import { colors } from '@/common/theme/variables'

const LTP_CONTRACT_TYPES = [
  { name: 'Created', contractStatusId: 1, color: colors.darkBlue },
  { name: 'Signed', contractStatusId: 2, color: colors.orange },
  { name: 'Paid', contractStatusId: 3, color: colors.grassGreen },
  { name: 'Rejected', contractStatusId: 4, color: colors.redAction },
  { name: 'All', contractStatusId: '' },
]

export default LTP_CONTRACT_TYPES