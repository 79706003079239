import { css } from 'styled-components'

import colorStyle from './colorStyle'
import commonStyle from './commonStyle'
import dimensionStyle from './dimensionStyle'
import flexStyle from './flexStyle'
import marginStyle from './marginStyle'
import paddingStyle from './paddingStyle'
import positionStyle from './positionStyle'

const baseStyles = css`
  ${marginStyle};
  ${paddingStyle};
  ${dimensionStyle};
  ${flexStyle};
  ${colorStyle};
  ${positionStyle};
  ${commonStyle};
`

export default baseStyles
