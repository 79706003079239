const VIOLATION_STATUSES = {
  NEW: 'Violation Added',
  PAID_IN_CASH: 'Paid in cash',
  COMPLETE: 'Paid',
  PENDING: 'Pending',
  UNPAID: 'Unpaid',
  VOIDED: 'Voided',
  CANCELED: 'Canceled',
}
export default VIOLATION_STATUSES
