function registerLotFileApi(api) {
  return {
    getAll: () => api.get(`/file`),
    get: (id, options) => api.get(`/file/${id}`, options),
    create: (data, options) => api.post(`/file`, data, options),
    update: (id, data) => api.put(`/file/${id}`, data),
    delete: (id) => api.delete(`/file/${id}`),
  }
}

export default registerLotFileApi