import { colors } from '@/common/theme/variables'

const userTheme = {
  name: 'userApp',
  background: colors.background,
  backgroundTabs: colors.black
}

export default { userTheme };

