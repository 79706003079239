import { withQuery } from '@/common/utils/helpers'

function registerLotViolationActionsApi(api) {
  return {
    getAllViolations: (query) => api.get(withQuery('/violation-action', query)),
    getViolationsCustomer: (query) =>
      api.get(withQuery('/violation-action/customer', query)),
    getViolationsSearch: (query) =>
      api.get(withQuery('/violation-action/search', query)),
    getViolation: (id) => api.get(`/violation-action/${id}`),
    getViolationActions: (id, query) =>
      api.get(withQuery(`/violation-action/lot/${id}`, query)),
    export: (query) =>
      api.get(withQuery(`/violation-action/export`, query), {
        responseType: 'blob',
      }),
    searchPlates: (lotId, query) =>
      api.get(withQuery(`/violation-action/search-plates/${lotId}`, query)),
    create: (data) => api.post(`/violation-action`, data),
    delete: ({ id, ...query }) =>
      api.delete(withQuery(`/violation-action/${id}`, query)),
    setPaidInCash: ({ id, ...query }) =>
      api.put(withQuery(`/violation-action/${id}/set-paid-in-cash`, query)),
    setPaymentComplete: ({ id, paymentData, ...data }) =>
      api.put(
        withQuery(`/violation-action/${id}/set-payment-complete`, data),
        paymentData,
      ),
    setRemoveBoot: (id, lotId, notes) =>
      api.put(
        withQuery(`/violation-action/${id}/remove-boot`, { lotId, notes }),
      ),
    getStatuses: () => api.get('/violation-action/status'),
    changeCashReceive: ({ id, ...rest }) =>
      api.put(withQuery(`/violation-action/${id}/change-cash-received`, rest)),
    callOfficer: (id) => api.put(`/violation-action/${id}/call-officer`),
    setInProgress: ({ id, lotId }) =>
      api.put(withQuery(`/violation-action/${id}/set-in-progress`, { lotId })),
  }
}

export default registerLotViolationActionsApi
