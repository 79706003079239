import styled from "styled-components"

import { colors } from '@/common/theme/variables'

const DownloadButton = styled.div`
  cursor: pointer;
  color: ${colors.azure};
  text-decoration: underline;
  text-decoration-color: ${colors.azure};
`

export default DownloadButton