import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from 'react-query'
import { toast } from 'react-toastify'

import { ltpContractsApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { LTP_CONTRACTS, LTP_CONTRACTS_INFO, LTP_CUSTOM_PAGE } = QUERY_KEYS

/**
 * Fetch all contracts for lot
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useLtpContracts(query) {
  return useQuery([LTP_CONTRACTS, query], () => ltpContractsApi.getAll(query))
}

/**
 * Fetch all contracts for lot with infinite scroll methods
 * @param query for using pagination, sort, search and filters
 * @returns useInfiniteQuery instance
 */
function useLtpContractsCustomer(query) {
  return useInfiniteQuery(
    [LTP_CONTRACTS, query],
    (param) =>
      ltpContractsApi.getAll({
        ...query,
        'Pagination.page': param?.pageParam ?? 1,
      }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.pageIndex < lastPage.totalPages
          ? lastPage.pageIndex + 1
          : undefined,
    },
  )
}

/**
 * Fetch contract by id
 * @param id
 * @returns useQuery instance
 */
function useLtpContract(id) {
  return useQuery([LTP_CONTRACTS_INFO, id], () =>
    ltpContractsApi.getContract(id),
  )
}

/**
 * Create contract
 * @param successCallback
 * @returns useMutation instance
 */
function useCreateLtpContract(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => ltpContractsApi.create(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(LTP_CONTRACTS)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
        await queryClient.invalidateQueries(LTP_CONTRACTS)
      }
    },
  })
}

/**
 * Update contract
 * @param successCallback
 * @returns useMutation instance
 */
function useUpdateLtpContract(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => ltpContractsApi.update(data.id, data), {
    onMutate: async () => {
      await queryClient.cancelQueries(LTP_CONTRACTS)
      await queryClient.cancelQueries(LTP_CONTRACTS_INFO)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.invalidateQueries(LTP_CONTRACTS)
        await queryClient.invalidateQueries(LTP_CONTRACTS_INFO)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Delete contract
 * @param successCallback
 * @returns useMutation instance
 */
function useDeleteLtpContract(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => ltpContractsApi.delete(id), {
    onMutate: async () => {
      await queryClient.cancelQueries(LTP_CONTRACTS)
    },
    onSettled: async (data) => {
      if (data.responseStatus === 200) {
        toast.success('Contract deleted!')
        await queryClient.invalidateQueries(LTP_CONTRACTS)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Get export file contracts by query
 * @param query
 * @returns useQuery instance
 */
function useLtpContractsExport(successCallback) {
  return useMutation((query) => ltpContractsApi.export(query), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        successCallback(data)
      }
    },
  })
}

/**
 * Sign contract
 * @param successCallback
 * @returns useMutation instance
 */
function useSignLtpContract(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => ltpContractsApi.sign(id), {
    onMutate: async () => {
      await queryClient.cancelQueries(LTP_CONTRACTS_INFO)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.invalidateQueries(LTP_CONTRACTS_INFO)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Reject contract
 * @param successCallback
 * @returns useMutation instance
 */
function useRejectLtpContract(successCallback) {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, rejectReason }) => ltpContractsApi.reject(id, { rejectReason }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(LTP_CONTRACTS_INFO)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          await queryClient.invalidateQueries(LTP_CONTRACTS_INFO)
          runCallback(successCallback)
        }
      },
    },
  )
}

/**
 * Create Payment Contract
 * @returns useMutation instance
 */
function usePaymentContract() {
  return useMutation((data) => ltpContractsApi.payContract(data))
}

/**
 * Delete Subscription Contract
 * @returns useMutation instance
 */
function useDeleteSubscription(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => ltpContractsApi.deleteSubscription(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(LTP_CONTRACTS)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.invalidateQueries(LTP_CONTRACTS)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Fetch custom page for lot
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useLtpCustomPage(lotId, enabled = false) {
  return useQuery(
    [LTP_CUSTOM_PAGE, lotId],
    () => ltpContractsApi.getCustomPage(lotId),
    { enabled },
  )
}

/**
 * Create custom page for lot
 * Mutation body:
 *    - file: document (there may be many files, just duplicate it in the body)
 * @returns useMutation instance
 */
function useCreateCustomPage() {
  const queryClient = useQueryClient()

  const options = {
    headers: { 'Content-Type': 'multipart/form-data' },
  }

  return useMutation(
    ({ lotId, data }) => ltpContractsApi.createCustomPage(lotId, data, options),
    {
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          await queryClient.invalidateQueries(LTP_CUSTOM_PAGE)
        }
      },
    },
  )
}

/**
 * Delete custom page for lot
 * Mutation body:
 * @returns useMutation instance
 */
function useDeleteCustomPage() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ lotId, ...query }) => ltpContractsApi.deleteCustomPage(lotId, query),
    {
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          await queryClient.invalidateQueries(LTP_CUSTOM_PAGE)
        }
      },
    },
  )
}

export {
  useLtpContracts,
  useLtpContractsCustomer,
  useLtpContract,
  useCreateLtpContract,
  useUpdateLtpContract,
  useDeleteLtpContract,
  useLtpContractsExport,
  useSignLtpContract,
  useRejectLtpContract,
  usePaymentContract,
  useDeleteSubscription,
  useLtpCustomPage,
  useCreateCustomPage,
  useDeleteCustomPage,
}
