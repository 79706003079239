import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { userAvailabilityApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'
const { USER_AVAILABILITY_BY_WEEK, USER_AVAILABILITY_BY_DATE } = QUERY_KEYS

/**
 * Get user weekly availabilities
 * @param whiteListId
 * @returns {UseQueryResult<AxiosResponse<any>, unknown>}
 */
function useUserAvailabilitiesByWeek(whiteListId, enabled = false) {
  return useQuery(
    [USER_AVAILABILITY_BY_WEEK, whiteListId],
    () => userAvailabilityApi.getAllByWeek(whiteListId),
    { enabled },
  )
}

/**
 * Get weekly availability
 * @param availabilityId
 * @param enabled
 * @returns {UseQueryResult<AxiosResponse<any>, unknown>}
 */
function useUserAvailabilityByWeek(
  availabilityId,
  whiteListId,
  enabled = false,
) {
  return useQuery(
    [USER_AVAILABILITY_BY_DATE, availabilityId],
    () => userAvailabilityApi.getByWeek(availabilityId, whiteListId),
    { enabled },
  )
}

/**
 * Create weekly availability
 * @param successCallback
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
function useCreateUserAvailabilityByWeek(successCallback) {
  const queryClient = useQueryClient()

  return useMutation(
    (data) => userAvailabilityApi.createByWeek(data.whiteListId, data),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(USER_AVAILABILITY_BY_WEEK)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          toast.success('Week availability added!')
          await queryClient.invalidateQueries(USER_AVAILABILITY_BY_WEEK)
          runCallback(successCallback)
        }
      },
    },
  )
}

/**
 * Update weekly availability
 * @param successCallback
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
function useEditUserAvailabilityByWeek(successCallback) {
  const queryClient = useQueryClient()

  return useMutation(
    (data) => userAvailabilityApi.updateByWeek(data.whiteListId, data),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(USER_AVAILABILITY_BY_WEEK)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          toast.success('Week availability updated!')
          await queryClient.invalidateQueries(USER_AVAILABILITY_BY_WEEK)
          runCallback(successCallback)
        }
      },
    },
  )
}

/**
 * Delete weekly availability
 * @param successCallback
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
function useDeleteUserAvailabilityByWeek(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => userAvailabilityApi.deleteByWeek(id), {
    onMutate: async () => {
      await queryClient.cancelQueries(USER_AVAILABILITY_BY_WEEK)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(USER_AVAILABILITY_BY_WEEK)
      runCallback(successCallback)
    },
  })
}

/**
 * Get user availabilities by date
 * @param whiteListId
 * @returns {UseQueryResult<AxiosResponse<any>, unknown>}
 */
function useUserAvailabilitiesByDate(whiteListId, enabled = false) {
  return useQuery(
    [USER_AVAILABILITY_BY_DATE, whiteListId],
    () => userAvailabilityApi.getAllByDate(whiteListId),
    { enabled },
  )
}

/**
 * Get availability by date
 * @param availabilityId
 * @param enabled
 * @returns {UseQueryResult<AxiosResponse<any>, unknown>}
 */
function useUserAvailabilityByDate(
  availabilityId,
  whiteListId,
  enabled = false,
) {
  return useQuery(
    ['availability', availabilityId],
    () => userAvailabilityApi.getByDate(availabilityId, whiteListId),
    {
      enabled,
    },
  )
}

/**
 * Create availability by date
 * @param successCallback
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
function useCreateUserAvailabilityByDate(successCallback) {
  const queryClient = useQueryClient()

  return useMutation(
    (data) => userAvailabilityApi.createByDate(data.whiteListId, data),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(USER_AVAILABILITY_BY_DATE)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          toast.success('Availability by date added!')
          await queryClient.invalidateQueries(USER_AVAILABILITY_BY_DATE)
          runCallback(successCallback)
        }
      },
    },
  )
}

/**
 * Update availability by date
 * @param successCallback
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
function useEditUserAvailabilityByDate(successCallback) {
  const queryClient = useQueryClient()

  return useMutation(
    (data) => userAvailabilityApi.updateByDate(data.whiteListId, data.id, data),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(USER_AVAILABILITY_BY_DATE)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          toast.success('Availability by date updated!')
          await queryClient.invalidateQueries(USER_AVAILABILITY_BY_DATE)
          runCallback(successCallback)
        }
      },
    },
  )
}

/**
 * Delete availability by date
 * @param successCallback
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
function useDeleteUserAvailabilityByDate(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => userAvailabilityApi.deleteByDate(id), {
    onMutate: async () => {
      await queryClient.cancelQueries(USER_AVAILABILITY_BY_DATE)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(USER_AVAILABILITY_BY_DATE)
      runCallback(successCallback)
    },
  })
}

export {
  /* Availabilities by week*/
  useUserAvailabilitiesByWeek,
  useUserAvailabilityByWeek,
  useEditUserAvailabilityByWeek,
  useCreateUserAvailabilityByWeek,
  useDeleteUserAvailabilityByWeek,
  /* Availabilities by date */
  useUserAvailabilitiesByDate,
  useUserAvailabilityByDate,
  useCreateUserAvailabilityByDate,
  useEditUserAvailabilityByDate,
  useDeleteUserAvailabilityByDate,
}
