import React from 'react'

import PropTypes from 'prop-types'

import { withIndents } from '@/common/hocs'

import { ButtonComponent } from './styled'

function Button(props) {
  return <ButtonComponent {...props}>{props.text}</ButtonComponent>
}

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  sizeType: PropTypes.oneOf([ '', 'big', 'extraSmall' ]),
  appearance: PropTypes.oneOf([
    'primary',
    'secondary',
    'thirdly',
    'delete',
    'clearForm',
    'validationCode',
  ]),
}

Button.defaultProps = {
  text: 'Button',
  type: 'button',
  sizeType: '',
  appearance: 'primary',
}

export default withIndents(Button)
