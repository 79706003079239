const colors = {
  white: '#fff',
  black: '#000',
  dimBlack: 'rgba(0, 0, 0, 0.25)',
  lightGray: '#EEEEEE',
  gray: '#333333',
  lgray: '#1F2937',
  lightGrayM: '#9CA3AF',
  lightGrayText: '#F9FAFB',
  darkGray: '#6B7280',
  davyGray: '#57595F',
  lavender: '#E5E7EB',
  primary: '#0369A1',
  stone: '#797D85',
  blue: '#0054D2',
  lightBlue: '#0284C7',
  lightBlueLink: '#0EA5E9',
  lightBlueHover: '#F0F9FF',
  backgroundBlue: '#E0F2FE',
  backgroundTabs: '#D1D5DB',
  zumthor: '#EEF6FF',
  background: '#F3F4F6',
  azure: '#0585FC',
  appleGreen: '#D9F0E1',
  green: '#056532',
  silver: '#C1C2C2',
  red: '#F87171',
  quickSilver: '#A2A4A8',
  water: '#CCE4FF',
  platinum: '#E7E7E7',
  ghostWhite: '#F9F9F9',
  trueBlue: '#006EDC',
  panache: '#E9F5ED',
  congoPink: '#FF8680',
  titanWhite: '#FDFDFF',
  malibu: '#62A9FA',
  lightSkyBlue: '#91C3FC',
  disabledInput: '#F9F9F9',
  gumLeaf: '#ABD0BA',
  sidebarElem: '#4B5563',
  activeSidebarElem: '#111827',
  activeSidebarString: '#F3F4F6',
  bgContent: '#374151',
  greenAction: '#10B981',
  redAction: '#EF4444',
  yellowAction: '#F59E0B',
  selectedBlue: 'rgba(2, 132, 199, 0.6)',
  darkBlue: '#075985',
  lightGreen: '#07AC79',
  grassGreen: '#059669',
  redBgCancel: '#FFF1F2',
  redCancel: '#E11D48',
  yellowError: '#EA580C',
  yellowBgError: '#FFF7ED',
  greenBgSuccess: '#ECFDF5',
  paid: '#148451',
  unpaid: '#DC2626',
  cancel: '#7F1D1D',
  orange: '#F97316',
}

export default colors
