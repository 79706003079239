import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { lotRatesApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { RATES, RATE, RATES_FOR_PERIOD } = QUERY_KEYS


/**
 * Fetch all rates
 * @param lotId
 * @param query
 * @returns useQuery instance
 */
function useRates(lotId, query) {
  return useQuery(
    [ RATES, lotId, query ],
    () => lotRatesApi.getAll(lotId, query),
  )
}

/**
 * Fetch rate by id
 * @param rateId
 * @param enabled boolean value to enable/disable request
 * @returns useQuery instance
 */
function useRate(lotId, rateId, enabled = false) {
  return useQuery(
    [ RATE, rateId ],
    () => lotRatesApi.get({lotId}, rateId),
    { enabled, cacheTime: 1},
  )
}

/**
 * Get total price rate by id and promoCode
 * @param query
 * @param enabled boolean value to enable/disable request
 * @returns useQuery instance
 */
 function useTotalPrice(successCallback) {
  return useMutation(
    (query) => lotRatesApi.getTotalPrice(query),
    {
      onSuccess: (data) => {
        if (data.responseStatus === 200) {
          successCallback(data)
        }
      },
    }
  )
}

/**
 * Fetch rates by period
 * @param lotId
 * @param dateFrom left date to find from
 * @param dateTo right date to find to
 * @returns useQuery instance
 */
function useRatesForPeriod(lotId, dateFrom, dateTo) {
  return useQuery(
    [ RATES_FOR_PERIOD, lotId ],
    () => lotRatesApi.getForPeriod(lotId, { dateFrom, dateTo }),
  )
}

/**
 * Create weekly rates
 * @param successCallback
 * @returns useMutation instance
 */
function useCreateWeeklyRate(successCallback) {
  const queryClient = useQueryClient()

  return useMutation(
    (data) => lotRatesApi.createWeekly(data),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(RATES)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          toast.success('Weekly rate added!')
          await queryClient.invalidateQueries(RATES)
          runCallback(successCallback)
        }
      },
    }
  )
}

/**
 * Create daily rates
 * @param successCallback
 * @returns useMutation instance
 */
function useCreateDailyRate(successCallback) {
  const queryClient = useQueryClient()

  return useMutation(
    (data) => lotRatesApi.createDaily(data),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(RATES)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          toast.success('Daily rate added!')
          await queryClient.invalidateQueries(RATES)
          runCallback(successCallback)
        }
      },
    })
}

/**
 * Update weekly rate
 * @param successCallback
 * @returns useMutation instance
 */
function useUpdateWeeklyRate(successCallback) {
  const queryClient = useQueryClient()

  return useMutation(
    (data) => lotRatesApi.updateWeekly(data.id, data),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(RATES)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          toast.success('Weekly rate updated!')
          await queryClient.invalidateQueries(RATES)
          runCallback(successCallback)
        }
      },
    })
}

/**
 * Update daily rate
 * @param successCallback
 * @returns useMutation instance
 */
function useUpdateDailyRate(successCallback) {
  const queryClient = useQueryClient()

  return useMutation(
    (data) => lotRatesApi.updatedDaily(data.id, data),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(RATES)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          toast.success('Daily rate updated!')
          await queryClient.invalidateQueries(RATES)
          runCallback(successCallback)
        }
      },
    })
}

/**
 * Duplicate rate
 * @returns useMutation instance
 */
 function useDuplicateRate() {
  const queryClient = useQueryClient()

  return useMutation(
    (rateId) => lotRatesApi.duplicateRate(rateId),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(RATES)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          toast.success('Daily rate duplicated!')
          await queryClient.invalidateQueries(RATES)
        }
      },
    })
}

/**
 * Delete rate by id
 * @param successCallback
 * @returns useMutation instance
 */
function useDeleteRate(lotId, successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => lotRatesApi.delete({lotId},id), {
    onMutate: async () => {
      await queryClient.cancelQueries(RATES)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(RATES)
      runCallback(successCallback)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        toast.success('Rate deleted!')
      }
    },
  })
}

/**
 * Update rate activity by id and lotId
 * @param successCallback
 * @returns useMutation instance
 */
 function useUpdateRateActivity(lotId, successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => lotRatesApi.updatedActivity(id, {lotId}), {
    onMutate: async () => {
      await queryClient.cancelQueries(RATES)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        toast.success('Daily rate status updated!')
        await queryClient.invalidateQueries(RATES)
        runCallback(successCallback)
      }
    },
  })
}


export {
  useRates,
  useRate,
  useTotalPrice,
  useRatesForPeriod,

  useCreateWeeklyRate,
  useCreateDailyRate,

  useUpdateWeeklyRate,
  useUpdateDailyRate,

  useDuplicateRate,

  useDeleteRate,
  useUpdateRateActivity,
}
