import React from 'react'

import PropTypes from 'prop-types'
import ReactLoader from 'react-loader-spinner'

function Loader(props) {
  return (
    <ReactLoader
      type="Oval"
      color="#00BFFF"
      height={props.size || 100}
      width={props.size || 100}
      style={
        props.margin !== 'undefined'
          ? { margin: `${props.margin}px` }
          : { margin: '10px' }
      }
      {...props}
    />
  )
}

Loader.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  margin: PropTypes.number,
}

export default Loader
