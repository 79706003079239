import styled from 'styled-components'

import { colors } from '@/common/theme/variables'

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`
export const ButtonCopy = styled.div`
  padding: 8px 17px 4px 17px;
  background-color: ${colors.white};
  border-radius: 0px 6px 6px 0px;
  border: 1px solid ${colors.lavender};
  cursor: pointer;
`