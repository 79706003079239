import React, { forwardRef } from 'react'

import Checkbox from '@/components/Checkbox'

const Radio = forwardRef((props, ref) => {
  return (
    <Checkbox ref={ref} {...props} type={'radio'} />
  )
})

export default Radio
