import React, { forwardRef } from 'react'

import { Row, Text } from '@/common/baseStyledElements'
import { colors } from '@/common/theme/variables'

import { CurrencyBadge, Input } from './styled'

const CurrencyInput = forwardRef((props, ref) => {
  return (
    <Row>
      <CurrencyBadge>
        <Text $color={colors.malibu} fontSize={'16px'}>USD</Text>
      </CurrencyBadge>
      <Input
        ref={ref}
        {...props}
        withleft={1}
      />
    </Row>
  )
})

export default CurrencyInput
