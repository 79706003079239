import PropTypes from 'prop-types'
import styled from 'styled-components'

import { dimensionStyle, marginStyle } from '@/common/theme/styles'

const Image = styled.img`
  width: 100%; 
  height: 100%;
  ${(props) => props.objectFit && `object-fit: ${props.objectFit}`};
  ${dimensionStyle}
  ${marginStyle};
`

Image.propTypes = {
  objectFit: PropTypes.oneOf(['cover', 'contain']),
}

export default Image
