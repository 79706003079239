import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { toast } from 'react-toastify'

import { lotViolationActionsApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { VIOLATION_ACTIONS, VIOLATION_SEARCH_PLATES } = QUERY_KEYS

/**
 * Fetch all violation actions
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useAllViolatoins(query) {
  return useQuery([VIOLATION_ACTIONS, query], () =>
    lotViolationActionsApi.getAllViolations(query),
  )
}

/**
 * Fetch violation by customer
 * @param query
 * @returns useInfiniteQuery instance
 */
function useViolatoinsCustomer(query) {
  return useInfiniteQuery(
    [VIOLATION_ACTIONS, query],
    (param) =>
      lotViolationActionsApi.getViolationsCustomer({
        ...query,
        'Pagination.page': param?.pageParam ?? 1,
      }),
    {
      enabled: !!query.search,
      getNextPageParam: (lastPage) =>
        lastPage.pageIndex < lastPage.totalPages
          ? lastPage.pageIndex + 1
          : undefined,
    },
  )
}

/**
 * Fetch violation search by anonymous customer
 * @param query for using pagination, sort, search and filters
 * @returns useInfiniteQuery instance
 */
function useViolatoinsSearch(query, enabled = false) {
  return useInfiniteQuery(
    [VIOLATION_ACTIONS, query],
    (param) =>
      lotViolationActionsApi.getViolationsSearch({
        ...query,
        'Pagination.page': param?.pageParam ?? 1,
      }),
    {
      enabled: !!query.search,
      getNextPageParam: (lastPage) =>
        lastPage.pageIndex < lastPage.totalPages
          ? lastPage.pageIndex + 1
          : undefined,
    },
    { enabled },
  )
}

/**
 * Fetch violation action by id
 * @param id
 * @returns useQuery instance
 */
function useGetViolatoin(id, enabled = true) {
  return useQuery(
    [VIOLATION_ACTIONS, id],
    () => lotViolationActionsApi.getViolation(id),
    { enabled },
  )
}

/**
 * Fetch all violation lot actions
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useViolatoinActions(id, query) {
  return useQuery([VIOLATION_ACTIONS, id, query], () =>
    lotViolationActionsApi.getViolationActions(id, query),
  )
}

/**
 * Get export file violation-actions by query
 * @param query
 * @returns useQuery instance
 */
function useViolatoinActionsExport(successCallback) {
  return useMutation((query) => lotViolationActionsApi.export(query), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        successCallback(data)
      }
    },
  })
}

/**
 * Get license plates
 * @param lotId, search, query
 * @returns useQuery instance
 */
function useViolationSearchPlates(lotId, search, query) {
  return useInfiniteQuery(
    [VIOLATION_SEARCH_PLATES, lotId, search, query],
    (param) =>
      lotViolationActionsApi.searchPlates(lotId, {
        ...query,
        Search: search,
        page: param?.pageParam ?? 1,
      }),
    {
      enabled: !!search,
      getNextPageParam: (lastPage) =>
        lastPage.pageIndex < lastPage.totalPages
          ? lastPage.pageIndex + 1
          : undefined,
    },
  )
}

/**
 * Create violation
 * @param successCallback
 * @returns useMutation instance
 */
function useCreateViolation(successCallback) {
  return useMutation((data) => lotViolationActionsApi.create(data), {
    onMutate: async () => {},
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        toast.success('Violation formed successfully')
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Delete violation
 * @param lotId, query
 * @returns useMutation instance
 */
function useDeleteViolation(lotId, query) {
  const queryClient = useQueryClient()
  return useMutation((voitQuery) => lotViolationActionsApi.delete(voitQuery), {
    onMutate: async () => {
      await queryClient.cancelQueries([VIOLATION_ACTIONS, lotId, query])
    },
    onSettled: async () => {
      await queryClient.refetchQueries([VIOLATION_ACTIONS, lotId, query])
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.invalidateQueries(VIOLATION_ACTIONS)
        toast.success('Violation canceled')
      }
    },
  })
}

/**
 * Get officer violations
 * @param lotId, query
 * @returns useQuery instance
 */
function useOfficerViolationActions(lotId, query) {
  return useInfiniteQuery(
    [VIOLATION_ACTIONS, lotId, query],
    (param) =>
      lotViolationActionsApi.getViolationActions(lotId, {
        ...query,
        'Pagination.page': param?.pageParam ?? 1,
      }),
    {
      enabled: !!lotId,
      getNextPageParam: (lastPage) =>
        lastPage.pageIndex < lastPage.totalPages
          ? lastPage.pageIndex + 1
          : undefined,
    },
  )
}

/**
 * Set paid in cash violation
 * @param id, lotId
 * @returns useMutation instance
 */
function useSetPaidInCash(lotId, violationId, query) {
  const queryClient = useQueryClient()
  return useMutation(
    (voitQuery) => lotViolationActionsApi.setPaidInCash(voitQuery),
    {
      onSuccess: async (res) => {
        if (res.responseStatus === 200) {
          if (query) {
            queryClient.setQueryData(
              [VIOLATION_ACTIONS, lotId, query],
              (data) => {
                return {
                  ...data,
                  pages: data.pages.map((page) => ({
                    ...page,
                    items: page.items.map((item) =>
                      item.id === violationId ? res : item,
                    ),
                  })),
                }
              },
            )
          } else {
            await queryClient.invalidateQueries(VIOLATION_ACTIONS)
          }
        }
      },
    },
  )
}

/**
 * Set payment complete
 * @param id, lotId
 * @returns useMutation instance
 */
function useSetPaymentCompelete(lotId, violationId, query) {
  const queryClient = useQueryClient()
  return useMutation(
    (data) => lotViolationActionsApi.setPaymentComplete({ ...data, lotId }),
    {
      onSettled: async () => {
        await queryClient.refetchQueries([VIOLATION_ACTIONS, lotId, query])
      },
      onSuccess: async (res) => {
        if (res.responseStatus === 200) {
          if (query) {
            queryClient.setQueryData(
              [VIOLATION_ACTIONS, lotId, query],
              (data) => {
                return {
                  ...data,
                  pages: data.pages.map((page) => ({
                    ...page,
                    items: page.items.map((item) =>
                      item.id === violationId ? res : item,
                    ),
                  })),
                }
              },
            )
          } else {
            await queryClient.invalidateQueries(VIOLATION_ACTIONS)
          }
        }
      },
    },
  )
}

/**
 * Set payment complete
 * @param id, lotId
 * @returns useMutation instance
 */
function useSetRemoveBoot(lotId, violationId, query) {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, notes }) => lotViolationActionsApi.setRemoveBoot(id, lotId, notes),
    {
      onMutate: async () => {},
      onSuccess: async (res) => {
        if (res.responseStatus === 200) {
          if (query) {
            queryClient.setQueryData(
              [VIOLATION_ACTIONS, lotId, query],
              (data) => {
                return {
                  ...data,
                  pages: data.pages.map((page) => ({
                    ...page,
                    items: page.items.map((item) =>
                      item.id === violationId ? res : item,
                    ),
                  })),
                }
              },
            )
          } else {
            await queryClient.invalidateQueries(VIOLATION_ACTIONS)
          }
          toast.success('Boot removed')
        }
      },
    },
  )
}

/**
 * Change cash received status
 * @returns useMutation instance
 */
function useChangeCashReceive() {
  const queryClient = useQueryClient()

  return useMutation((data) => lotViolationActionsApi.changeCashReceive(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(VIOLATION_ACTIONS)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(VIOLATION_ACTIONS)
    },
  })
}

/**
 * Call officer
 * @returns useMutation instance
 */
function useCallOfficer(successCallback) {
  return useMutation((id) => lotViolationActionsApi.callOfficer(id), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Change status set in progress
 * @returns useMutation instance
 */
function useSetInProgress() {
  const queryClient = useQueryClient()

  return useMutation((data) => lotViolationActionsApi.setInProgress(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(VIOLATION_ACTIONS)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(VIOLATION_ACTIONS)
    },
  })
}

/**
 * Fetch all violation action statuses
 * @returns useQuery instance
 */
function useVAStatuses() {
  return useQuery([VIOLATION_ACTIONS], () =>
    lotViolationActionsApi.getStatuses(),
  )
}

export {
  useAllViolatoins,
  useViolatoinsCustomer,
  useViolatoinsSearch,
  useGetViolatoin,
  useViolatoinActions,
  useViolatoinActionsExport,
  useViolationSearchPlates,
  useCreateViolation,
  useDeleteViolation,
  useOfficerViolationActions,
  useSetPaidInCash,
  useSetPaymentCompelete,
  useSetRemoveBoot,
  useVAStatuses,
  useChangeCashReceive,
  useCallOfficer,
  useSetInProgress,
}
