import * as yup from 'yup'

import regExpValidations from '@/common/utils/const/regExp'

const CompanySchema = yup.object().shape({
  name: yup.string().required('Company is required'),
  position: yup.string().required('Position is required'),
  phone: yup
    .string()
    .required('Phone is required')
    .matches(regExpValidations?.phoneRegExp, 'Phone number is not valid'),
  countryId: yup.number().required('Country is required'),
  stateId: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
  postalCode: yup
    .string()
    .required('ZIP is required')
    .matches(regExpValidations.postalRegExp, 'ZIP should be valid'),
  addressLine: yup.string().required('Address is required'),
})

export default CompanySchema