import styled from 'styled-components'

import colors from '../theme/variables/colors'
import Text from './Text'

const SectionText = styled(Text)`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${colors.darkGray};
`

export default SectionText;