import React from 'react'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { STRIPE_PUBLISHABLE_KEY } from '@/common/config'

const stripe = loadStripe(STRIPE_PUBLISHABLE_KEY)

function withStripe(Component) {
  function Wrapper(props) {
    return (
      <Elements stripe={stripe}>
        <Component {...props} />
      </Elements>
    )
  }

  return Wrapper
}

export default withStripe
