import { useQuery } from 'react-query'

import { customerMyPermitsApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'

const { MY_PERMITS } = QUERY_KEYS

/**
 * Fetch all lots
 * @returns useQuery instance
 */
function useMyPermits({ status, plate }) {
  return useQuery([MY_PERMITS, status, plate], () =>
    customerMyPermitsApi.get(status, plate),
  )
}

export { useMyPermits }
