import * as yup from 'yup'

import {
  registerDateValidation,
  registerTimeValidation,
} from '@/common/schemas/customValidator'
import { PASS_DURATION_TYPES, RATE_TYPES } from '@/common/utils/const'
import regExpValidations from '@/common/utils/const/regExp'

registerTimeValidation()
registerDateValidation()

const LotRateSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  type: yup
    .mixed()
    .required('Period is required')
    .oneOf([RATE_TYPES.WEEKLY, RATE_TYPES.DAILY]),
  passDuration: yup
    .mixed()
    .required('Duration is required')
    .oneOf([
      PASS_DURATION_TYPES.HOUR,
      PASS_DURATION_TYPES.FULL_DAY,
      PASS_DURATION_TYPES.CUSTOM,
    ]),
  dayIds: yup.array().when('type', {
    is: RATE_TYPES.WEEKLY,
    then: yup
      .array()
      .of(yup.number())
      .min(1, 'Select at least one day of the week'),
  }),
  dateFrom: yup.string().when('type', {
    is: RATE_TYPES.DAILY,
    then: yup
      .string()
      .required('Start Date is required')
      .dateValidation(yup.ref('dateTo')),
  }),
  dateTo: yup.string().when('type', {
    is: RATE_TYPES.DAILY,
    then: yup
      .string()
      .required('End Date is required')
      .dateValidation(yup.ref('dateFrom'), true),
  }),
  customHours: yup.number().when('passDuration', {
    is: PASS_DURATION_TYPES.CUSTOM,
    then: yup
      .number()
      .typeError('Need enter hours')
      .required('Need enter hours')
      .positive('Hours can be only positive')
      .max(168, 'Max value it 168')
      .min(0.5, 'Min value it 0,5')
      .test(
        'formatHours',
        ({ value }) => {
          if (value < 24) return 'Invalid hours. Example: 2 or 2,5 ...'
          return 'Invalid hours. Example: 24 or 48 or 72 ...'
        },
        (value) => {
          if (value < 24)
            return new RegExp(regExpValidations.floatHulfHours).test(value)
          return new RegExp(regExpValidations.HoursMoreDay).test(value)
        },
      ),
  }),
  amount: yup.number().when('passDuration', {
    is: PASS_DURATION_TYPES.FULL_DAY,
    then: yup
      .number()
      .typeError('Total Permit Amount is required')
      .required('Need enter Total Permit Amount')
      .positive('Amount can be only positive'),
  }),
  purchaseFrom: yup
    .string()
    .timeValidation('Invalid time')
    .required('Field is required'),
  purchaseTo: yup
    .string()
    .timeValidation('Invalid time')
    .required('Field is required'),
})

export default LotRateSchema
