import * as yup from 'yup'

const addLtpAnonymCustomerSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  phone: yup.string().nullable(),
  email: yup.string().nullable(),
})

export default addLtpAnonymCustomerSchema
