import { useQuery } from 'react-query'
import { useSetRecoilState } from 'recoil'

import { dictionaryApi } from '@/api'
import { 
  countryAtom, 
  userRoleAtom, 
  stateAtom, 
  exportTypesAtom,
  violationTypesAtom,
  vendorTypesAtom,
  violationReasonsAtom, 
  timezonesAtom
} from '@/common/store/dictionary'
import { QUERY_KEYS } from '@/common/utils/const'

const { DICTIONARY } = QUERY_KEYS

function useDictionary() {
  const setCountries = useSetRecoilState(countryAtom)
  const setStates = useSetRecoilState(stateAtom)
  const setUserRoles = useSetRecoilState(userRoleAtom)
  const setExportTypes = useSetRecoilState(exportTypesAtom)
  const setViolationTypes = useSetRecoilState(violationTypesAtom)
  const setVendorTypes = useSetRecoilState(vendorTypesAtom)
  const setViolationReasons = useSetRecoilState(violationReasonsAtom)
  const setTimezones = useSetRecoilState(timezonesAtom)
  
  return useQuery(
    [ DICTIONARY ],
    () => dictionaryApi.dictionaries(),
    {
      onSuccess: (data) => {
        setCountries(data?.countries)
        setStates(data?.states)
        setUserRoles(data?.userRoles)
        setExportTypes(data?.exportTypes)
        setViolationTypes(data?.violationTypes)
        setVendorTypes(data?.vendorTypes),
        setViolationReasons(data?.violationReasons)
        setTimezones(data?.timezones)
      },
    })
}

export {
  useDictionary,
}