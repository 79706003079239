import { css } from 'styled-components'

const flexStyle = css`
  ${({ $justifyContent }) => {
    return $justifyContent && `justify-content: ${$justifyContent}`
  }};
  ${({ $alignItems }) => {
    return $alignItems && `align-items: ${$alignItems}`
  }};
  ${({ $flex }) => {
    return $flex && `flex: ${$flex}`
  }}
  ${({ $flexDirection }) => {
    return $flexDirection && `flex-direction: ${$flexDirection}`
  }}
  ${({ $alignSelf }) => {
    return $alignSelf && `align-self: ${$alignSelf}`
  }}
`

export default flexStyle
