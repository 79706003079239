import Customer from './Customer.js'
import LotManager from './LotManager.js'
import LotOwner from './LotOwner.js'
import LotSuperManager from './LotSuperManager.js'
import PPAdmin from './PPAdmin.js'
import PPUser from './PPUser.js'
import ViolationOfficer from './ViolationOfficer.js'

const userRoles = {
  ppAdmin: 'PP Admin',
  ppUser: 'PP User',
  superManager: 'Lot Super Manager',
  manager: 'Lot Manager',
  owner: 'Lot Owner',
  officer: 'Violation Officer',
  customer: 'Customer',
  referral: 'Referral',
}

function getAccesses(role, direction = [], action = false) {
  const tempAccesses = findAccesses(role)
  if (direction.length) {
    let accesses = []
    if (action) accesses = tempAccesses[direction][action]
    else
      accesses = direction.map((item) => {
        return {
          direction: item,
          keys: tempAccesses[item],
        }
      })

    return accesses
  }

  return tempAccesses
}

function findAccesses(role) {
  switch (role) {
    case 'PP Admin':
      return PPAdmin

    case 'PP User':
      return PPUser

    case 'Lot Super Manager':
      return LotSuperManager

    case 'Lot manager':
      return LotManager

    case 'Lot Owner':
      return LotOwner

    case 'Violation Officer':
      return ViolationOfficer

    case 'Customer':
      return Customer

    default:
      return Customer
  }
}

export const isViolationOfficer = (role) => {
  return role && role?.name === userRoles.officer
}

export const isCustomer = (role) => {
  return role && role?.name === userRoles.customer
}

export const isSuperManager = (role) => {
  return role && role?.name === userRoles.superManager
}

export const isManager = (role) => {
  return role && role?.name === userRoles.manager
}

export const isPPAdmin = (role) => {
  return role && role?.name === userRoles.ppAdmin
}

export const isReferral = (role) => {
  return role && role?.name === userRoles.referral
}

export default getAccesses
