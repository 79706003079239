export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID
export const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE
export const apiUrl = process.env.REACT_APP_API_URL
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY
export const STRIPE_ACCOUNT_ID = process.env.REACT_APP_STRIPE_ACCOUNT_ID
export const STRIPE_PUBLISHABLE_KEY = 
process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
export const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY
export const openAppKey = process.env.REACT_APP_OPEN_APP_KEY
