import { useMutation, useQuery } from 'react-query'

import { dashboardApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { REVENUE, REAL_TIME, LOTS_STATISTIC, LOTS_STATISTIC_COMPARE } =
  QUERY_KEYS

/**
 * Fetch all Revenue data
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useRevenue(query) {
  return useQuery([REVENUE, query], () => dashboardApi.revenue(query))
}

/**
 * Get export file revenue by query
 * @param query
 * @returns useQuery instance
 */
function useRevenueExport(successCallback) {
  return useMutation((query) => dashboardApi.revenueExport(query), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) successCallback(data)
    },
  })
}

/**
 * Fetch all Real Time Tracking data
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useRealTime(query) {
  return useQuery([REAL_TIME, query], () => dashboardApi.realTime(query))
}

/**
 * Fetch all Revenue statistic data
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useRevenueStatistic(successCallback) {
  return useMutation((query) => dashboardApi.revenueStatistic(query), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Fetch all Revenue statistic data
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useRevenueStatisticCompare(successCallback) {
  return useMutation((query) => dashboardApi.revenueStatisticCompare(query), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Fetch all Revenue data
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useLotsStatistic(query) {
  return useQuery([LOTS_STATISTIC, query], () =>
    dashboardApi.lotsStatistic(query),
  )
}

/**
 * Fetch all Revenue data
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useLotsStatisticCompare(query) {
  return useQuery([LOTS_STATISTIC_COMPARE, query], () =>
    dashboardApi.lotsStatisticCompare(query),
  )
}

/**
 * Fetch all Revenue data
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useLotsPatmentTypes(successCallback) {
  return useMutation((query) => dashboardApi.lotsPaymentTypes(query), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Fetch all Revenue data
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useLotsPatmentTypesCompare(successCallback) {
  return useMutation((query) => dashboardApi.lotsPaymentTypesCompare(query), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Get export file revenue by type
 * @param type
 * @returns useQuery instance
 */
function useStatisticExport(successCallback) {
  return useMutation((query) => dashboardApi.statisticExport(query), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        successCallback(data)
      }
    },
  })
}

export {
  useRevenue,
  useRevenueExport,
  useRealTime,
  useRevenueStatistic,
  useRevenueStatisticCompare,
  useLotsStatistic,
  useLotsStatisticCompare,
  useLotsPatmentTypes,
  useLotsPatmentTypesCompare,
  useStatisticExport,
}
