import * as yup from 'yup'

import regExpValidations from '@/common/utils/const/regExp'

const userFormSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required').nullable(),
  lastName: yup.string().required('Last Name is required').nullable(),
  phone: yup
    .string()
    .required('Phone is required')
    .matches(regExpValidations?.phoneRegExp, 'Phone number is not valid')
    .nullable(),
  email: yup
    .string()
    .required('Email is required')
    .matches(regExpValidations?.emailRegExp, 'Email is not valid'),
  roleId: yup
    .number()
    .required('Role is required')
})

export default userFormSchema
