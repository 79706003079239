import { withQuery } from "@/common/utils/helpers/requestHelpers"

function registerLotManagerCompanyApi(api) {
  return {
    getAll: (query) => api.get(withQuery(`/lot-company`, query)),
    get: (id, query) => api.get(withQuery(`/lot-company/${id}`, query)),
    create: (data) => api.post(`/lot-company`, data),
    update: (data) => api.put(`/lot-company/${data.id}`, data),
    delete: (id) => api.delete(`/lot-company/${id}`),
    getCurrent: () => api.get(`/lot-company/current`),
    updateCurrent: (data) => api.put(`/lot-company/current`, data),
    export: (query) => 
      api.get(withQuery(`/lot-company/export`, query), {responseType: 'blob'}),
  }
}

export default registerLotManagerCompanyApi