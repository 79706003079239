import { 
  useMutation, 
  useQuery,
  useQueryClient 
} from 'react-query'

import { lotManagerCompanyApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { 
  LOT_MANAGER_COMPANIES,
  LOT_MANAGER_COMPANY_INFO,
  LOT_MANAGER_COMPANY_CURRENT 
} = QUERY_KEYS

/**
 * Fetch all companies
 * @param 
 * @returns useQuery instance
 */
 function useLotManagerCompanies(query) {
  return useQuery(
    [ LOT_MANAGER_COMPANIES, query ],
    () => lotManagerCompanyApi.getAll(query),
  )
}

/**
 * Get export file Companies by type and filters
 * @param type
 * @returns useQuery instance
 */
 function useLotManagerCompaniesExport(successCallback) {
  return useMutation(
    (query) => lotManagerCompanyApi.export(query),
    {
      onSuccess: async (data) => {
        if(data.responseStatus === 200){
          successCallback(data)
        }
      },
    }
  )
}

/**
 * Fetch compane by id
 * @param id
 * @returns useQuery instance
 */
 function useLotManagerCompany(id, query) {
  return useQuery(
    [ LOT_MANAGER_COMPANY_INFO, id , query],
    () => lotManagerCompanyApi.get(id, query),
  )
}

/**
 * Create company
 * @param successCallback
 * @returns useMutation instance
 */
 function useLotManagerCompanyCreate(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => lotManagerCompanyApi.create(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(LOT_MANAGER_COMPANIES)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
        await queryClient.invalidateQueries(LOT_MANAGER_COMPANIES)
      }
    },
  })
}

/**
 * Update company
 * @param successCallback
 * @returns useMutation instance
 */
function useLotManagerCompanyUpdate(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => lotManagerCompanyApi.update(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(LOT_MANAGER_COMPANIES)
      await queryClient.cancelQueries(LOT_MANAGER_COMPANY_INFO)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.invalidateQueries(LOT_MANAGER_COMPANIES)
        await queryClient.invalidateQueries(LOT_MANAGER_COMPANY_INFO)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Delete company
 * @param successCallback
 * @returns useMutation instance
 */
 function useLotManagerCompanyDelete(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => lotManagerCompanyApi.delete(id), {
    onMutate: async () => {
      await queryClient.cancelQueries(LOT_MANAGER_COMPANIES)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
        await queryClient.invalidateQueries(LOT_MANAGER_COMPANIES)
      }
    },
  })
}

/**
 * Fetch current company bu lot manager
 * @param 
 * @returns useQuery instance
 */
function useLotManagerCompanyCurrent() {
  return useQuery(
    [ LOT_MANAGER_COMPANY_CURRENT ],
    () => lotManagerCompanyApi.getCurrent(),
  )
}

/**
 * Update company
 * @param successCallback
 * @returns useMutation instance
 */
 function useUpdateLotManagerCompanyCurrent(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => lotManagerCompanyApi.updateCurrent(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(LOT_MANAGER_COMPANY_CURRENT)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.invalidateQueries(LOT_MANAGER_COMPANY_CURRENT)
        runCallback(successCallback)
      }
    },
  })
}

export { 
  useLotManagerCompanies,
  useLotManagerCompaniesExport,
  useLotManagerCompany,
  useLotManagerCompanyCreate,
  useLotManagerCompanyUpdate,
  useLotManagerCompanyDelete,
  useLotManagerCompanyCurrent, 
  useUpdateLotManagerCompanyCurrent,
}