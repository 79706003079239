import { format, isSameDay, isSameMonth, isSameYear } from 'date-fns'

import {
  AVAILABILITY_TYPES,
  DAY_OF_WEEK, 
  PASS_DURATION_TYPES,
  RATE_TYPES,
} from '@/common/utils/const'

/**
 * To check if availability type from calendar is by week
 * @param type
 * @returns {boolean}
 */
export function isAvailabilityByWeek(type) {
  return type && type === AVAILABILITY_TYPES.BY_WEEK
}

/**
 * To check if availability type from calendar is by date
 * @param type
 * @returns {boolean}
 */
export function isAvailabilityByDate(type) {
  return type && type === AVAILABILITY_TYPES.BY_DATE
}

/**
 * To check if rate is weekly
 * @param type
 * @returns {boolean}
 */
export function isWeeklyRate(type) {
  return type && type === RATE_TYPES.WEEKLY
}

/**
 * To check if rate is daily
 * @param type
 * @returns {boolean}
 */
export function isDailyRate(type) {
  return type && type === RATE_TYPES.DAILY
}

/**
 * Preparing lot documents to request
 * @param lotId
 * @param documents array of documents
 * @param extractFile there is the opportunity to pass an array of documents
 * with many other data, and with the required field *file*
 * In this case, if we pass extractFile = true, the function will automatically
 * extract files from documents, in another way, we will need to pass documents
 * with only files
 * @returns formData with lotId and files
 */
export function prepareLotDocuments(lotId, documents, extractFile = true) {
  const data = new FormData()
  if(lotId)
    data.append('lotId', lotId)

  documents.forEach((document) => {
    if (extractFile && document?.file) {
      data.append('file', document?.file)
    } else {
      data.append('file', document)
    }
  })

  return data
}

/**
 * Generate string of period for the rate
 * @param data could be date array with day of week or with dates
 * (lotDailyRates/lotWeeklyRates)
 * lotWeeklyRates - usually array with the number of the day of the the week
 * lotDailyRates - an array with an object that contain *dateFrom* *dateTo* keys
 * @param type RATE_TYPES constant (WEEKLY/DAILY)
 * @returns string
 */
export function generateRatePeriod(data, type) {
  if (type === RATE_TYPES.WEEKLY) {
    if (data.length === 7) return 'Everyday'

    const aPeriod = data.map((item) => DAY_OF_WEEK[item.dayId])
    return aPeriod.join(', ')
  } else if (type === RATE_TYPES.DAILY) {
    const obj = data[0]
    const dateFrom = new Date(obj?.dateFrom)
    const dateTo = new Date(obj?.dateTo)

    const args = [ dateFrom, dateTo ]

    const sameDay = isSameDay(...args)
    const sameMonth = isSameMonth(...args)
    const sameYear = isSameYear(...args)

    /*
     * Difference types of format date
     * By default returns two dates in *dd MMM yyyy* format
     * */
    if (sameDay && sameMonth && sameYear) {
      return format(dateFrom, 'dd MMM yyyy')
    }

    if (sameMonth && sameYear) {
      const day1 = format(dateFrom, 'dd')
      const day2 = format(dateTo, 'dd')
      const end = format(dateFrom, 'MMM yyyy')

      return `${day1} - ${day2} ${end}`
    }

    if (sameYear) {
      const date1 = format(dateFrom, 'dd MMM')
      const date2 = format(dateTo, 'dd MMM')
      const year = format(dateFrom, 'yyyy')

      return `${date1} - ${date2}  ${year}`
    }

    return `
    ${format(dateFrom, 'dd MMM yyyy')}
    - 
    ${format(dateTo, 'dd MMM yyyy')}
    `
  }
  return ''
}

/**
 * Compute pass duration type by passed hours
 * 1 hour - 1 Hour pass
 * 17 hours - Full Day Pass
 * In other cases: Custom Pass Duration
 * @param hours
 */
export function computePassDurationType(rate) {
  if(rate?.isFullDay) return PASS_DURATION_TYPES.FULL_DAY
  if (rate?.hours === 1) return PASS_DURATION_TYPES.HOUR
  return PASS_DURATION_TYPES.CUSTOM
}