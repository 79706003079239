import * as yup from 'yup'

import regExpValidations from '@/common/utils/const/regExp'

const UserWhiteListSchema = yup.object().shape({
  recipientFirstName: yup.string().required('First Name is required'),
  recipientLastName: yup.string().required('Last Name is required'),
  recipientContactNumber: yup
    .string()
    .required('Phone is required')
    .matches(regExpValidations?.phoneRegExp, 'Phone number is not valid'),
  recipientEmail: yup
    .string()
    .required('Email is required')
    .matches(regExpValidations?.emailRegExp, 'Email is not valid'),
  lotId: yup.number().required('Country is required'),
  associatedPlates: yup
    .array()
    .of(
      yup.object().shape({
        value: yup
          .string()
          .max(10, 'Maximum length of license plate is 10 symbols'),
        text: yup.string(),
      }),
    )
    .min(1, 'Select at least one License Plate')
    .required('Select at least one License Plate')
    .typeError('License Plate is required'),
  noExpiration: yup.mixed().oneOf([true, false]),
  startDate: yup
    .string()
    .when('noExpiration', {
      is: true,
      then: yup.string().required('Start Date is required'),
    })
    .when('noExpiration', {
      is: false,
      then: yup
        .string()
        .required('Start Date is required')
        .dateValidation(yup.ref('expirationDate')),
    }),
  expirationDate: yup
    .string()
    .when('noExpiration', {
      is: true,
      then: yup.string(),
    })
    .when('noExpiration', {
      is: false,
      then: yup
        .string()
        .required('End Date is required')
        .dateValidation(yup.ref('startDate'), true),
    }),
})

export default UserWhiteListSchema
