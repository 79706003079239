import React from 'react'

import { Auth0Provider } from '@auth0/auth0-react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify'
import { RecoilRoot } from 'recoil'

import { auth0Domain, auth0ClientId, auth0Audience } from '@/common/config'
import AppRouter from '@/common/router/AppRouter'

import 'react-toastify/dist/ReactToastify.css'

// Create a react-query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export default function App() {
  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      audience={auth0Audience}
      redirectUri={window.location.origin}
      scope={'openid profile email'}
    >
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <AppRouter />
          <ToastContainer
            position={'top-right'}
            autoClose={3000}
            newestOnTop={false}
            hideProgressBar={true}
            closeOnClick
            pauseOnHover
          />
        </RecoilRoot>
      </QueryClientProvider>
    </Auth0Provider>
  )
}
