import { withQuery } from '@/common/utils/helpers'

function registerPromoCodesApi(api) {
  return {
    get: (lotId) => api.get(`/promo-code/${lotId}`),
    create: (data) => api.post(`/promo-code`, data),
    export: (lotId, query) =>
      api.get(withQuery(`/promo-code/lot/${lotId}/export`, query), {
        responseType: 'blob',
      }),
    exportPdf: (lotId, query) =>
      api.get(withQuery(`/promo-code/lot/${lotId}/export/pdf`, query), {
        responseType: 'blob',
      }),
  }
}

export default registerPromoCodesApi
