import React from 'react'

import PropTypes from 'prop-types'

import { Row } from '@/common/baseStyledElements'

function FormItem(props) {
  const { children } = props;
  
  return (
    <Row className={'form-item'} {...props}>
      {children}
    </Row>
  )
}

FormItem.propTypes = {
  children: PropTypes.any,
}

FormItem.defaultProps = {
  children: null,
}


export default FormItem