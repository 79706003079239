import { withQuery } from '@/common/utils/helpers'

function registerUserApi(api) {
  return {
    getUserCurrent: () => api.get('/user/current'),
    getUserEnforcerActions: () => api.get('/user/enforcer-actions'),
    getUserById: (id) => api.get(`/user/${id}`),
    invite: (data) => api.post('/user/invite', data),
    update: (data) => api.put('/user', data),
    updateById: (data) => api.put(`/user/${data.userId ?? data.id}`, data),
    delete: (id) => api.delete(`/user/${id}`),
    getRoles: (query) => api.get(withQuery('/user/roles', query)),
    getRolesForChange: (roleId) => api.get(`/user/roles-for-change/${roleId}`),
    getAll: (query) => api.get(withQuery(`/user`, query)),
    block: (id) => api.post(`/user/${id}/block`),
    unblock: (id) => api.post(`/user/${id}/unblock`),
  }
}

export default registerUserApi
