import styled from 'styled-components'

import baseStyles from '@/common/theme/styles/baseStyles'
import colors from '@/common/theme/variables/colors'

const CommonContainer = styled.div`
  border-radius: 8px;
  padding: 24px;
  background-color: ${colors.background};
  ${baseStyles};
`

export default CommonContainer
