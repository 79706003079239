import { withQuery } from '@/common/utils/helpers'

function registerLtpCustomersApi(api) {
  return {
    getAll: (query) => api.get(withQuery(`/contracts/customer`, query)),
    export: (query) => 
      api.get(
        withQuery(`/contracts/customer/export`, query), {responseType: 'blob'}
      ),
    getByEmailOrPhoneNumber: (query) =>
      api.get(withQuery(`/user/by-contact`, query)),
    createLtpCustomer: (body) => api.post(`/user/customer`, body),
  }
}

export default registerLtpCustomersApi
