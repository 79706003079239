import * as yup from 'yup'

import regExpValidations from '@/common/utils/const/regExp'

const addLtpCustomerSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  phone: yup
    .string()
    .required('Phone Number is required')
    .matches(regExpValidations?.phoneRegExp, 'Phone number is not valid'),
  email: yup.string().required('Email is required'),
  associatedPlates: yup
    .string()
    .required('License Plate is required')
    .max(10, 'Maximum length of license plate is 10 symbols'),
  countryId: yup.number().required('Please, select country'),
  stateId: yup.number().required('Please, select state'),
  city: yup.string().required('City is required'),
  postalCode: yup
    .string()
    .required('ZIP is required')
    .matches(regExpValidations.postalRegExp, 'ZIP should be valid'),
  addressLine: yup.string().required('Address Line is required'),
})

export default addLtpCustomerSchema
