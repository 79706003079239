import * as yup from 'yup'

const feesSchema = yup.object().shape({
  percents: yup.mixed().test('totalDiff', ``, (value, ctx) => {
    if (
      ctx.parent.managementFeeCurrency === 'percent' ||
      ctx.parent.platformFeeAmount === 'percent'
    )
      return (
        +ctx.parent.managementFeeAmount <= 100 &&
        +ctx.parent.platformFeeAmount <= 100
      )

    return true
  }),
})

export default feesSchema
