import React from 'react'

import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import { Container, MainText, DescriptionText } from './styled'

PagePreview.propTypes = {
  text: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  svg: PropTypes.any.isRequired,
}

function PagePreview(props) {
  const { text, description, svg } = props

  return(
    <Container>
      <SVG src={svg} />
      <MainText>{text}</MainText>
      <DescriptionText>{description}</DescriptionText>
    </Container>
  )
}

export default PagePreview