import FileSaver from 'file-saver'
import { useContextMenu } from 'react-contexify'
import { useRecoilValue } from 'recoil'

import { userInfoAtom } from '@/common/store/currentUser'

/**
 * To filter props when we use spread (...props}
 * @param obj props object
 * @param data array with the props keys that is not be in final result
 * @param type string, allow or disallow keys from data array
 * @returns filtered props
 */

export function filteredProps(obj, data, type = 'disallow') {
  if (type === 'disallow') {
    const result = {}
    Object.keys(obj).forEach((el) => {
      if (!data.includes(el)) {
        result[el] = obj[el]
      }
    })
    return result
  }

  if (type === 'allow') {
    const result = {}
    data.forEach((el) => {
      result[el] = obj[el]
    })
    return result
  }
}

/**
 * To check if callback isn't null and run it
 * @param callback A unction
 * @returns callback if exists
 */
export function runCallback(callback) {
  return callback && callback()
}

/**
 * Parse any array with data to select format
 * @param data Arrays with items
 * @param textAlias A key in array to convert it to the text need for select
 * @param valueAlias A key in array to convert it to the value need for select
 * @returns array with following objects:
 *  {text: "some text", value: "some value"}
 */
export function toSelect(
  data,
  textAlias = 'name',
  valueAlias = 'id',
  spareAlias,
) {
  if (!data || !data[0]) return []

  return data.map((item) => {
    const value = item[valueAlias]
    let text = item[textAlias]

    if (Array.isArray(textAlias)) {
      const array = textAlias.map((i) => item[i])
      if ((array.includes('') || array.includes(null)) && spareAlias) {
        text = spareAlias.map((i) => item[i]).join(', ')
      } else {
        text = array.join(', ')
      }
    }

    return { text, value }
  })
}

/**
 * Delete all data from cookie to logout from auth0
 */
export function deleteAllCookies() {
  const cookies = document.cookie.split(';')

  cookies.forEach((cookie) => {
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  })
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @return Formatted string.
 */
export function toHumanFileSize(bytes) {
  const thresh = 1024
  const dp = 1

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + ' ' + units[u]
}

/**
 * Extract hours and minutes from time string
 * Example:
 * We have a string 11:30:20
 * We want to parse it into the 11:30
 * @param timeString
 * @returns {number}
 */
export function toTimeFormat(timeString) {
  try {
    return timeString.split(':').slice(0, 2).join(':')
  } catch (err) {
    return timeString
  }
}

/**
 * Shows context menu and adds scroll listener for closing menu
 */
export function openContextMenu(ev, menuId) {
  const { show, hideAll } = useContextMenu({ id: menuId })

  const handleWindowScroll = () => {
    hideAll()
    document.removeEventListener('scroll', handleWindowScroll, true)
  }

  const handleContextMenu = (event) => {
    const {
      target: { localName, farthestViewportElement },
    } = event
    let props = {}

    if (localName === 'svg') {
      props = {
        id: event.target.getAttribute('data-id'),
        type: event.target.getAttribute('data-type'),
        status: event.target.getAttribute('data-status'),
      }
    } else if (localName === 'path') {
      props = {
        id: farthestViewportElement.dataset.id,
        type: farthestViewportElement.dataset.type,
        status: farthestViewportElement.dataset.status,
      }
    }

    show(event, { props })
    document.addEventListener('scroll', handleWindowScroll, true)
  }
  handleContextMenu(ev)
}

export function debounce(func, timeout = 500) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export function debouncePromise(fn, ms = 0) {
  let timeoutId
  const pending = []
  return (...args) =>
    new Promise((res, rej) => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        const currentPending = [...pending]
        pending.length = 0
        Promise.resolve(fn.apply(this, args)).then(
          (data) => {
            currentPending.forEach(({ resolve }) => resolve(data))
          },
          (error) => {
            currentPending.forEach(({ reject }) => reject(error))
          },
        )
      }, ms)
      pending.push({ resolve: res, reject: rej })
    })
}

/**
 * Helpers function to download export file
 * @param data
 * @returns
 */
export function downloadExportFile(data) {
  const file = data.data
  let name = data.responseHeaders['content-disposition'].split('filename=')
  name = name[1].split(';')[0]
  FileSaver.saveAs(file, name)
}

/**
 * Convert UTC time string to local time string
 * Example:
 * We have a string 6:30 UTC
 * We want to parse it into the 9:30 if UTC +3
 * @param timeString
 * @returns {string}
 */
export function toLocalTime(utcString) {
  try {
    const date = new Date()
    const targetHours = utcString?.split(':')[0]
    const targetMinutes = utcString?.split(':')[1]
    date.setUTCHours(targetHours)
    date.setUTCMinutes(targetMinutes)
    return date.toTimeString().split(':').slice(0, 2).join(':')
  } catch (err) {
    return utcString
  }
}

/**
 * Convert local time string to UTC time string
 * Example:
 * We have a string 9:30 if UTC +3
 * We want to parse it into the 6:30 UTC
 * @param localTimeString
 * @returns {string}
 */
export function toUTCTime(localTimeString) {
  try {
    const date = new Date()
    const targetHours = localTimeString?.split(':')[0]
    const targetMinutes = localTimeString?.split(':')[1]
    date.setHours(targetHours)
    date.setMinutes(targetMinutes)
    return (
      date.getUTCHours().toString().padStart(2, '0') +
      ':' +
      date.getUTCMinutes().toString().padStart(2, '0')
    )
  } catch (err) {
    return localTimeString
  }
}

/**
 * Convert UTC date string to local date string
 * Example:
 * We have a string 2022-04-18T14:39:39.83 UTC
 * We want to parse it into the 2022-04-18_17:39:39 if UTC +3
 * @param timeString
 * @returns {string}
 */
export function serverDateToNormal(utcDate) {
  try {
    if (utcDate) {
      const date = new Date(`${utcDate}`)
      return date.toLocaleString('sv-SE').split('.')[0].replace(' ', '_')
    }
  } catch (err) {
    return utcDate
  }
}

/**
 * Add USA code to string of 10 digits or return
 * without changes ( if it is ukrainian number)
 * Example:
 * We have a string 2341526792
 * We want to get +12341526792
 * Example:
 * We have a string +380503252784
 * We want to get +380503252784
 * @param phoneNumber
 * @returns {string}
 */
export function toPhoneWithCountryCode(phoneNumber) {
  try {
    if (phoneNumber.match(/^\((\+38)/gm)) {
      return phoneNumber.match(/\d|\+/gm).join('')
    }

    return '+1' + phoneNumber.match(/\d/gm).join('')
  } catch (err) {
    return phoneNumber
  }
}

/**
 * Remove first two symbold of string (remove USA code)
 * or return without changes ( if it is ukrainian number)
 * Example:
 * We have a string +12341526792
 * We want to get 2341526792
 * Example:
 * We have a string +380503252784
 * We want to get +380503252784
 * @param phoneNumber
 * @returns {string}
 */
export function toPhoneWithoutCode(phoneNumber) {
  try {
    if (phoneNumber.match(/^(\+38)/gm)) {
      const formattedPhone = [...phoneNumber]
      formattedPhone.unshift('(')
      formattedPhone.splice(4, 0, ' (')
      formattedPhone.splice(8, 0, ') ')
      formattedPhone.splice(12, 0, '-')
      return formattedPhone.join('')
    }
    const formattedPhone = [...phoneNumber.slice(2)]
    formattedPhone.unshift('(')
    formattedPhone.splice(4, 0, ') ')
    formattedPhone.splice(8, 0, '-')
    return formattedPhone.join('')
  } catch (err) {
    return phoneNumber
  }
}

/**
 * Check if current user logged via google
 * @returns {boolean}
 */
export function isUserLoggedViaGoogle() {
  const currentUserInfo = useRecoilValue(userInfoAtom)

  return currentUserInfo.sub.includes('google')
}
