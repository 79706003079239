import styled from 'styled-components'

import { colors } from '@/common/theme/variables'

export const Error = styled.p`
  color: ${colors.red};
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
`

export default Error
