import { withQuery } from '@/common/utils/helpers'

function registerLotRateApi(api) {
  return {
    createWeekly: (body) => api.post(`/rates/weekly`, body),
    createDaily: (body) => api.post(`/rates/daily`, body),

    updateWeekly: (id, body) => api.put(`/rates/weekly/${id}`, body),
    updatedDaily: (id, body) => api.put(`/rates/daily/${id}`, body),

    getAll: (lotId, query) => api.get(withQuery(`/rates/lot/${lotId}`, query)),
    get: (lotId, id) => api.get(withQuery(`/rates/${id}`, lotId)),
    delete: (lotId, id) => api.delete(withQuery(`/rates/${id}`, lotId)),
    getTotalPrice: (query) => api.get(withQuery('/rates/total-price', query)),

    updatedActivity: (id, lotId) =>
      api.put(withQuery(`/rates/${id}/change-activity`, lotId)),

    duplicateRate: (passId) =>
      api.post(`/rates/dublicate`, {passId}),

    getForPeriod: (lotId, query) =>
      api.get(withQuery(`/rates/lot/${lotId}/for-period`), query),
  }
}

export default registerLotRateApi
