import { useMutation, useQueryClient } from 'react-query'

import { lotFileApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { LOTS, LOT_INFO } = QUERY_KEYS

/**
 * Create file for lot
 * Mutation body:
 *    - lotId
 *    - file: document (there may be many files, just duplicate it in the body)
 * @returns useMutation instance
 */
function useCreateFile() {
  const queryClient = useQueryClient()

  const options = {
    headers: { 'Content-Type': 'multipart/form-data' },
  }

  return useMutation((data) => lotFileApi.create(data, options), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.refetchQueries(LOT_INFO)
        await queryClient.refetchQueries(LOTS)
      }
    },
  })
}

/**
 * Download file by id
 * @returns useMutation instance
 */
 function useDownloadFile(successCallback) {
  const options = { responseType: 'blob' }

  return useMutation((fileId) => lotFileApi.get(fileId, options), {
    onSuccess: (data) => {
      if (data.responseStatus === 200) {
        successCallback && runCallback(() => {
          successCallback(data)
        })
      }
    },
  })
}


/**
 * Delete file
 * @returns useMutation instance
 */
function useDeleteFile() {
  const queryClient = useQueryClient()

  return useMutation((id) => lotFileApi.delete(id), {
    onSettled: async () => {
      await queryClient.refetchQueries(LOT_INFO)
      await queryClient.refetchQueries(LOTS)
    },
  })
}

export {
  useCreateFile,
  useDownloadFile,
  useDeleteFile,
}