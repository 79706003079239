import {
  registerAccountApi,
  registerAvailabilityApi,
  registerDictionaryApi,
  registerLotEnforcementApi,
  registerLotFileApi,
  registerLotInfoApi,
  registerLotRateApi,
  registerEnforcementCompanyApi,
  registerPaymentApi,
  registerLotMessagesApi,
  registerLotViolationActionsApi,
  registerVendorContractsApi,
  registerUserApi,
  registerLtpCustomersApi,
  registerLtpContractsApi,
  registerQRcodeApi,
  registerPromoCodesApi,
  registerValidationsApi,
  registerWhiteListApi,
  registerBankAccountApi,
  registerPaymentCardApi,
  registerUserAvailabilityApi,
  registerLotFeeApi,
  registerPermitStore,
  registerStripeAccountApi,
  registerLotManagerCompanyApi,
  registerCustomerPermitStoreApi,
  registerDashboardApi,
  registerCustomerMyPermitsApi,
  registerBillingApi,
  registerWaitListApi,
} from '@/api/apis'
import api from '@/api/config'

export const accountApi = registerAccountApi(api)
export const dictionaryApi = registerDictionaryApi(api)
export const lotFileApi = registerLotFileApi(api)
export const lotInfoApi = registerLotInfoApi(api)
export const lotAvailabilityApi = registerAvailabilityApi(api)
export const lotRatesApi = registerLotRateApi(api)
export const lotEnforcementApi = registerLotEnforcementApi(api)
export const enforcementCompanyApi = registerEnforcementCompanyApi(api)
export const paymentApi = registerPaymentApi(api)
export const lotMessagesApi = registerLotMessagesApi(api)
export const lotViolationActionsApi = registerLotViolationActionsApi(api)
export const vendorContractsApi = registerVendorContractsApi(api)
export const userApi = registerUserApi(api)
export const ltpCustomersApi = registerLtpCustomersApi(api)
export const ltpContractsApi = registerLtpContractsApi(api)
export const QRcodeApi = registerQRcodeApi(api)
export const validationsApi = registerValidationsApi(api)
export const whiteListApi = registerWhiteListApi(api)
export const promoCodesApi = registerPromoCodesApi(api)
export const bankAccountApi = registerBankAccountApi(api)
export const paymentCardApi = registerPaymentCardApi(api)
export const userAvailabilityApi = registerUserAvailabilityApi(api)
export const lotFeeApi = registerLotFeeApi(api)
export const permitStoreApi = registerPermitStore(api)
export const stripeAccountApi = registerStripeAccountApi(api)
export const lotManagerCompanyApi = registerLotManagerCompanyApi(api)
export const customerPermitStoreApi = registerCustomerPermitStoreApi(api)
export const dashboardApi = registerDashboardApi(api)
export const customerMyPermitsApi = registerCustomerMyPermitsApi(api)
export const billingApi = registerBillingApi(api)
export const waitListApi = registerWaitListApi(api)
