import React from 'react'

import { subYears } from 'date-fns'
import PropTypes from 'prop-types'

import { toSelect, isUserLoggedViaGoogle } from '@/common/utils/helpers'
import { isCustomer } from '@/common/utils/roleAccesses'
import Button from '@/components/Button'
import CreatableSelect from '@/components/CreatableSelect'
import DatePicker from '@/components/DatePicker'
import Field from '@/components/Field'
import Form from '@/components/Form'
import Input from '@/components/Input'
import PhoneNumberInput from '@/components/PhoneNumberInput'
import Select from '@/components/Select'

UserForm.propTypes = {
  form: PropTypes.object,
  user: PropTypes.object,
  countries: PropTypes.array,
  states: PropTypes.array,
  handleChangePassword: PropTypes.func
}

UserForm.defaultProps = {
  form: {},
  countries: [],
  states: [],
  handleChangePassword: () => {}
}

function UserForm(props) {
  const { form, user, countries, states, handleChangePassword } = props

  const isUserCustomer = isCustomer(user?.role)
  
  return (
    <>
      <Form.FormItem>
        <Field
          label={'First Name'}
          name={'firstName'}
          form={form}
          component={Input}
          isRequired={true}
        />
      </Form.FormItem>
      <Form.FormItem>
        <Field
          label={'Last Name'}
          name={'lastName'}
          form={form}
          component={Input}
          isRequired={true}
        />
      </Form.FormItem>
      <Form.FormItem>
        <Field
          label={'Birth date'}
          name={'birthDay'}
          form={form}
          component={DatePicker}
          placeholder={'Enter your birth date'}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          maxDate={subYears(new Date(), 16)}
          minDate={subYears(new Date(), 90)}
          isController={true}
          isRequired={true}
        />
      </Form.FormItem>
      <Form.FormItem>
        <Field
          label={'Phone'}
          name={'phone'}
          form={form}
          component={PhoneNumberInput}
          isRequired={true}
        />
      </Form.FormItem>
      <Form.FormItem>
        <Field 
          label={'Email'} 
          name={'email'} 
          form={form} 
          component={Input} 
          disabled={true}
        />
      </Form.FormItem>
      <Form.FormItem>
      {isUserCustomer && (
          <Field
            label={'License Plates'}
            name={'associatedPlates'}
            form={form}
            component={CreatableSelect}
            disableMenuOptions={true}
            placeholder={'Add License Plate'}
            isController={true}
          />
        )}
      </Form.FormItem>
      <Form.FormItem>
        <Field
          label={'Country'}
          name={'countryId'}
          form={form}
          options={toSelect(countries)}
          component={Select}
          isController={true}
          isRequired={true}
        />
      </Form.FormItem>
      <Form.FormItem>
        <Field
          name={'stateId'}
          form={form}
          label={'State'}
          options={toSelect(states)}
          component={Select}
          isController={true}
          isRequired={true}
        />
      </Form.FormItem>
      <Form.FormItem>
        <Field
          name={'city'}
          form={form}
          label={'City'}
          component={Input}
          isRequired={true}
        />
      </Form.FormItem>
      <Form.FormItem>
        <Field
          name={'postalCode'}
          form={form}
          label={'ZIP'}
          component={Input}
          isRequired={true}
        />
      </Form.FormItem>
      <Form.FormItem>
        <Field
          name={'addressLine'}
          form={form}
          label={'Address Line 1'}
          component={Input}
          isRequired={true}
        />
      </Form.FormItem>
      <Form.FormItem>
        <Field
          name={'addressLine2'}
          form={form}
          label={'Address Line 2'}
          component={Input}
        />
      </Form.FormItem>
      {!isUserLoggedViaGoogle() && 
        <Form.FormItem>
          <Button
            text={'Need to change password?'}
            $marginRight={20}
            appearance={'thirdly'}
            onClick={handleChangePassword}
          />
        </Form.FormItem>
      }
    </>
  )
}

export default UserForm
