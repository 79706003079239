export const VIOLATION_STATUSES_IDX = {
  canceled: 1,
  paid: 2,
  paidInCash: 3,
  processed: 4,
  unpaid: 5,
  pending: 6,
  accepted: 7,
}

export default VIOLATION_STATUSES_IDX
