import React, { forwardRef, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'

import { IconCalendar } from '@/common/assets/icons'
import DateInput from '@/components/DateInput'

import 'react-datepicker/dist/react-datepicker.css'

const DatePicker = forwardRef((props, ref) => {
  const { setValue, placeholder, onChange, value, ...rest } = props
  const defaultValue = value ? new Date(value) : new Date()

  const [startDate, setStartDate] = useState(defaultValue)

  useEffect(() => {
    value ? setStartDate(defaultValue) : setStartDate(null)
  }, [value])

  return (
    <ReactDatePicker
      selected={startDate}
      ref={ref}
      {...rest}
      autoComplete="off"
      placeholderText={placeholder}
      onChange={async (date) => {
        if (date) {
          setStartDate(date)
          const dateString = new Date(date).toDateString()
          await setValue(dateString)
          onChange(dateString)
        } else {
          setStartDate(null)
          await setValue('')
          onChange('')
        }
      }}
      customInput={<DateInput $height={36} icon={IconCalendar} />}
    />
  )
})

DatePicker.propTypes = {
  name: PropTypes.string,
  setValue: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

DatePicker.defaultProps = {
  placeholder: 'Start date',
  name: 'DatePicker',
}

export default DatePicker
