import styled from 'styled-components'

import { marginStyle, 
  paddingStyle, 
  textAlignStyle, 
  dimensionStyle
 } from '@/common/theme/styles'

export const IndentContainer = styled.div`
  ${marginStyle};
  ${paddingStyle};
  ${textAlignStyle};
  ${dimensionStyle};
`

export const DimensionContainer = styled.div`
  ${dimensionStyle};
`


