import styled, { css } from 'styled-components'

import { device, colors } from '@/common/theme/variables'

export const FieldWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`

const requiredAfter = css`
  &::after {
    margin-left: 1px;
    content: '*';
    display: block;
    color: ${colors.trueBlue};
    font-weight: 500;
  }
`

export const Label = styled.label`
  font-size: ${(props) => props.fontSize || '14px'};
  font-weight: ${(props) => props.fontWeight || 500};
  color: ${colors.gray};
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  ${({ isRequired }) => isRequired && requiredAfter}

  @media ${device.tablet} {
    font-size: ${(props) => props.fontSize || '16px'};
    font-weight: ${(props) => props.fontWeight || 500};
    margin-bottom: 8px;
    line-height: 20px;
  }
`

const inRowStyle = css`
  flex-direction: row;
  align-items: center;

  ${Label} {
    order: 2;
    margin: 0 0 0 8px;
    font-weight: 400;
  }
`

const inRowStyleBefore = css`
  flex-direction: row;
  align-items: center;

  ${Label} {
    margin: 0 58px 0 23px;
  }
`
const borderInput = css`
  input {
    border-radius: 6px 0px 0px 6px;
  }
`

export const InputContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  border-radius: 0;

  ${({ inRow }) => inRow && inRowStyle}
  ${({ labelBefore }) => labelBefore && inRowStyleBefore}
  ${({ withAction }) => withAction && borderInput}
`
