import styled from "styled-components";

import { colors } from '@/common/theme/variables'

export const CardInputWrapper = styled.div`
  border: 1px solid ${colors.lavender};
	padding: 10px 20px;
  border-radius: 6px;
	background-color: ${colors.white};
	color: ${colors.darkGray};

`;

