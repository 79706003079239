import React from 'react'

import PropTypes from 'prop-types'

import { filteredProps } from '@/common/utils/helpers/commonHelpers'

import { FormContainer } from '../styled'

function Form(props) {
  return (
    <FormContainer {...filteredProps(props, "onSubmit")}>
      <form onSubmit={props.onSubmit}>{props.children}</form>
    </FormContainer>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.any,
}

export default Form
