/**
 * Preparing lot documents to request
 * @param lotId
 * @param documents array of documents
 * @param extractFile there is the opportunity to pass an array of documents
 * with many other data, and with the required field *file*
 * In this case, if we pass extractFile = true, the function will automatically
 * extract files from documents, in another way, we will need to pass documents
 * with only files
 * @returns formData with lotId and files
 */
export function prepareContractDocuments(
  contractId,
  documents,
  extractFile = true,
) {
  const data = new FormData()
  if (contractId) data.append('lotId', contractId)

  documents.forEach((document) => {
    if (extractFile && document?.file) {
      data.append('file', document?.file)
    } else {
      data.append('file', document)
    }
  })

  return data
}
