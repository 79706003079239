/* eslint-disable react/prop-types */
import React from 'react'

import { components } from 'react-select'

const Option =
  ({ onChange }) =>
  ({ children, ...props }) =>
    (
      <components.Option {...props}>
        <div
          onTouchEnd={(e) => {
            e.stopPropagation()
            onChange(e)
          }}
        >
          {children}
        </div>
      </components.Option>
    )

export default Option
