import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { enforcementCompanyApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { COMPANY, COMPANY_INFO, MY_COMPANY_INFO } = QUERY_KEYS

/**
 * Fetch all companies
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
 function useCompanies(query) {
  return useQuery(
    [ COMPANY, query],
    () => enforcementCompanyApi.getCompanies(query),
  )
}

/**
 * Fetch company by id
 * @param id for using pagination, sort, search and filters
 * @returns useQuery instance
 */
 function useCompany(id) {
  return useQuery(
    [ COMPANY_INFO, id],
    () => enforcementCompanyApi.getCompany(id),
  )
}

/**
 * Fetch company by id
 * @param id for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useMyCompany() {
  return useQuery(
    [ MY_COMPANY_INFO],
    () => enforcementCompanyApi.getMyCompany(),
  )
}

/**
 * Create lot
 * @param successCallback
 * @returns useMutation instance
 */
 function useAddCompany(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => enforcementCompanyApi.createCompany(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(COMPANY)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
        await queryClient.refetchQueries(COMPANY)
      }
    },
  })
}

/**
 * Update lot
 * @param successCallback
 * @returns useMutation instance
 */
function useUpdateCompany(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => 
    enforcementCompanyApi.updateCompany(data.id, data), {
      onMutate: async () => {
        await queryClient.cancelQueries(COMPANY)
        await queryClient.cancelQueries(COMPANY_INFO)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          await queryClient.refetchQueries(COMPANY)
          await queryClient.refetchQueries(COMPANY_INFO)
          runCallback(successCallback)
        }
      },
    }
  )
}

/**
 * Delete lot
 * @param successCallback
 * @returns useMutation instance
 */
function useDeleteCompany(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => enforcementCompanyApi.deleteCompany(id), {
    onMutate: async () => {
      await queryClient.cancelQueries(COMPANY)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(COMPANY)
      runCallback(successCallback)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        toast.success('Company deleted!')
        runCallback(successCallback)
        await queryClient.refetchQueries(COMPANY)
      }
    },
  })
}

/**
 * Get export file company by type
 * @param query
 * @returns useQuery instance
 */
 function useCompanyExport(successCallback) {
  return useMutation(
    (query) => enforcementCompanyApi.export(query), 
    {
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          successCallback(data)
        }
      },
    }
  )
}

export { 
  useCompanies,
  useCompany,
  useMyCompany,
  useAddCompany,
  useUpdateCompany,
  useDeleteCompany, 
  useCompanyExport,
}