import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { vendorContractsApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { VENDOR_CONTRACTS, VENDOR_CONTRACT_INFO } = QUERY_KEYS

/**
 * Fetch all contracts for lot
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useVendorContracts(query) {
  return useQuery(
    [ VENDOR_CONTRACTS, query ],
    () => vendorContractsApi.getContracts(query),
  )
}

/**
 * Fetch contract by id
 * @param id
 * @returns useQuery instance
 */
 function useVendorContract(lotId, id) {
  return useQuery(
    [ VENDOR_CONTRACT_INFO, id ],
    () => vendorContractsApi.getContract(lotId, id),
    {enabled: false, cacheTime: 1}
  )
}

/**
 * Create lot
 * @param successCallback
 * @returns useMutation instance
 */
 function useCreateVendorContract(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => vendorContractsApi.create(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(VENDOR_CONTRACTS)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
        await queryClient.refetchQueries(VENDOR_CONTRACTS)
      }
    },
  })
}

/**
 * Update lot
 * @param successCallback
 * @returns useMutation instance
 */
function useUpdateVendorContract(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => vendorContractsApi.update(data.id, data), {
    onMutate: async () => {
      await queryClient.cancelQueries(VENDOR_CONTRACTS)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.refetchQueries(VENDOR_CONTRACTS)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Delete lot
 * @param successCallback
 * @returns useMutation instance
 */
function useDeleteVendorContract(lotId, successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => vendorContractsApi.delete({lotId}, id), {
    onMutate: async () => {
      await queryClient.cancelQueries(VENDOR_CONTRACTS)
    },
    onSettled: async (data) => {
      if (data.responseStatus === 200) {
      toast.success('Company deleted!')
      await queryClient.invalidateQueries(VENDOR_CONTRACTS)
      runCallback(successCallback)
    }}
  })
}

/**
 * Get export file contracts by query
 * @param query
 * @returns useQuery instance
 */
function useVendorContractsExport(successCallback) {
  return useMutation(
    (query) => vendorContractsApi.export(query),
    {
      onSuccess: data => {
        if (data.responseStatus === 200)
          successCallback(data)
      },
    }
  )
}

export {
  useVendorContracts,
  useVendorContract,
  useCreateVendorContract,
  useUpdateVendorContract,
  useDeleteVendorContract,
  useVendorContractsExport
}