import React from 'react'

import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import { IconChevron } from '@/common/assets/icons'

import { IconContainer, ButtonContainer, ButtonText } from './styled'

function ButtonGoBack(props) {
  const { text, onClick } = props

  return (
    <ButtonContainer $alignItems={'center'} onClick={onClick}>
      <IconContainer>
        <SVG src={IconChevron} />
      </IconContainer>
      <ButtonText>{text}</ButtonText>
    </ButtonContainer>
  )
}

ButtonGoBack.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
}

ButtonGoBack.defaultProps = {
  text: 'Back',
  onClick: null,
}

export default ButtonGoBack
