import styled from 'styled-components'

import { colors } from '@/common/theme/variables'

import Text from './Text'

const Title = styled(Text)`
  font-size: 22px;
  line-height: 32px;
  color: ${colors.primary};
  font-weight: 500;
`

export default Title
