import styled from 'styled-components'


import { BaseInput } from '@/common/baseStyledElements'
import { colors } from '@/common/theme/variables'
import NumberInput from '@/components/NumberInput'

export const CurrencyBadge = styled(BaseInput)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 63px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  color: ${colors.malibu};
`

export const Input = styled(NumberInput)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  appearance: none;
  -moz-appearance: textfield;
`