import { Link as ReactLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import colors from '@/common/theme/variables/colors'

const underlineStyle = css`
  background-image: linear-gradient(
    to right,
    ${colors.lightBlueLink} 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 7px 1px;
  background-repeat: repeat-x;
`

const Link = styled(ReactLink)`
  color: ${colors.lightBlueLink};
  text-decoration: none;
  font-weight: 400;
  ${({ $underline }) => $underline &&  underlineStyle}
`

export default Link
