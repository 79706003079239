import styled from 'styled-components'

import { Text } from '@/common/baseStyledElements'
import { colors, device } from '@/common/theme/variables'

export const Container = styled.div`
  width: 100%;
  height: 64px;
  min-height: 64px;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.lavender};
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const BarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const LogoContainer = styled.div`
  width: 64px;
  height: 52px;

  @media ${device.tablet} {
    width: 44px;
    height: 32px;  
  }
`

export const IconContainer = styled.div`
  padding: 20px 24px 24px 24px;
  cursor: pointer;
`

export  const HeadText = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: ${colors.primary};
`
