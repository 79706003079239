function registerLotEnforcementApi(api) {
  return {
    getAllRules: (id) => api.get(`/lotenforcement/lot/${id}`),
    getRule: (id) => api.get(`/lotenforcement/${id}`),
    create: (data) => api.post(`/lotenforcement`, data),
    update: (id, data) => api.put(`/lotenforcement/${id}`, data),
    delete: (lotId, id) => api.delete(`/lotenforcement/${id}`, lotId),
    createAllRules: (id, data) => api.post(`/lotenforcement/all/${id}`, data),
    updateAllRules: (id, data) => api.put(`/lotenforcement/all/${id}`, data),
    deleteAllRules: (id) => api.delete(`/lotenforcement/all/${id}`),
    changeStatus: (lotId, id) =>
      api.put(`/lotenforcement/${id}/change-active-status?lotId=${lotId}`),
    changeAcceptCash: (lotId, id) =>
      api.put(`/lotenforcement/${id}/change-accept-cash?lotId=${lotId}`),
    getViolationTypes: () => api.get(`/lotenforcement/violation-types`),
    export: (lotId, type) =>
      api.get(`/lotenforcement/lot/${lotId}export?type=${type}`),
  }
}

export default registerLotEnforcementApi
