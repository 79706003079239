import React from 'react'

import PropTypes from 'prop-types'

import { IndentContainer } from './styled'

function withIndents(Component) {
  Wrapper.propTypes = {
    $marginTop: PropTypes.number,
    $marginRight: PropTypes.number,
    $marginBottom: PropTypes.number,
    $marginLeft: PropTypes.number,
    $marginVertical: PropTypes.number,
    $marginHorizontal: PropTypes.number,
    $margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    $paddingTop: PropTypes.number,
    $paddingRight: PropTypes.number,
    $paddingBottom: PropTypes.number,
    $paddingLeft: PropTypes.number,
    $paddingVertical: PropTypes.number,
    $paddingHorizontal: PropTypes.number,
    $padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    $textAlignStyle: PropTypes.string,
    
    $fullWidth: PropTypes.bool
  }

  function Wrapper(props) {
    const {
      $marginRight,
      $marginLeft,
      $marginTop,
      $marginBottom,
      $marginVertical,
      $marginHorizontal,
      $margin,

      $paddingRight,
      $paddingLeft,
      $paddingTop,
      $paddingBottom,
      $paddingVertical,
      $paddingHorizontal,
      $padding,

      $textAlignStyle,
      $fullWidth,
      ...rest
    } = props

    return (
      <IndentContainer
        $marginTop={$marginTop}
        $marginRight={$marginRight}
        $marginBottom={$marginBottom}
        $marginLeft={$marginLeft}
        $marginVertical={$marginVertical}
        $marginHorizontal={$marginHorizontal}
        $margin={$margin}
        $paddingTop={$paddingTop}
        $paddingRight={$paddingRight}
        $paddingBottom={$paddingBottom}
        $paddingLeft={$paddingLeft}
        $paddingVertical={$paddingVertical}
        $paddingHorizontal={$paddingHorizontal}
        $padding={$padding}
        $textAlignStyle={$textAlignStyle}
        $fullWidth={$fullWidth}
      >
        <Component {...rest} />
      </IndentContainer>
    )
  }

  return Wrapper
}

export default withIndents
