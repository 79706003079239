import {
  useMutation,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from 'react-query'

import { lotInfoApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { LOTS, LOT_INFO, LOT_INFO_OWNERS, LOT_INFO_MANAGERS } = QUERY_KEYS

/**
 * Fetch all lots
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useLots(query) {
  return useQuery([LOTS, query], () => lotInfoApi.getAll(query), {
    staleTime: 10000,
  })
}

/**
 * Fetch infinite parking lots
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useParkingLots(query) {
  return useInfiniteQuery(
    [LOTS, query],
    (param) => lotInfoApi.getAll({ ...query, page: param?.pageParam ?? 1 }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.pageIndex < lastPage.totalPages
          ? lastPage.pageIndex + 1
          : undefined,
    },
  )
}

/**
 * Fetch lot by id
 * @param lotId
 * @returns useQuery instance
 */
function useLot(lotId) {
  return useQuery([LOT_INFO, lotId], () => lotInfoApi.get(lotId))
}

/**
 * Get export file lots by query
 * @param query
 * @returns useQuery instance
 */
function useLotsExport(successCallback) {
  return useMutation((query) => lotInfoApi.export(query), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        successCallback(data)
      }
    },
  })
}

/**
 * Create lot
 * @param successCallback
 * @returns useMutation instance
 */
function useCreateLot(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => lotInfoApi.create(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(LOTS)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
        await queryClient.refetchQueries(LOTS)
      }
    },
  })
}

/**
 * Update lot
 * @param successCallback
 * @returns useMutation instance
 */
function useUpdateLot(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => lotInfoApi.update(data.id, data), {
    onMutate: async () => {
      await queryClient.cancelQueries(LOTS)
      await queryClient.cancelQueries(LOT_INFO)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.refetchQueries(LOTS)
        await queryClient.refetchQueries(LOT_INFO)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Delete lot
 * @param successCallback
 * @returns useMutation instance
 */
function useDeleteLot(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => lotInfoApi.delete(id), {
    onMutate: async () => {
      await queryClient.cancelQueries(LOTS)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(LOTS)
      runCallback(successCallback)
    },
  })
}

/**
 * Change status block for lot
 * @param successCallback
 * @returns useMutation instance
 */
function useChangeBlock(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => lotInfoApi.changeBlock(id), {
    onMutate: async () => {
      await queryClient.cancelQueries(LOTS)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(LOTS)
      runCallback(successCallback)
    },
  })
}

/**
 * Fetch all lot owners
 * @returns useQuery instance
 */
function useLotOwners() {
  return useQuery([LOT_INFO_OWNERS], () => lotInfoApi.getOwners())
}

/**
 * Fetch all lot managers
 * @returns useQuery instance
 */
function useLotManagers() {
  return useQuery([LOT_INFO_MANAGERS], () => lotInfoApi.getManagers())
}

export {
  useLots,
  useLot,
  useLotsExport,
  useCreateLot,
  useUpdateLot,
  useDeleteLot,
  useChangeBlock,
  useLotOwners,
  useLotManagers,
  useParkingLots,
}
