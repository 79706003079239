import { useMutation, useQuery, useQueryClient } from 'react-query'

import { validationsApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { VALIDATIONS, VALIDATIONS_INFO } = QUERY_KEYS

/**
 * Fetch all validations for lot
 * @returns useQuery instance
 */
function useValidations(query) {
  return useQuery([VALIDATIONS, query], () => validationsApi.get(query))
}

/**
 * Create validation
 * @param successCallback
 * @returns useMutation instance
 */
function useCreateValidations(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => validationsApi.create(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(VALIDATIONS)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
        await queryClient.refetchQueries(VALIDATIONS)
      }
    },
  })
}

/**
 * Update validation
 * @param successCallback
 * @returns useMutation instance
 */
function useUpdateValidations(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => validationsApi.update(data.id, data), {
    onMutate: async () => {
      await queryClient.cancelQueries(VALIDATIONS)
      await queryClient.cancelQueries(VALIDATIONS_INFO)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.refetchQueries(VALIDATIONS)
        await queryClient.refetchQueries(VALIDATIONS_INFO)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Delete validation
 * @param successCallback
 * @returns useMutation instance
 */
function useDeleteValidations(lotId, successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => validationsApi.delete({lotId}, id), {
    onMutate: async () => {
      await queryClient.cancelQueries(VALIDATIONS)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(VALIDATIONS)
      runCallback(successCallback)
    },
  })
}

export {
  useValidations,
  useCreateValidations,
  useUpdateValidations,
  useDeleteValidations,
}
