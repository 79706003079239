import styled, { css } from 'styled-components'

import { dimensionStyle, positionStyle } from '@/common/theme/styles'
import { device, colors } from '@/common/theme/variables'

const bigButton = css`
  font-size: 14px;
  height: 52px;
`

const extraSmallButton = css`
  line-height: 12px;
  padding: 7px 19px;
`

const disabledStyle = css`
  background-color: ${colors.silver};
  color: ${colors.white};
  border-color: ${colors.silver};
  cursor: not-allowed;
`

const btnPrimaryStyle = css`
  background-color: ${colors.primary};
  color: ${colors.white};
`

const btnSecondaryStyle = css`
  border: 1px solid ${({ $color }) => $color || colors.primary};
  background-color: ${colors.white};
  color: ${({ $color }) => $color || colors.primary};
`

const btnThirdlyStyle = css`
  border: none;
  background-color: unset;
  color: ${({ $color }) => $color || colors.primary};
  padding: 0;
  font-size: 12px;
  line-height: 20px;
`

// Mean type, not text itself
const btnDeleteStyle = css`
  background: ${colors.white};
  border-color: ${colors.lavender};
  box-shadow: none;
  color: ${colors.lightGrayM};
  height: auto;
  font-size: 12px;
  line-height: 12px;
  padding: 8px 40px;

  &:hover {
    color: ${colors.red};
    border-color: ${colors.red};
  }
`
const btnClearFormStyle = css`
  background: none;
  border: none;
  color: ${colors.quickSilver};
  padding: 0px;
`

const btnValidationStyle = css`
  font-size: 16px;
  line-height: 16px;
  background: ${colors.lightGreen};
  color: ${colors.white};
  border: none;
  position: relative;

  @media ${device.tablet} {
    width: 100vw;
    border: none;
    border-radius: unset;
    padding: 8px 0px;
    ${positionStyle};
  }
`

export const ButtonComponent = styled.button`
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-size: 12px;
  font-weight: 500;
  border-radius: 100px;
  padding: 8px 20px;
  display: inline-block;
  border: 1px solid ${colors.primary};
  ${dimensionStyle};
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;

  ${({ appearance }) => appearance === 'primary' && btnPrimaryStyle};
  ${({ appearance }) => appearance === 'secondary' && btnSecondaryStyle};
  ${({ appearance }) => appearance === 'thirdly' && btnThirdlyStyle};
  ${({ appearance }) => appearance === 'delete' && btnDeleteStyle};
  ${({ appearance }) => appearance === 'clearForm' && btnClearFormStyle};
  ${({ appearance }) => appearance === 'validationCode' && btnValidationStyle};
  ${({ sizeType }) => sizeType === 'big' && bigButton};
  ${({ sizeType }) => sizeType === 'extraSmall' && extraSmallButton};
  ${({ disabled }) => disabled && disabledStyle};
  ${({ textSize }) => textSize && `font-size: ${textSize}px`};
`
