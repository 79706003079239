import styled from 'styled-components'

import { colors } from '@/common/theme/variables'

import Text from './Text'

const ModalTitle = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  color: ${colors.gray};
  text-align: center;
`

export default ModalTitle