function registerUserAvailabilityApi(api) {
  return {
    getAllByWeek: (whiteListId) =>
      api.get(`/whitelist/schedule/${whiteListId}/week`),
    getByWeek: (id, whiteListId) =>
      api.get(`/whitelist/schedule/${whiteListId}/week/${id}`),
    createByWeek: (whiteListId, data) =>
      api.post(`/whitelist/schedule/${whiteListId}/week`, data),
    updateByWeek: (whiteListId, data) =>
      api.put(`/whitelist/schedule/${whiteListId}/week/`, data),
    deleteByWeek: (id) => api.delete(`/whitelist/schedule/week/${id}`),

    getAllByDate: (whiteListId) =>
      api.get(`/whitelist/schedule/${whiteListId}/day`),
    getByDate: (id, whiteListId) =>
      api.get(`/whitelist/schedule/${whiteListId}/day/${id}`),
    createByDate: (whiteListId, data) =>
      api.post(`/whitelist/schedule/${whiteListId}/day`, data),
    updateByDate: (whiteListId, id, data) =>
      api.put(`/whitelist/schedule/${whiteListId}/day/${id}`, data),
    deleteByDate: (id) => api.delete(`/whitelist/schedule/day/${id}`),
  }
}

export default registerUserAvailabilityApi
