import styled from 'styled-components'

import { colors } from '@/common/theme/variables'
import device from '@/common/theme/variables/mediaQuery'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px 25px 40px 25px;
  background-color: ${({theme} )=> theme.background || colors.bgContent};
  display: flex;
  flex-direction: column;
  overflow: scroll;

  @media ${device.tablet} {
    padding: 0 16px 16px 16px;
  }
`
