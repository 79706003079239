import * as yup from 'yup'

import regExpValidations from '@/common/utils/const/regExp'

const userProfileSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required').nullable(),
  lastName: yup.string().required('Last Name is required').nullable(),
  phone: yup
    .string()
    .required('Phone is required')
    .matches(regExpValidations?.phoneRegExp, 'Phone number is not valid')
    .nullable(),
  email: yup
    .string()
    .required('Email is required')
    .matches(regExpValidations?.emailRegExp, 'Email is not valid'),
  birthDay: yup
    .string('Birth Day is required')
    .required('Birth Day is required')
    .nullable(),
  countryId: yup.number().required('Please, select counrry'),
  stateId: yup.number().required('Please, select state'),
  city: yup.string().required('City is required'),
  postalCode: yup
    .string()
    .required('ZIP is required')
    .matches(regExpValidations.postalRegExp, 'ZIP should be valid'),
  addressLine: yup.string().required('Address Line is required'),
})

export default userProfileSchema
