import React from 'react'

import PropTypes from 'prop-types'

import { isUserLoggedViaGoogle } from '@/common/utils/helpers'
import Button from '@/components/Button'
import Field from '@/components/Field'
import Form from '@/components/Form'
import Input from '@/components/Input'
import PhoneNumberInput from '@/components/PhoneNumberInput'

OfficerForm.propTypes = {
  form: PropTypes.object,
  handleChangePassword: PropTypes.func
}

OfficerForm.defaultProps = {
  form: {},
  handleChangePassword: () => {}
}

function OfficerForm(props) {
  const { form, handleChangePassword } = props
  
  return (
    <>
      <Form.FormItem>
        <Field
          label={'First Name'}
          name={'firstName'}
          form={form}
          component={Input}
          isRequired={true}
        />
      </Form.FormItem>
      <Form.FormItem>
        <Field
          label={'Last Name'}
          name={'lastName'}
          form={form}
          component={Input}
          isRequired={true}
        />
      </Form.FormItem>
      <Form.FormItem>
        <Field
          label={'Phone'}
          name={'phone'}
          form={form}
          component={PhoneNumberInput}
          isRequired={true}
        />
      </Form.FormItem>
      <Form.FormItem>
        <Field 
          label={'Email'} 
          name={'email'} 
          form={form} 
          component={Input} 
          disabled={true}
        />
      </Form.FormItem>
      {!isUserLoggedViaGoogle() && 
        <Form.FormItem>
          <Button
            text={'Need to change password?'}
            $marginRight={20}
            appearance={'thirdly'}
            onClick={handleChangePassword}
          />
        </Form.FormItem>
      }
    </>
  )
}

export default OfficerForm
