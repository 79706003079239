const Customer = {
  lotManagement: {
    read: false,
    lotInfo: false,
    create: false,
    update: false,
    delete: false,
  },
  lotAvailability: {
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  lotPricing: {
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  lotViolations: {
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  lotMessages: {
    read: false,
  },
  vendorContracts: {
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  whiteList: {
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  violationsList: {
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  violationsCompanies: {
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  ltp: {
    read: true,
    create: false,
    update: false,
    delete: false,
    readCustomers: false,
    signContract: true,
    rejectContract: true,
    customFile: false,
  },
  messages: {
    read: false,
    update: false,
  },
  user: {
    read: true,
    readList: false,
    create: false,
    update: false,
    updateAll: true,
    delete: false,
    invate: false,
  }
}

export default Customer