import * as yup from 'yup'

const customerAddLicensePlates = yup.object().shape({
  associatedPlates: yup
    .array()
    .of(
      yup.object().shape({
        value: yup
          .string()
          .max(10, 'Maximum length of license plate is 10 symbols'),
        text: yup.string(),
      }),
    )
    .min(1, 'Select at least one License Plate')
    .required('Select at least one License Plate'),
})

export default customerAddLicensePlates
