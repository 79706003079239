const QUERY_KEYS = {
  DICTIONARY: 'DICTIONARY',

  /* Lot Info Query Keys */
  LOTS: 'LOTS',
  LOTS_EXPORT: 'LOTS_EXPORT',
  LOT_INFO: 'LOT_INFO',
  LOT_INFO_OWNERS: 'LOT_INFO_OWNERS',
  LOT_INFO_MANAGERS: 'LOT_INFO_MANAGERS',

  /* Lot Availability Query Keys */
  AVAILABILITY_BY_WEEK: 'LOT_AVAILABILITY_BY_WEEK',
  AVAILABILITY_BY_DATE: 'LOT_AVAILABILITY_BY_DATE',
  LOT_AVAILABILITY_SCHEDULE_BY_DATES: 'LOT_AVAILABILITY_SCHEDULE-BY-DATES',
  LOT_AVAILABILITY_SCHEDULE_BY_WEEK: 'LOT_AVAILABILITY_SCHEDULE-BY-WEEK',

  /* Lot Manager Company Query Keys */
  LOT_MANAGER_COMPANIES: 'LOT_MANAGER_COMPANIES',
  LOT_MANAGER_COMPANY_INFO: 'LOT_MANAGER_COMPANY_INFO',
  LOT_MANAGER_COMPANY_CURRENT: 'LOT_MANAGER_COMPANY_CURRENT',

  /* Lot Schedule Query Keys */
  RATES: 'RATES',
  RATES_NOW: 'RATES_NOW',
  VALIDATION_CODE: 'VALIDATION_CODE',
  ACTIVE_PASS: 'ACTIVE_PASS',
  RATE: 'RATE',
  RATES_FOR_PERIOD: 'RATES_FOR_PERIOD',

  /* Lot Enforcement Query Keys */
  RULES: 'RULES',
  RULE: 'RULE',
  CREATE_RULE: 'CREATE_RULE',
  UPDATE_RULE: 'UPDATE_RULE',
  CHANGE_STATUS_RULE: 'CHANGE_STATUS_RULE',
  ENFORCEMENT_EXPORT: 'ENFORCEMENT_EXPORT',
  VIOLATION_TYPES: 'VIOLATION_TYPES',

  /* Payment Query Keys */
  PAYMENT_INVOICE: 'PAYMENT_INVOICE',

  /* Enforcement Company Query Keys */
  COMPANY: 'COMPANY',
  COMPANY_INFO: 'COMPANY_INFO',
  MY_COMPANY_INFO: 'MY_COMPANY_INFO',

  /* Lot Messages Query Keys */
  LOT_MESSAGES: 'LOT_MESSAGES',

  /* Lot Messages Query Keys */
  VIOLATION_ACTIONS: 'VIOLATION_ACTIONS',
  VIOLATION_SEARCH_PLATES: 'VIOLATION_SEARCH_PLATES',

  /* Vendor Contracts Query Keys */
  VENDOR_CONTRACTS: 'VENDOR_CONTRACTS',
  VENDOR_CONTRACT_INFO: 'VENDOR_CONTRACT_INFO',

  /*Message Templates Query Keys */
  MESSAGE_TEMPLATES: 'MESSAGE_TEMPLATES',
  MESSAGE_TEMPLATE_INFO: 'MESSAGE_TEMPLATE_INFO',

  /* User Query Keys */
  USER_INFO: 'USER_INFO',
  USER_CURRENT: 'USER_CURRENT',
  USER_ENFORCER_ACTIIONS: 'USER_ENFORCER_ACTIIONS',

  /* User Billing History Query Keys */
  BILLING_DICTIONARIES: 'BILLING_DICTIONARIES',
  USER_BILLING_HISTORY: 'USER_BILLING_HISTORY',

  /* Long Term Contracts */
  LTP_CUSTOMERS: 'LTP_CUSTOMERS',
  LTP_ADD_CUSTOMERS: 'LTP_ADD_CUSTOMERS',
  LTP_CONTRACTS: 'LTP_CONTRACTS',
  LTP_CONTRACTS_INFO: 'LTP_CONTRACTS_INFO',
  LTP_CONTRACTS_CUSTOMER: 'LTP_CONTRACTS_CUSTOMER',
  LTP_CUSTOM_PAGE: 'LTP_CUSTOM_PAGE',

  /* Agrements */
  AGREEMENTS: 'AGREEMENTS',
  AGREEMENT_INFO: 'AGREEMENT_INFO',

  /*QR code generate Keys */
  GET_QR_CODE: 'GET_QR_CODE',

  /* Validations */
  VALIDATIONS: 'VALIDATIONS',
  VALIDATIONS_INFO: 'VALIDATIONS_INFO',

  /* White List Query Keys */
  WHITE_LIST: 'WHITE_LIST',

  /* Promo Codes Query Keys */
  PROMO_CODE: 'PROMO_CODE',

  /* Bank Account Query Keys */
  BANK_ACCOUNT: 'BANK_ACCOUNT',

  /* Payment Card Query Keys */
  PAYMENT_CARD: 'PAYMENT_CARD',

  /* UserWhiteList Query Keys */
  USER_AVAILABILITY_BY_WEEK: 'USER_AVAILABILITY_BY_WEEK',
  USER_AVAILABILITY_BY_DATE: 'USER_AVAILABILITY_BY_DATE',

  /* Stripe Account Query Keys */
  STRIPE_ACCOUNT: 'STRIPE_ACCOUNT',
  STRIPE_ACCOUNT_CHECK: 'STRIPE_CHECK',

  /* Lot Fee Query Keys */
  LOT_FEE: 'LOT_FEE',
  FEE_CREATE: 'FEE_CREATE',
  FEE_CHANGE_ACTIVITY: 'FEE_CHANGE_ACTIVITY',
  FEES_AMOUNT: 'FEES_AMOUNT',
  REPORT_TYPES: 'REPORT_TYPES',
  LOT_REFERRALS: 'LOT_REFERRALS',

  /* Dashboard Query Keys */
  REVENUE: 'REVENUE',
  REVENUE_EXPORT: 'REVENUE_EXPORT',
  REAL_TIME: 'REAL_TIME',
  REVENUE_STATISTIC: 'REVENUE_STATISTIC',
  LOTS_STATISTIC: 'LOTS_STATISTIC',
  LOTS_PAYMENT_TYPES: 'LOTS_PATMENT_TYPES',
  LOTS_STATISTIC_COMPARE: 'LOTS_STATISTIC_COMPARE',

  /* Customer Permit Store Query Keys */
  ALL_LOTS: 'ALL_LOTS',
  MY_PERMITS: 'MY_PERMITS',

  /* User Management Query Keys */
  ALL_USERS: 'ALL_USERS',
  USER_ROLES: 'USER_ROLES',
  USER_ROLES_FOR_CHANGE: 'USER_ROLES_FOR_CHANGE',

  /* PermitStore Query Keys */
  LOT_NAME: 'LOT_NAME',
}

export default QUERY_KEYS
