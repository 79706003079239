import { withQuery } from '@/common/utils/helpers'

function registerLotFeeApi(api) {
  return {
    get: (lotId) => api.get(`/lot-fee/lot/${lotId}`),
    update: (id, data) => api.put(`/lot-fee/${id}`, data),
    changeActivity: (status) =>
      api.put(withQuery(`/lot-fee/change-activity`, status)),
    getFeesAmount: (lotId, query) =>
      api.get(withQuery(`/lot-fee/received/lot/${lotId}`, query)),
    feesAmountExport: ({ lotId, ...query }) =>
      api.get(withQuery(`/lot-fee/received/lot/${lotId}/export`, query), {
        responseType: 'blob',
      }),
    getReportTypes: () => api.get(`/payment/report/types`),
    exportRepot: (query) =>
      api.get(withQuery(`/payment/report/export`, query), {
        responseType: 'blob',
      }),
  }
}

export default registerLotFeeApi
