import { IconChevronDown, IconClose } from '@/common/assets/icons'
import colors from '@/common/theme/variables/colors'

const customStyles = {
  control: (base, state) => {
    const borderColor = state.isFocused ? colors.lightBlue : colors.lavender

    return {
      ...base,

      fontSize: '14px',
      minHeight: '32px',
      boxShadow: '0px 1px 2px 0px #0000000D',
      border: `1px solid ${borderColor}`,

      '&:hover': {
        borderColor: colors.lightBlue,
      },
    }
  },

  singleValue: (base) => ({
    ...base,

    paddingLeft: '2px',
    color: `${colors.bgContent}`,
  }),

  menu: (provided, state) => {
    const display = state.options.length > 0 ? 'block' : 'none'

    return {
      ...provided,
      display,

      border: `1px solid ${colors.lavender}`,
      boxShadow: 'none',
      '&:hover': {
        borderColor: colors.lightBlue,
      },
    }
  },

  menuList: (provided) => ({
    ...provided,

    fontSize: '14px',
    padding: `1px 4px`,
  }),

  option: (provided, state) => {
    const backgroundColor = state.isSelected
      ? colors.backgroundBlue
      : state.isFocused
      ? colors.lightBlue
      : colors.white
    const color = state.isFocused ? colors.white : colors.bgContent

    return {
      ...provided,
      backgroundColor,
      color,

      padding: `4px 8px`,
      borderRadius: `3px`,
      margin: '1px 0px',
      '&:hover': {
        backgroundColor: colors.lightBlue,
        color: colors.white,
      },
    }
  },

  indicatorSeparator: (base) => ({
    ...base,

    display: 'none',
  }),

  dropdownIndicator: (base, state) => {
    const display =
      state?.options.length === 0 ||
      state?.options[0]?.text?.includes('Add New License Plate:')
        ? 'none'
        : 'block'

    return {
      ...base,
      display,

      background: `url(${IconChevronDown}) center no-repeat`,
      marginRight: `13px`,
      marginTop: `2px`,
      svg: {
        display: 'none',
      },
    }
  },

  placeholder: (base) => ({
    ...base,

    fontSize: '14px',
  }),

  valueContainer: (base) => ({
    ...base,

    padding: '0px 8px',
  }),

  multiValue: (styles) => ({
    ...styles,

    backgroundColor: colors.backgroundBlue,
    padding: '4px 6px 4px 6px',
    borderRadius: '24px',
    margin: '2px 10px 2px 0',
  }),

  multiValueLabel: (styles) => ({
    ...styles,

    color: colors.primary,
    padding: 0,
    paddingLeft: 0,
    fontSize: '12px',
    lineHeight: '12px',
  }),

  multiValueRemove: (styles) => ({
    ...styles,

    background: `url(${IconClose}) center no-repeat`,
    height: '12px',
    width: '12px',
    marginLeft: '6px',
    svg: {
      display: 'none',
    },
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  }),

  input: (styles) => ({
    ...styles,

    margin: 0,
    marginLeft: '2px',
  }),

  clearIndicator: (styles) => ({
    ...styles,

    display: 'none',
  }),
}

export default customStyles
