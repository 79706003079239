import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import { IconCopy} from '@/common/assets/icons'
import { BaseInput } from '@/common/baseStyledElements'

import { Row, ButtonCopy} from './styled'

const Input = forwardRef((props, ref) => {
  const { id, copy } = props

  const handleCopy = () => {
    const copyText = document.getElementById(id)
    navigator.clipboard.writeText(copyText.value)
  }

  if(copy)
    return (
      <Row>
        <BaseInput type={'text'} ref={ref} {...props} />
        <ButtonCopy onClick={handleCopy}>
          <SVG src={IconCopy}/>
        </ButtonCopy>
      </Row>
    )

  return (
    <BaseInput type={'text'} ref={ref} {...props} />
  )
})

Input.propTypes = {
  id: PropTypes.string,
  copy: PropTypes.number,
}

Input.defaultProps = {
  id: 'some',
  copy: 0,
}

export default Input
