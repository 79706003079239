import React from 'react'

import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import { IconHelp } from '@/common/assets/icons'
import { Col } from '@/common/baseStyledElements'

import { FormGroupTitle, Content } from '../styled'

FormGroup.propTypes = {
  title: PropTypes.string,
  titleType: PropTypes.oneOf([ 'small', 'normal' ]),
  children: PropTypes.any,
  childrenProps: PropTypes.any,
  direction: PropTypes.oneOf([ 'column', 'row' ]),
  help: PropTypes.bool,
  textHelp: PropTypes.string,
  titleWidth: PropTypes.number,
}

FormGroup.defaultProps = {
  title: '',
  titleType: 'normal',
  children: null,
  childrenProps: null,
  direction: 'column',
  help: false,
  textHelp: 'Help'
}

function FormGroup(props) {
  const {
    children,
    title,
    childrenProps,
    titleType,
    direction,
    help,
    textHelp,
    titleWidth
  } = props

  return (
    <Col {...props}>
      <FormGroupTitle titleType={titleType} titleWidth={titleWidth}>
        {title}
        {help && 
          <sup
            data-tip={textHelp}
            style={{paddingLeft: '2px'}}
            data-multiline={true}
          >
            <SVG src={IconHelp} />
          </sup>
        }
      </FormGroupTitle>
      <Content
        {...childrenProps}
        $flexDirection={direction} 
      >
        {children}
      </Content>
    </Col>
  )
}


export default FormGroup