import styled from 'styled-components'

import { colors } from '@/common/theme/variables'

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.lavender};
  margin: 16px 0;
`

export default Divider