import React, { Suspense, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { BrowserRouter as Router } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import { registerRequestInterceptor } from '@/api/interceptors'
import { useSignUp } from '@/common/services'
import { userInfoAtom } from '@/common/store/currentUser'
import LoadingScreen from '@/components/LoadingScreen'

import HomeLayout from '../layout/HomeLayout'
import PublickRoutes from './PublickRoutes'

function AppRouter() {
  const { isAuthenticated, getAccessTokenSilently, isLoading, user } =
    useAuth0()

  const userInfoRecoil = useSetRecoilState(userInfoAtom)

  const { mutate: signUp, isLoading: isLoadingUser, isSuccess } = useSignUp()

  useEffect(() => {
    if (user) {
      signUp({
        email: user.email,
        authId: user.sub,
      })
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        registerRequestInterceptor(token)
      })
      userInfoRecoil(user)
    }
  }, [isAuthenticated, getAccessTokenSilently])

  if (isLoading || isLoadingUser) return <LoadingScreen />

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Router>
        <PublickRoutes isAuthenticated={isAuthenticated} />
        {isSuccess && <HomeLayout isSignUp={isSuccess} />}
      </Router>
    </Suspense>
  )
}

export default AppRouter
