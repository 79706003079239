import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { lotAvailabilityApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'
const {
  AVAILABILITY_BY_WEEK,
  AVAILABILITY_BY_DATE,
  LOT_AVAILABILITY_SCHEDULE_BY_DATES,
  LOT_AVAILABILITY_SCHEDULE_BY_WEEK,
} = QUERY_KEYS

/**
 * Get all weekly availabilities
 * @param lotId
 * @returns {UseQueryResult<AxiosResponse<any>, unknown>}
 */
function useAvailabilitiesByWeek(lotId, query) {
  return useQuery(
    [ AVAILABILITY_BY_WEEK, query ], 
    () => lotAvailabilityApi.getAllByWeek(lotId, query),
  )
}

/**
 * Get weekly availability
 * @param availabilityId
 * @param enabled
 * @returns {UseQueryResult<AxiosResponse<any>, unknown>}
 */
function useAvailabilityByWeek(availabilityId, lotId, enabled = false) {
  return useQuery(
    [ AVAILABILITY_BY_DATE, availabilityId ],
    () => lotAvailabilityApi.getByWeek(availabilityId, lotId),
    { enabled },
  )
}

/**
 * Create weekly availability
 * @param successCallback
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
function useCreateAvailabilityByWeek(successCallback) {
  const queryClient = useQueryClient()

  return useMutation(
    (data) => lotAvailabilityApi.createByWeek(data.lotId, data),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(AVAILABILITY_BY_WEEK)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          toast.success('Week availability added!')
          await queryClient.invalidateQueries(AVAILABILITY_BY_WEEK)
          runCallback(successCallback)
        }
      },
    },
  )
}

/**
 * Update weekly availability
 * @param successCallback
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
function useEditAvailabilityByWeek(successCallback) {
  const queryClient = useQueryClient()

  return useMutation(
    (data) => lotAvailabilityApi.updateByWeek(data.lotId, data),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(AVAILABILITY_BY_WEEK)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          toast.success('Week availability updated!')
          await queryClient.invalidateQueries(AVAILABILITY_BY_WEEK)
          runCallback(successCallback)
        }
      },
    },
  )
}

/**
 * Delete weekly availability
 * @param successCallback
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
function useDeleteAvailabilityByWeek(lotId, successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => lotAvailabilityApi.deleteByWeek({lotId}, id), {
    onMutate: async () => {
      await queryClient.cancelQueries(AVAILABILITY_BY_WEEK)
    },
    onSettled: async (data) => {
      if (data.responseStatus === 200) {
        toast.success('Availability deleted!')
        await queryClient.invalidateQueries(AVAILABILITY_BY_WEEK)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Get all availabilities by date
 * @param lotId
 * @returns {UseQueryResult<AxiosResponse<any>, unknown>}
 */
function useAvailabilitiesByDate(lotId, query) {
  return useQuery([AVAILABILITY_BY_DATE, query], () =>
    lotAvailabilityApi.getAllByDate(lotId, query),
  )
}

/**
 * Get availability by date
 * @param availabilityId
 * @param enabled
 * @returns {UseQueryResult<AxiosResponse<any>, unknown>}
 */
function useAvailabilityByDate(availabilityId, lotId, enabled = false) {
  return useQuery(
    ['availability', availabilityId],//to do
    () => lotAvailabilityApi.getByDate(availabilityId, lotId),
    {
      enabled,
    },
  )
}

/**
 * Create availability by date
 * @param successCallback
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
function useCreateAvailabilityByDate(successCallback) {
  const queryClient = useQueryClient()

  return useMutation(
    (data) => lotAvailabilityApi.createByDate(data.lotId, data),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(AVAILABILITY_BY_DATE)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          toast.success('Availability by date added!')
          await queryClient.invalidateQueries(AVAILABILITY_BY_DATE)
          runCallback(successCallback)
        }
      },
    },
  )
}

/**
 * Update availability by date
 * @param successCallback
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
function useEditAvailabilityByDate(successCallback) {
  const queryClient = useQueryClient()

  return useMutation(
    (data) => lotAvailabilityApi.updateByDate(data.lotId, data.id, data),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(AVAILABILITY_BY_DATE)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          toast.success('Availability by date updated!')
          await queryClient.invalidateQueries(AVAILABILITY_BY_DATE)
          runCallback(successCallback)
        }
      },
    },
  )
}

/**
 * Delete weekly availability
 * @param successCallback
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
function useDeleteAvailabilityByDate(lotId, successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => lotAvailabilityApi.deleteByDate({lotId}, id), {
    onMutate: async () => {
      await queryClient.cancelQueries(AVAILABILITY_BY_DATE)
    },
    onSettled: async (data) => {
      if (data.responseStatus === 200) {
        toast.success('Availability deleted!')
        await queryClient.invalidateQueries(AVAILABILITY_BY_DATE)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Get lot availability by start and stop dates
 * @param successCallback
 * @returns {UseQueryResult<AxiosResponse<any>, unknown>}
 */
function useLotAvailabilityByDates(lotId, startDate, stopDate) {
  return useQuery(
    [LOT_AVAILABILITY_SCHEDULE_BY_DATES, lotId, startDate, stopDate],
    () => lotAvailabilityApi.getDayShedule(lotId, startDate, stopDate),
  )
}

/**
 * Get lot availability by start and stop dates
 * @param successCallback
 * @returns {UseQueryResult<AxiosResponse<any>, unknown>}
 */
function useLotAvailabilityByWeek(lotId) {
  return useQuery([LOT_AVAILABILITY_SCHEDULE_BY_WEEK, lotId], () =>
    lotAvailabilityApi.getWeekShedule(lotId),
  )
}

function useUpdateByWeekActivity(lotId) {
  const queryClient = useQueryClient()

  return useMutation(
    (id) => lotAvailabilityApi.changeActivityByWeek({ lotId }, id),
    {
    onMutate: async () => {
      await queryClient.cancelQueries(AVAILABILITY_BY_WEEK)
    },
    onSettled: async (data) => {
      if (data.responseStatus === 200) {
        toast.success('Availability updated!')
        await queryClient.invalidateQueries(AVAILABILITY_BY_WEEK)
      }
    },
  })
}

function useUpdateByDateActivity(lotId ) {
  const queryClient = useQueryClient()

  return useMutation(
    (id) => lotAvailabilityApi.changeActivityByDate({ lotId }, id),
    {
    onMutate: async () => {
      await queryClient.cancelQueries(AVAILABILITY_BY_DATE)
    },
    onSettled: async (data) => {
      if (data.responseStatus === 200) {
        toast.success('Availability updated!')
        await queryClient.invalidateQueries(AVAILABILITY_BY_DATE)
      }
    },
  })
}

export {
  /* Availabilities by week*/
  useAvailabilitiesByWeek,
  useAvailabilityByWeek,
  useEditAvailabilityByWeek,
  useCreateAvailabilityByWeek,
  useDeleteAvailabilityByWeek,
  /* Availabilities by date */
  useAvailabilitiesByDate,
  useAvailabilityByDate,
  useCreateAvailabilityByDate,
  useEditAvailabilityByDate,
  useDeleteAvailabilityByDate,
  /* Lot Availabilities*/
  useLotAvailabilityByDates,
  useLotAvailabilityByWeek,
  /* Availabilities activity*/
  useUpdateByWeekActivity,
  useUpdateByDateActivity,
}
