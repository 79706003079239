import { useQuery, useQueryClient, useMutation } from 'react-query'

import { userApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const {
  USER_INFO,
  USER_CURRENT,
  USER_ENFORCER_ACTIIONS,
  ALL_USERS,
  USER_ROLES,
  USER_ROLES_FOR_CHANGE,
  LOT_MANAGER_COMPANY_INFO,
} = QUERY_KEYS

/**
 * Fetch current User
 * @param
 * @returns useQuery instance
 */
function useUserCurrent(enabled = true) {
  return useQuery([USER_CURRENT], () => userApi.getUserCurrent(), {
    staleTime: Infinity,
    enabled,
  })
}

function useUserEnforcerActions(enabled = true) {
  return useQuery(
    [USER_ENFORCER_ACTIIONS],
    () => userApi.getUserEnforcerActions(),
    {
      staleTime: Infinity,
      enabled,
    },
  )
}

/**
 * Fetch User by id
 * @param id
 * @returns useQuery instance
 */
function useUser(id) {
  return useQuery([USER_INFO, id], () => userApi.getUserById(id))
}

/**
 * Invite User
 * @param successCallback
 * @returns useMutation instance
 */
function useInviteUser(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => userApi.invite(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(ALL_USERS)
      await queryClient.cancelQueries(LOT_MANAGER_COMPANY_INFO)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.invalidateQueries(ALL_USERS)
        await queryClient.invalidateQueries(LOT_MANAGER_COMPANY_INFO)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Update currentUser
 * @param successCallback
 * @returns useMutation instance
 */
function useUpdateUserCurrent(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => userApi.update(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(USER_CURRENT)
      await queryClient.cancelQueries(LOT_MANAGER_COMPANY_INFO)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.invalidateQueries(USER_CURRENT)
        await queryClient.invalidateQueries(LOT_MANAGER_COMPANY_INFO)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Update User by id
 * @param successCallback
 * @returns useMutation instance
 */
function useUpdateUser(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => userApi.updateById(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(ALL_USERS)
      await queryClient.cancelQueries(USER_INFO)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.invalidateQueries(ALL_USERS)
        await queryClient.invalidateQueries(USER_INFO)
        await queryClient.invalidateQueries(LOT_MANAGER_COMPANY_INFO)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Delete User by id
 * @param successCallback
 * @returns useMutation instance
 */
function useDeleteUser(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => userApi.delete(id), {
    onMutate: async () => {
      await queryClient.cancelQueries(ALL_USERS)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.invalidateQueries(ALL_USERS)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Fetch user roles
 * @param query for other types
 * @returns useQuery instance
 */
function useUserRoles(query) {
  return useQuery([USER_ROLES], () => userApi.getRoles(query))
}

/**
 * Fetch roles which is acceptable fo user
 * @param query for other types
 * @returns useQuery instance
 */
function useRolesForChange(roleId) {
  return useQuery([USER_ROLES_FOR_CHANGE, roleId], () =>
    userApi.getRolesForChange(roleId),
  )
}

/**
 * Fetch all users
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useAllUsers(query) {
  return useQuery([ALL_USERS, query], () => userApi.getAll(query))
}

/**
 * Block user
 * @param successCallback
 * @returns useMutation instance
 */
function useBlockUser() {
  return useMutation((id) => userApi.block(id))
}

/**
 * Unblock user
 * @param successCallback
 * @returns useMutation instance
 */
function useUnblockUser() {
  return useMutation((id) => userApi.unblock(id))
}

export {
  useUserCurrent,
  useUserEnforcerActions,
  useUser,
  useInviteUser,
  useUpdateUserCurrent,
  useUpdateUser,
  useDeleteUser,
  useAllUsers,
  useBlockUser,
  useUnblockUser,
  useUserRoles,
  useRolesForChange,
}
