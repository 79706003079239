import styled from 'styled-components'

import { baseStyles } from '@/common/theme/styles'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${baseStyles};

  &:last-child {
    border-radius: 0 0 8px 8px;
  }
`

export default Row
