import { css } from 'styled-components'

const marginStyle = css`
  ${({ $marginTop }) => {
    return typeof $marginTop === 'number'
    ? `margin-top: ${$marginTop}px;`
    : `margin-top: ${$marginTop};`
  }}
  ${({ $marginRight }) => {
    return  typeof $marginRight === 'number'
    ? `margin-right: ${$marginRight}px;`
    : `margin-right: ${$marginRight};`
  }}
  ${({ $marginBottom }) => {
    return typeof $marginBottom === 'number' 
    ? `margin-bottom: ${$marginBottom}px;`
    : `margin-bottom: ${$marginBottom};`
  }}
  ${({ $marginLeft }) => {
    return typeof $marginLeft === 'number'
    ? `margin-left: ${$marginLeft}px;`
    : `margin-left: ${$marginLeft};`
  }}
  ${({ $marginHorizontal }) => {
    return (
      $marginHorizontal &&
      `margin-left: ${$marginHorizontal}px; 
      margin-right: ${$marginHorizontal}px;`
    )
  }}
  ${({ $marginVertical }) => {
    return (
      $marginVertical &&
      `margin-bottom: ${$marginVertical}px; margin-top: ${$marginVertical}px;`
    )
  }}
  ${({ $margin }) => {
    if (!$margin) {
      return null
    }
    return typeof $margin === 'number'
      ? `margin: ${$margin}px;`
      : `margin: ${$margin};`
  }}
`

export default marginStyle
