import styled, { css } from 'styled-components'

import { IconCheckboxChecked } from '@/common/assets/icons'
import { commonStyle, marginStyle } from '@/common/theme/styles'
import colors from '@/common/theme/variables/colors'

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${marginStyle};
  ${commonStyle};
`

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > label {
    margin-bottom: 0;
    margin-left: 8px;
  }
`

const checkbox = css`
  appearance: none;
  border: 1px solid ${colors.lightGrayM};
  min-width: 16px;

  &:checked {
    background: 1px no-repeat url(${IconCheckboxChecked});
    border-color: ${colors.primary};
  }
`

export const ReactCheckbox = styled.input`
  height: 16px;
  width: 16px;
  border-radius: 2px;
  border: 1px solid ${colors.primary};

  ${({ type }) => type === 'checkbox' && checkbox}
`
