import styled from 'styled-components'

import { colors } from '@/common/theme/variables'

export const TextContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const ItemText = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  transition: color 0.2s linear;
  color: ${colors.sidebarElem};
`

export const IconContainer = styled.div`
  min-width: 20px;
  height: 100%;
  display: flex;
  margin-right: 7px;
  
  svg {
    width: 20px;
    height: 20px;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 13px;
  transition: background-color .3s linear;
  margin: 4px 0;
  border-radius: 4px;
  background-color: ${({ active }) => {
    return active ? colors.activeSidebarString : colors.white
  }};

  ${ItemText} {
    color: ${({ active }) => 
    (active ? colors.activeSidebarElem : colors.davyGray)};
  }

  rect {
    stroke: ${({ active }) => 
    (active ? colors.activeSidebarElem : colors.davyGray)};
  }

  path {
    fill: ${({ active }) => 
    (active ? colors.activeSidebarElem : colors.davyGray)};
  }

  &:first-child {
    margin: 0 0 4px 0;
  }

  &:hover {
    background-color: ${colors.activeSidebarString};
    cursor: pointer;

    ${ItemText} {
      color: ${colors.activeSidebarElem}
    }

    rect {
      stroke: ${colors.activeSidebarElem};
    }

    path {
      fill: ${colors.activeSidebarElem}
    }
  }
`
