import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { customerPermitStoreApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { ALL_LOTS } = QUERY_KEYS

/**
 * Fetch all lots
 * @returns useQuery instance
 */
function useAllLots() {
  return useQuery([ALL_LOTS], () => customerPermitStoreApi.getAll())
}

/**
 * Add license plates for user
 * @param successCallback
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
function useAddLicensePlates(successCallback) {
  return useMutation((data) => customerPermitStoreApi.addPlates(data), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        toast.success('License Plate added!')
        runCallback(successCallback)
      }
    },
  })
}

export { useAllLots, useAddLicensePlates }
