import React from 'react'

import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { v1 } from 'uuid'

import { Error } from '@/common/baseStyledElements'
import { withDimensions, withExtraProps, withIndents } from '@/common/hocs'

import StripeInput from '../StripeInput'
import { FieldWrapper, InputContainer, Label } from './styled'

const Field = (props) => {
  const {
    label,
    customLabel,
    name,
    form,
    labelBefore,
    rootProps,
    component: Component,
    isController,
    isRequired,
    customError,
    isStripeElement,
    withAction,
    revalidateField,
    labelFontSize,
    labelLength,
    ...rest
  } = props
  const {
    register,
    formState: { errors },
    watch,
    control,
    trigger,
  } = form
  watch(name)
  const error =
    customError ||
    errors[name]?.message ||
    errors[name]?.map((error) => error?.value?.message) ||
    ''
  const id = v1()

  const formattedLabel =
    label && labelLength && label.length > labelLength
      ? label.slice(0, labelLength) + '...'
      : customLabel && labelLength && customLabel.length > labelLength
      ? customLabel.slice(0, labelLength) + '...'
      : label || customLabel

  return (
    <FieldWrapper>
      <InputContainer 
        withAction={withAction} 
        inRow={rootProps?.inRow} 
        labelBefore={labelBefore}
      >
        {(label || customLabel) && (
          <Label
            htmlFor={id}
            name={name}
            isRequired={isRequired}
            fontSize={labelFontSize}
          >
            {formattedLabel}
          </Label>
        )}
        {isController ? (
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, name, ref, value } }) => {
              const handleOnChange = (e) => {
                if (
                  Component?.defaultProps?.name === 'ReactCreatableSelect' ||
                  Component?.defaultProps?.name === 'DatePicker'
                ) {
                  if (errors[revalidateField]){
                    trigger(revalidateField)
                  }
                  return onChange(e)
                }
                return onChange(e.value)
              }
                return (
                  <Component
                    value={value}
                    ref={ref}
                    name={name}
                    onBlur={onBlur}
                    onChange={handleOnChange}
                    {...rest}
                  />
              )}}
            />
          ) : isStripeElement ?
              <StripeInput component={Component} {...rest}/>
            :  
              <Component id={id} name={name} {...register(name)}  {...rest} />
        }
      </InputContainer>
      {(error && error !== '') && <Error>{error}</Error>}
    </FieldWrapper>
  )
}

Field.propTypes = {
  customLabel: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  component: PropTypes.elementType,
  form: PropTypes.object,
  labelBefore: PropTypes.bool,
  rootProps: PropTypes.object, // received from *withExtraProps* HOC
  disabled: PropTypes.bool,
  isController: PropTypes.bool,
  isRequired: PropTypes.bool,
  customError: PropTypes.string,
  withAction: PropTypes.bool,
  revalidateField: PropTypes.string,
  labelFontSize: PropTypes.string,
  isStripeElement: PropTypes.bool,
  labelLength: PropTypes.number,
}

Field.defaultProps = {
  form: {
    register: () => {},
    formState: {
      errors: {},
    },
    watch: () => {},
  },
  disabled: false,
  isController: false,
  isRequired: false,
  customError: '',
  withAction: false
}

export default withDimensions(withIndents(withExtraProps(Field)), {
  $width: '100%',
})
