import React from 'react'

import PropTypes from 'prop-types'

import { DimensionContainer } from './styled'

function withDimensions(Component, defaultProps) {
  Wrapper.propTypes = {
    $width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    $height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    $fullSize: PropTypes.bool,
  }

  function Wrapper(props) {
    const {
      $width,
      $height,
      $fullSize,
      ...rest
    } = props

    return (
      <DimensionContainer
        $width={$width || defaultProps?.$width}
        $height={$height || defaultProps?.$height}
        $fullSize={$fullSize}
      >
        <Component {...rest} />
      </DimensionContainer>
    )
  }

  return Wrapper
}

export default withDimensions