import { css } from 'styled-components'

const commonStyle = css`
  ${({ $display }) => {
    return $display && `display: ${$display}`
  }};
  ${({ $zIndex }) => {
    return $zIndex && `z-index: ${$zIndex}`
  }};
`

export default commonStyle
