import { useQuery, useMutation, useQueryClient } from 'react-query'

import { bankAccountApi  } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { BANK_ACCOUNT, STRIPE_ACCOUNT } = QUERY_KEYS

/**
 * Fetch bank account by id
 * @param id 
 * @returns useQuery instance
 */
function useBankAccount(id) {
  return useQuery(
    [ BANK_ACCOUNT, id ],
    () => bankAccountApi.get(id),
  )
}

function useBankAccountOwner() {
  return useMutation(
    (id) => bankAccountApi.getByOwner(id),
  )
}

/**
 * Create bank account
 * @param successCallback
 * @returns useMutation instance
 */
 function useCreateBankAccount(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => bankAccountApi.create(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(BANK_ACCOUNT)
      await queryClient.cancelQueries(STRIPE_ACCOUNT)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.refetchQueries(BANK_ACCOUNT)
        await queryClient.refetchQueries(STRIPE_ACCOUNT)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Update bank account
 * @param successCallback
 * @returns useMutation instance
 */
function useUpdateBankAccount(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => bankAccountApi.update(data.id, data), {
    onMutate: async () => {
      await queryClient.cancelQueries(BANK_ACCOUNT)
      await queryClient.cancelQueries(STRIPE_ACCOUNT)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.refetchQueries(BANK_ACCOUNT)
        await queryClient.refetchQueries(STRIPE_ACCOUNT)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Delete bank account
 * @param successCallback
 * @returns useMutation instance
 */
function useDeleteBankAccount(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => bankAccountApi.delete(id), {
    onMutate: async () => {
      await queryClient.cancelQueries(BANK_ACCOUNT)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(BANK_ACCOUNT)
      runCallback(successCallback)
    },
  })
}

export {
  useBankAccount,
  useBankAccountOwner,
  useCreateBankAccount,
  useUpdateBankAccount,
  useDeleteBankAccount
}