import React, { memo, useState } from 'react'

import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import { useLocation, useHistory } from 'react-router-dom'
import { v4 } from 'uuid'

import { IconEmpty } from '@/common/assets/icons'

import { ItemContainer, IconContainer, ItemText, TextContainer } from './styled'

function Item(props) {
  const { icon, text, path, onClick, arrChildren } = props
  const [ showChildren, setShowChildren ] = useState(false)

  const history = useHistory()
  const location = useLocation()

  const isActive = (path) =>
    location.pathname.toLowerCase().includes(path.toLowerCase())

  const handleClick = (childPath) => 
    (onClick ? onClick : history.push(childPath || path))

  const hoverOnParent = (flag) => () => {
    setShowChildren(flag)
  }

  return (
    <div onMouseLeave={hoverOnParent(false)}>
      <ItemContainer 
        active={isActive(path)} 
        onClick={() => handleClick()} 
        onMouseEnter={hoverOnParent(true)}
      >
        <IconContainer active={isActive(path)}>
          <SVG src={icon || IconEmpty} />
        </IconContainer>
        <TextContainer>
          <ItemText active={isActive(path)}>{text}</ItemText>
        </TextContainer>
      </ItemContainer>
      {showChildren && arrChildren.map((child) => {
        return(
          <ItemContainer 
            key={v4()}
            active={isActive(child.path)} 
            onClick={() => handleClick(child.path)} 
          >
            <IconContainer />
            <TextContainer>
              <ItemText active={isActive(child.path)}>{child.text}</ItemText>
            </TextContainer>
          </ItemContainer>
        )
      })}
    </div>  
  )
}

Item.propTypes = {
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  active: PropTypes.bool,
  icon: PropTypes.any,
  onClick: PropTypes.func,
  arrChildren: PropTypes.array
}

Item.defaultProps = {
  active: false,
  text: 'Default',
  path: '/',
  icon: IconEmpty,
  onClick: null,
  arrChildren: []
}

export default memo(Item)
