/* eslint-disable react/prop-types */
import React, {useRef, useImperativeHandle} from 'react';

import { colors } from '@/common/theme/variables'

import { CardInputWrapper } from './styled'

const inputStyle = {
	color: colors.darkGray,
	fontSize: '16px',
	'::placeholder': {
		color: colors.darkGray,
	},
}

const StripeInput = ({
  component: Component,
  inputRef,
  wrapper: Wrapper,
  handleReady,
  ...props
}) => {
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus
  }));

  return (
		<Wrapper>
      <Component
        {...props}
        onReady={(element) => {
          handleReady()
          return (elementRef.current = element)
        }}
        options={{
          style: {
            base: inputStyle,
          },
        }}
      />
		</Wrapper>
  );
};

StripeInput.defaultProps = {
  wrapper: CardInputWrapper,
  handleReady: () => {}
}

export default StripeInput;
