import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import Input from '@/components/Input'

const PhoneNumberInput = forwardRef((props, ref) => {
  const { phoneNumberValue, ...rest } = props

  const isTimeStartsFromPlus = String(phoneNumberValue?.[1]) === '+'

  const phoneMask = [
    '(',
    /[0-9]|\+/,
    ...(isTimeStartsFromPlus ? 
    [ /[0-9]/,
    /[0-9]/,
    ' ',
    '(',
    /[0-9]/,
    /[0-9]/,
    ]
    : [ /[0-9]/ ]),
    /[0-9]/,
    ')',
    ' ',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ]

  return (
    <Input
      ref={ref}
      {...rest}
      placeholder={'(###) ###-####'}
      mask={phoneMask}
    />
  )
})

PhoneNumberInput.propTypes = {
  phoneNumberValue: PropTypes.string,
}

export default PhoneNumberInput
