import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'
import Select from 'react-select'

import DefaultOption from './components/DefaultOption'
import { Custom, CustomMini } from './styles'

const ReactSelect = forwardRef((props, ref) => {
  const {
    options,
    placeholder,
    value,
    fees,
    currentValue,
    currencyValue,
    selectComponents,
    customStyles,
    ...rest
  } = props
  const [defaultValue] = options?.filter(
    (option) => option.value === value || option.value === currentValue,
  )

  const prepareComponents = (mainProps) => {
    const result = {}
    Object.values(selectComponents).forEach(
      (item) => (result[item.name] = item(mainProps)),
    )
    return result
  }

  const styles = customStyles ? customStyles : fees ? CustomMini : Custom

  //Uses for fees form currency fields
  if (currencyValue)
    return (
      <Select
        value={currencyValue}
        defaultValue={defaultValue}
        ref={ref}
        getOptionLabel={({ text }) => text}
        options={options}
        placeholder={placeholder}
        styles={fees ? CustomMini : Custom}
        {...rest}
      />
    )

  return (
    <Select
      defaultValue={defaultValue}
      ref={ref}
      getOptionLabel={({ text }) => text}
      options={options}
      components={prepareComponents(props)}
      placeholder={placeholder}
      styles={styles}
      {...rest}
    />
  )
})

ReactSelect.propTypes = {
  value: PropTypes.any,
  currentValue: PropTypes.any,
  placeholder: PropTypes.string,
  fees: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any,
      value: PropTypes.any,
    }),
  ),
  currencyValue: PropTypes.any,
  selectComponents: PropTypes.any,
  customStyles: PropTypes.any,
}

ReactSelect.defaultProps = {
  name: 'ReactSelect',
  placeholder: 'Select an option...',
  fees: false,
  options: [],
  currencyValue: false,
  selectComponents: { DefaultOption },
  customStyles: false,
}

export default ReactSelect
