import React from 'react'

import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import { IconCross } from '@/common/assets/icons'
import { Col } from '@/common/baseStyledElements'

import ButtonGoBack from './components/ButtonGoBack'
import { ModalContainer, ModalWrapper, CloseIconContainer } from './styled'


function Modal(props) {
  const {
    width,
    visible,
    children,
    onClose,
    showGoBack,
    showClose,
    $color,
    $paddingTop,
  } = props

  return (
    <ModalContainer $display={visible ? 'flex' : 'none'}>
      <ModalWrapper $width={width} $color={$color} $paddingTop={$paddingTop}>
        <Col>
          {showGoBack && (
            <Col>
              <ButtonGoBack onClick={onClose} />
            </Col>
          )}
          {showClose && (
            <Col $alignItems={'flex-end'}>
              <CloseIconContainer onClick={onClose}>
                <SVG src={IconCross} />
              </CloseIconContainer>
            </Col>
          )}
          <Col $marginTop={showGoBack ? 40 : 0}>{children}</Col>
        </Col>
      </ModalWrapper>
    </ModalContainer>
  )
}

Modal.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  visible: PropTypes.bool,
  children: PropTypes.any,
  onClose: PropTypes.func,
  showGoBack: PropTypes.bool,
  showClose: PropTypes.bool,
  $paddingTop: PropTypes.number,
  $color: PropTypes.string,
}

Modal.defaultProps = {
  width: 550,
  visible: false,
  children: <></>,
  onClose: null,
  showGoBack: true,
  showClose: false,
  $paddingTop: null,
}

export default Modal
