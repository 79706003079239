import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { IconClock } from '@/common/assets/icons'
import Input from '@/components/Input'

const TimeInput = forwardRef((props, ref) => {
  const { timeValue, ...rest } = props

  const isTimeStartsFromZero = String(timeValue?.[0]) === '0'
  const isTimeStartsFromOne = String(timeValue?.[0]) === '1'

  const timeMask = [
    /[0-2]/,
    ...(isTimeStartsFromZero ? [ /[0-9]/ ]
      : isTimeStartsFromOne
        ? [ /[0-9]/ ]
        : [ /[0-3]/ ]),
    ':',
    /[0-5]/,
    /[0-9]/,
  ]

  return (
    <Input
      ref={ref}
      {...rest}
      icon={IconClock}
      placeholder={'00:00'}
      mask={timeMask}
    />
  )
})

TimeInput.propTypes = {
  timeValue: PropTypes.string,
}

export default TimeInput
