import styled from 'styled-components'

import { marginStyle } from '@/common/theme/styles'
import { colors } from '@/common/theme/variables'

const Card = styled.div`
  width: 100%;
  border-radius: 12px;
  background-color: ${colors.background};
  border: 1px solid ${colors.zumthor};
  box-shadow: 0px -1px 10px rgba(5, 133, 252, 0.01),
    0px 1px 10px rgba(5, 133, 252, 0.01), 0px 1px 20px rgba(16, 129, 175, 0.05),
    0px -1px 20px rgba(16, 129, 175, 0.05);
  padding: 28px 24px;
  ${marginStyle};
`
export default Card
