import { useQuery } from 'react-query'

import { billingApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'

const { BILLING_DICTIONARIES, USER_BILLING_HISTORY } = QUERY_KEYS

/**
 * Fetch biling history dictionaries
 * @param query
 * @returns useQuery instance
 */
function useBillingDictionaries() {
  return useQuery([BILLING_DICTIONARIES], () => billingApi.getDictionaries())
}

/**
 * Fetch biling history currentUser
 * @param query for other types
 * @returns useQuery instance
 */
function useBillingHistory({ type }) {
  return useQuery([USER_BILLING_HISTORY, type], () =>
    billingApi.getBillingHistory(type),
  )
}

/**
 * Fetch biling history by user Id
 * @param query for other types
 * @returns useQuery instance
 */
function useBillingHistoryByUser(query) {
  return useQuery([USER_BILLING_HISTORY, query], () =>
    billingApi.getBillingHistoryByUser(query),
  )
}

export { useBillingDictionaries, useBillingHistory, useBillingHistoryByUser }
