import * as yup from 'yup'

const confirmWithNoteModalSchema = yup.object().shape({
  reason: yup
    .string()
    .required('Reason required')
    .max(254, 'The maximum number of symbols should be 254'),
})

export default confirmWithNoteModalSchema
