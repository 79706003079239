import * as yup from 'yup'

import { registerTimeValidation } from '@/common/schemas/customValidator'
import { AVAILABILITY_TYPES } from '@/common/utils/const'

registerTimeValidation()

const LotAvailabilitySchema = yup.object().shape({
  type: yup.mixed()
    .oneOf([ AVAILABILITY_TYPES.BY_WEEK, AVAILABILITY_TYPES.BY_DATE ]),
  days: yup.array().when('type', {
    is: AVAILABILITY_TYPES.BY_WEEK,
    then: yup.array()
      .of(yup.number())
      .min(1, 'Select at least one day of the week'),
  }),
  date: yup.string().when('type', {
    is: AVAILABILITY_TYPES.BY_DATE,
    then: yup.string('Date is required').required('Date is required'),
  }),
  workFrom: yup.string()
    .timeValidation('Invalid time')
    .required('Field is required'),
  workTo: yup.string()
    .timeValidation('Invalid time')
    .required('Field is required'),
})

export default LotAvailabilitySchema