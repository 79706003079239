import styled from 'styled-components'

import {
  commonStyle,
  paddingStyle,
  dimensionStyle,
} from '@/common/theme/styles'
import { device, colors } from '@/common/theme/variables'

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.dimBlack};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  ${commonStyle};
`

export const ModalWrapper = styled.div`
  background-color: ${({$color}) => $color || colors.white};
  border-radius: 16px;
  padding: 40px;
  overflow: scroll;
  min-height: 20%;
  height: auto;
  max-height: 70%;
  ${dimensionStyle};
  ${paddingStyle};

  @media ${device.tablet} {
    padding: 20px;
    width: calc(100% - 52px);
  }
`

export const CloseIconContainer = styled.div`
  cursor: pointer;
  position: relative;
  right: -15px;
  top: -20px;

  @media ${device.tablet} {
    right: 0;
    top: 0;
  }
`
