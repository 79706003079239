import * as yup from 'yup'

const dashboardLotsFIlterSchema = yup.object().shape({
  dateFrom: yup.string().dateValidation(yup.ref('dateTo')),
  dateTo: yup.string().dateValidation(yup.ref('dateFrom'), true),
  lots: yup
    .mixed()
    .test('lots', `Choose at least one lot`, async (value, ctx) => {
      return await ctx.parent.LotIds.some((id) => !!ctx.parent[`lot_${id}`])
    }),
})

export default dashboardLotsFIlterSchema
