import { withQuery } from '@/common/utils/helpers'

function registerDashboardApi(api) {
  return {
    revenue: (query) => api.get(withQuery(`/dashboard/revenue`, query)),
    revenueExport: (query) =>
      api.get(withQuery(`/dashboard/revenue/export`, query), {
        responseType: 'blob',
      }),
    realTime: (query) =>
      api.get(withQuery(`/dashboard/real-time-tracking`, query)),
    revenueStatistic: (query) =>
      api.get(withQuery(`/dashboard/revenue-statistic`, query)),
    lotsStatistic: (query) =>
      api.get(withQuery(`/dashboard/lots-statistic`, query)),
    lotsPaymentTypes: (query) =>
      api.get(withQuery(`/dashboard/lots-payment-types`, query)),
    revenueStatisticCompare: (query) =>
      api.get(withQuery(`/dashboard/revenue-statistic/compare`, query)),
    lotsStatisticCompare: (query) =>
      api.get(withQuery(`/dashboard/lots-statistic/compare`, query)),
    lotsPaymentTypesCompare: (query) =>
      api.get(withQuery(`/dashboard/lots-payment-types/compare`, query)),
    statisticExport: (query) =>
      api.get(withQuery(`/dashboard/statistic/export`, query), {
        responseType: 'blob',
      }),
  }
}

export default registerDashboardApi
