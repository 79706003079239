import { useMutation, useQuery, useQueryClient } from 'react-query'

import { lotFeeApi, vendorContractsApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const {
  LOT_FEE,
  FEE_CHANGE_ACTIVITY,
  FEES_AMOUNT,
  REPORT_TYPES,
  LOT_REFERRALS,
} = QUERY_KEYS

/**
 * Fetch all Fees for lot
 * @param lotId
 * @returns useQuery instance
 */
function useFees(lotId) {
  return useQuery([LOT_FEE, lotId], () => lotFeeApi.get(lotId), {
    cacheTime: 1,
  })
}

/**
 * Update fees
 * @param successCallback
 * @returns useMutation instance
 */
function useUpdateFees(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => lotFeeApi.update(data.id, data), {
    onMutate: async () => {
      await queryClient.cancelQueries(LOT_FEE)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.refetchQueries(LOT_FEE)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Change activity for fees
 * @param status
 * @returns useQuery instance
 */
function useChangeActivityFees(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((status) => lotFeeApi.changeActivity(status), {
    onMutate: async ({ onMutate }) => {
      onMutate && onMutate()
      await queryClient.cancelQueries(FEE_CHANGE_ACTIVITY)
    },
    onSettled: async () => {
      await queryClient.refetchQueries(LOT_FEE)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Get fees amount data
 * @param status
 * @returns useQuery instance
 */
function useFeesAmount(lotId, query, enabled = false) {
  return useQuery(
    [FEES_AMOUNT, query],
    () => lotFeeApi.getFeesAmount(lotId, { ...query }),
    { enabled },
  )
}

/**
 * Get export file fees amount report by query
 * @param query
 * @returns useQuery instance
 */
function useFeesAmountExport(successCallback) {
  return useMutation((query) => lotFeeApi.feesAmountExport(query), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        successCallback(data)
      }
    },
  })
}

/**
 * Fetch all report types
 * @param lotId
 * @returns useQuery instance
 */
function useReportTypes() {
  return useQuery([REPORT_TYPES], () => lotFeeApi.getReportTypes())
}

/**
 * Get export file payment report by query
 * @param query
 * @returns useQuery instance
 */
function useReportExport(successCallback) {
  return useMutation((query) => lotFeeApi.exportRepot(query), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        successCallback(data)
      }
    },
  })
}

/**
 * Get referrals by lotId
 * @param lotId
 * @returns useQuery instance
 */
function useFeeReferrals(lotId) {
  return useQuery([LOT_REFERRALS, lotId], () =>
    vendorContractsApi.getReferrals(lotId),
  )
}

export {
  useFees,
  useUpdateFees,
  useChangeActivityFees,
  useFeesAmount,
  useFeesAmountExport,
  useReportTypes,
  useReportExport,
  useFeeReferrals,
}
