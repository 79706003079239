import {VIOLATION_STATUSES} from '@/common/utils/const'

/**
 * Return current user name or email
 * @param user
 * @returns {string}
 */
 export function getCurrentUserName(user) {
  return user?.firstName || user?.lastName ? 
  `${user?.firstName} ${user?.lastName}` : user?.email || 'User'
}

/**
 * Converts string of time in the format "HH:MM:SS" into "HH:MM" format
 * @param timeStr string of time in the format HH:MM:SS
 * @returns {string} string of time in the format HH:MM
 */
 export function omitSeconds(timeStr) {
  return  timeStr?.slice(0, -3)
}

/**
 * Return violation status
 * @param violation
 * @returns {string}
 */
 export function getViolationStatus(violation) {
  return violation?.isPaid
    ? VIOLATION_STATUSES.COMPLETE
    : violation?.statusId === 4
    ? VIOLATION_STATUSES.VOIDED
    : violation?.isPaidInCash
    ? VIOLATION_STATUSES.PAID_IN_CASH
    : VIOLATION_STATUSES.NEW
}

/**
 * Return violation status
 * @param violation
 * @returns {boolean}
 */
export function isBoot(violation) {
  return violation?.action === 'Boot'
}
