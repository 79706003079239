import * as yup from 'yup'

import regExpValidations from '@/common/utils/const/regExp'

const customerFormSchema = yup.object().shape({
  licensePlate: yup.string().required('License Plate required'),
  phoneNumber: yup
    .string()
    .required('Phone number required')
    .matches(regExpValidations.phoneRegExp, 'Phone number is not valid'),
  agree: yup
    .bool()
    .oneOf([ true ], 'You need agree with this message')
    .required(),  
  permitId: yup.number().required('Permit required'),
  
})

export default customerFormSchema
