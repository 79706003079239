import { withQuery } from '@/common/utils/helpers'

function registerBillingApi(api) {
  return {
    getBillingHistory: (type) => api.get(`/billing-history/${type}`),
    getBillingHistoryByUser: ({ userId, ...query }) =>
      api.get(withQuery(`/billing-history/user/${userId}`, query)),
    getDictionaries: () => api.get(`/billing-history/dictionaries`),
  }
}

export default registerBillingApi
