import { useQuery, useMutation, useQueryClient } from 'react-query'

import { promoCodesApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { PROMO_CODE } = QUERY_KEYS

/**
 * Fetch promoCodes by lot id
 * @param lotId
 * @returns useQuery instance
 */
function usePromoCodes(lotId) {
  return useQuery([PROMO_CODE, lotId], () => promoCodesApi.get(lotId))
}

/**
 * Get export file promo codes by type
 * @param query
 * @returns useMutation instance
 */
function useExportPromoCodes(lotId, successCallback) {
  return useMutation((query) => promoCodesApi.export(lotId, query), {
    onSuccess: (data) => {
      if (data.responseStatus === 200) successCallback(data)
    },
  })
}

/**
 * Get export file promo codes by type in pdf file
 * @param query
 * @returns useMutation instance
 */
function useExportPdfPromoCodes(lotId, successCallback) {
  return useMutation((query) => promoCodesApi.exportPdf(lotId, query), {
    onSuccess: (data) => {
      if (data.responseStatus === 200) successCallback(data)
    },
  })
}

/**
 * Create promo code
 * @param successCallback
 * @returns useMutation instance
 */
function useCreatePromoCode(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => promoCodesApi.create(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(PROMO_CODE)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
        await queryClient.refetchQueries(PROMO_CODE)
      }
    },
  })
}

export {
  usePromoCodes,
  useExportPromoCodes,
  useExportPdfPromoCodes,
  useCreatePromoCode,
}
