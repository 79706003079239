import { withQuery } from '@/common/utils/helpers'

function registerPaymentApi(api) {
  return {
    holdCreate: (data) => api.post(`/payment/permit`, data),
    paymentViolation: (data) => api.post(`/payment/violation`, data),
    getFeesReport: (query) =>
      api.get(withQuery(`/payment/report/referral`, query), {
        responseType: 'blob',
      }),
    deletePayment: (id) => api.delete(`/payment/${id}`),
  }
}

export default registerPaymentApi
