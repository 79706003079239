import React from 'react'

import { Row } from '@/common/baseStyledElements'

import Loader from '../Loader'

function Loading(props) {
  const { ...rest } = props
  return (
    <Row $alignItems={'center'} $justifyContent={'center'} {...rest}>
      <Loader style={{ lineHeight: '0px' }} {...rest} />
    </Row>
  )
}

export default Loading