import React, { forwardRef } from 'react'

import { ReactCheckbox } from './styled'

const Checkbox = forwardRef((props, ref) => {
  return (
    <ReactCheckbox
      type={'checkbox'}
      ref={ref}
      {...props}
    />
  )
})

export default Checkbox
