import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { whiteListApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { WHITE_LIST } = QUERY_KEYS

/**
 * Fetch all white list
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useWhiteList(query) {
  return useQuery(
    [ WHITE_LIST, query ],
    () => whiteListApi.getAll(query),
  )
}

/**
 * Get export file white list by type
 * @param type
 * @returns useMutation instance
 */
function useWhiteListExport(successCallback) {
  return useMutation(
    (query) => whiteListApi.export(query),
    {
      onSuccess: async (data) => {
        if(data.responseStatus === 200){
          successCallback(data)
        }
      },
    }
  )
}

/**
 * Create user in white list
 * @param successCallback
 * @returns useMutation instance
 */
function useAddWhiteList(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => whiteListApi.create(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(WHITE_LIST)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        toast.success('User created! Now You can add user schedule')
        runCallback(successCallback)
        await queryClient.refetchQueries(WHITE_LIST)
      }
    },
  })
}

/**
 * Update user in white list
 * @param successCallback
 * @returns useMutation instance
 */
function useUpdateWhiteList(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => whiteListApi.update(data.id, data), {
    onMutate: async () => {
      await queryClient.cancelQueries(WHITE_LIST)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        toast.success('User information updated!')
        await queryClient.refetchQueries(WHITE_LIST)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Delete user in white list
 * @param successCallback
 * @returns useMutation instance
 */
function useDeleteWhiteList(lotId, successCallback) {
  const queryClient = useQueryClient()
  return useMutation((id) => whiteListApi.delete({lotId}, id), {
    onMutate: async () => {
      await queryClient.cancelQueries(WHITE_LIST)
    },
    onSuccess: async () => {
      toast.success('User deleted!')
      await queryClient.invalidateQueries(WHITE_LIST)
      runCallback(successCallback)
    },
  })
}

export {
  useWhiteList,
  useWhiteListExport,
  useAddWhiteList,
  useUpdateWhiteList,
  useDeleteWhiteList,
} 