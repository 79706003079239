import React from 'react'

import { useHistory } from 'react-router-dom'

import NoUserPicture from '@/common/assets/images/no-user-image.png'
import routes from '@/common/router/routes'
import { useUserCurrent } from '@/common/services/userService'
import { getCurrentUserName } from '@/common/utils/helpers'

import { Container, UserInfo, UserImage } from './styled'

function UserProfile() {
  const { data } = useUserCurrent()
  const history = useHistory()

  return (
    <Container onClick={() => history.push(routes.userProfile)}>
      <div>
        <UserInfo>{data && getCurrentUserName(data)}</UserInfo>
        <UserInfo>{data && data?.role?.name}</UserInfo>
      </div>
      <UserImage src={data?.picture || NoUserPicture} alt={'User Image'} />
    </Container>
  )
}

export default UserProfile
