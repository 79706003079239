import { css } from 'styled-components'

const positionStyle = css`
  ${({ $position }) => {
    return $position && `position: ${$position};`
  }}
  ${({ $top }) => {
    return $top && `top: ${$top}px;`
  }}
  ${({ $right }) => {
    return $right && `right: ${$right}px;`
  }}
  ${({ $bottom }) => {
    return $bottom && `bottom: ${$bottom}px;`
  }}
  ${({ $left }) => {
    return $left && `left: ${$left}px;`
  }}
`

export default positionStyle
