import { css } from 'styled-components'

import colors from '../variables/colors'
import device from '../variables/mediaQuery'

export const iconStyle = css`
  background-image: url(${(props) => props?.icon});
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 10px;
  background-size: 16px;
  padding: 16px 0px 16px 35px;
`

const inputStyle = css`
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: ${colors.white};
  border: 1px solid ${colors.backgroundTabs};
  outline: none;
  color: ${colors.bgContent};
  ${({ icon }) => icon && iconStyle};
  ${({ $sizeType }) => {
    if ($sizeType === 'big') return 'height: 48px'
    if ($sizeType === 'normal') return 'height: 36px'
    return 'height: 34px'
  }};
  background-color: ${({ disabled }) =>
    disabled ? colors.ghostWhite : colors.white};

  @media ${device.tablet} {
    height: 40px;
    font-size: 16px;
  }

  &:disabled {
    color: ${colors.darkGray};
    font-weight: 500;
  }
`

export default inputStyle
