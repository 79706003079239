import * as yup from 'yup'

import regExpValidations from '@/common/utils/const/regExp'

const stripeAccountDetailsSchema = yup.object().shape({
  ssnLast4: yup
    .string()
    .required('Last 4 SSN numbers are required')
    .matches(regExpValidations?.digitRegExp, 'Must be only digits')
    .min(4, 'Must be exactly 4 digits')
    .max(4, 'Must be exactly 4 digits'),
  jobTitle: yup.string().required('Job Title is required'),
  ein: yup.string().required('EIN is required'),
  businessWebsite: yup
    .string()
    .required('Website is required')
    .matches(regExpValidations?.websiteRegExp, 'Website is not valid'),
})

export default stripeAccountDetailsSchema
