import styled, { css } from 'styled-components'

import {
  marginStyle,
  paddingStyle,
  dimensionStyle,
  baseStyles
} from '@/common/theme/styles'
import { colors } from '@/common/theme/variables'

export const FormContainer = styled.div`
  ${marginStyle};
  ${paddingStyle};
  ${dimensionStyle};
  display: flex;
  flex-direction: column;

  .form-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }}
`

const smallTitle = css`
  color: ${colors.lgray};
  font-size: 14px;
  margin-bottom: 8px;
`

const normalTitle = css`
  color: ${colors.stone};
  text-transform: uppercase;
  font-size: 18px;
`

export const FormGroupTitle = styled.p`
  font-weight: 500;
  ${({ titleType }) => titleType === 'small' && smallTitle};
  ${({ titleType }) => titleType === 'normal' && normalTitle};
  ${({ titleWidth }) => {
    return titleWidth && `width: ${titleWidth}px;`
  }};
`

export const Content = styled.div`
  border-radius: 8px;
  display: flex;
  ${baseStyles};
`
