import React from 'react'

import PropTypes from 'prop-types'
import { Route, Redirect, Switch } from 'react-router-dom'

import AuthLayout from '../layout/AuthLayout'
import routes from './routes'

const SignIn = React.lazy(() => import('@/pages/Auth/SignIn'))
const Terms = React.lazy(() => import('@/pages/Auth/TermsAndConditions'))
const Policy = React.lazy(() => import('@/pages/Auth/PrivacyPolicy'))
const SignOut = React.lazy(() => import('@/pages/Auth/SignOut'))
const PermitStoreAnonym = React.lazy(() => import('@/pages/PermitStoreAnonym'))
const Violations = React.lazy(() =>
  import('@/pages/ViolationsCustomer/anonymous'),
)

PublickRoutes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
}

function PublickRoutes(props) {
  const { isAuthenticated } = props

  return (
    <Switch>
      {!isAuthenticated && (
        <Route path={routes.signIn} exact>
          <AuthLayout>
            <SignIn />
          </AuthLayout>
        </Route>
      )}
      {!isAuthenticated && (
        <Route path={routes.termsAndConditions} exact>
          <AuthLayout>
            <Terms />
          </AuthLayout>
        </Route>
      )}
      {!isAuthenticated && (
        <Route path={routes.privacyPolicy} exact>
          <AuthLayout>
            <Policy />
          </AuthLayout>
        </Route>
      )}
      <Route path={routes.signOut} exact>
        <SignOut />
      </Route>
      <Route path={routes.permitStoreAnonym}>
        <PermitStoreAnonym />
      </Route>
      <Route path={routes.violationsAnonymSearch}>
        <Violations />
      </Route>
      <Route path={routes.violationsAnonymDetails}>
        <Violations />
      </Route>
      <Route path={'/'} exact>
        {isAuthenticated ? (
          <Redirect to={routes.main} />
        ) : (
          <Redirect to={routes.signIn} />
        )}
      </Route>
      {!isAuthenticated && (
        <Route path={'*'}>
          <Redirect to={routes.signIn} />
        </Route>
      )}
    </Switch>
  )
}

export default PublickRoutes
