import React from 'react'

import PropTypes from 'prop-types'

import Checkbox from '@/components/Checkbox'
import CurrencyInput from '@/components/CurrencyInput'
import DatePicker from '@/components/DatePicker'
import NumberInput from '@/components/NumberInput'
import PhoneNumberInput from '@/components/PhoneNumberInput'
import Radio from '@/components/Radio'
import Select from '@/components/Select'
import TimeInput from '@/components/TimeInput'

function withExtraProps(Component) {
  Wrapper.propTypes = {
    name: PropTypes.string,
    form: PropTypes.object,
    component: PropTypes.elementType,
  }

  function Wrapper(props) {
    const { name, form, component: Field } = props

    /**
     * Props for component
     * @returns {{}}
     */
    const getExtraProps = () => {
      const extra = {}

      /* Props for Select Component */
      if (Object.is(Field, Select)) {
        extra.value = form?.getValues(name) || undefined
      }

      /* Props for NumberInput and CurrencyInput */
      if (Object.is(Field, NumberInput) || Object.is(Field, CurrencyInput)) {
        extra.setValue = (value, options) => {
          form?.setValue(name, value, options)
        }
      }

      /* Props for TimeInput */
      if (Object.is(Field, TimeInput)) {
        extra.timeValue = form?.watch(name) || undefined
      }

      /* Props for TimeInput */
      if (Object.is(Field, PhoneNumberInput)) {
        extra.phoneNumberValue = form?.watch(name) || undefined
      }

      /* Props for DatePicker */
      if (Object.is(Field, DatePicker)) {
        extra.setValue = (value, options) => {
          form?.setValue(name, value, options)
        }
        extra.initialValue = form?.watch(name)
      }

      /* Props for Checkbox */
      if (Object.is(Field, Checkbox) && name === 'termsAndConditions') {
        extra.value = form?.watch(name) || undefined
      }

      return extra
    }

    /**
     * Props for parent wrapper (Field)
     * @returns {{}}
     */
    const getRootProps = () => {
      const root = {}

      if (Object.is(Field, Checkbox) || Object.is(Field, Radio)) {
        root.inRow = true
      }

      return root
    }

    return (
      <Component {...props} {...getExtraProps()} rootProps={getRootProps()} />
    )
  }

  return Wrapper
}

export default withExtraProps