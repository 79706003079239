import {
  IconAnalytics,
  IconSignOut,
  IconTask,
  IconViolations,
  IconLongtermContracts,
  IconMessages,
  IconParking,
  IconSearch,
  IconCalendar,
  IconBoxDollar,
  IconUserCog,
} from '@/common/assets/icons'
import routes from '@/common/router/routes'

export const menu = [
  {
    text: 'Dashboard',
    path: routes.dashboard,
    icon: IconAnalytics,
  },
  {
    id: 'lotManagement',
    text: 'Lot Management',
    path: routes.lotManagement,
    icon: IconTask,
  },
  {
    text: 'Violations',
    path: routes.violations,
    icon: IconViolations,
    children: [
      {
        text: 'Violation List',
        path: routes.violationsList,
        icon: false,
      },
      {
        text: 'Violation Companies',
        path: routes.violationsCompanies,
        icon: false,
      },
    ],
  },
  {
    text: 'Monthly Agreements',
    path: routes.ltpContracts,
    icon: IconLongtermContracts,
    children: [
      {
        text: 'Customers',
        path: routes.ltpCustomers,
        icon: false,
      },
      {
        text: 'Contracts',
        path: routes.ltpContracts,
        icon: false,
      },
      {
        text: 'WaitList',
        path: routes.waitList,
        icon: false,
      },
    ],
  },
  {
    text: 'Messages',
    path: routes.messages,
    icon: IconMessages,
  },
  {
    text: 'User List',
    path: routes.userList,
    icon: IconUserCog,
  },
  {
    text: 'Admin Panel',
    path: routes.adminPanel,
    icon: IconUserCog,
    children: [
      {
        text: 'User Management',
        path: routes.userManagement,
        icon: false,
      },
      {
        text: 'Company Management',
        path: routes.companyManagement,
        icon: false,
      },
    ],
  },
  { divider: true },
  {
    text: 'Log Out',
    path: routes.signOut,
    icon: IconSignOut,
  },
]

export const violationOfficerMenu = [
  {
    text: 'Parking lots list',
    path: routes.parkingLots,
    icon: IconParking,
  },
  {
    text: 'Plate Search',
    path: routes.plateSearch,
    icon: IconSearch,
  },
  {
    text: 'Violation List',
    path: routes.officerViolationsList,
    icon: IconViolations,
  },
  { divider: true },
  {
    text: 'Log Out',
    path: routes.signOut,
    icon: IconSignOut,
  },
]

export const CustomerMenu = [
  /*{
    text: 'Permit Store',
    path: routes.permitStoreCustomer,
    icon: IconParking,
  },*/
  {
    text: 'Violations',
    path: routes.violationsSearch,
    icon: IconViolations,
  },
  {
    text: 'My Permits',
    path: routes.myPermitsCustomer,
    icon: IconCalendar,
  },
  {
    text: 'Monthly Agreements',
    path: routes.customerLtp,
    icon: IconLongtermContracts,
  },
  {
    text: 'Biling History',
    path: routes.billingHistory,
    icon: IconBoxDollar,
  },
  { divider: true },
  {
    text: 'Log Out',
    path: routes.signOut,
    icon: IconSignOut,
  },
]
