import React, { useCallback } from 'react'

import PropTypes from 'prop-types'

import { useUserCurrent } from '@/common/services/userService';
import { isViolationOfficer, isCustomer } from '@/common/utils/roleAccesses';

import Item from './components/Item'
import { menu, violationOfficerMenu, CustomerMenu } from './menu'
import { Modal, Container, ItemsContainer, Divider } from './styled'

function Sidebar({ open, close}) {
  const { data: user } = useUserCurrent()
  const isOfficer = isViolationOfficer(user?.role)
  const isConsumer = isCustomer(user?.role)
  let sidebarMenu = []
  if(isOfficer) sidebarMenu = violationOfficerMenu 
  else if(isConsumer) sidebarMenu = CustomerMenu
  else sidebarMenu = menu

  const renderItems = useCallback(
    () =>
      sidebarMenu.map((item, index) => {
        if (item.divider) {
          return <Divider key={index} />
        }
        return (
          <Item
            key={index}
            icon={item.icon}
            text={item.text}
            path={item.path}
            arrChildren={item.children}
          /> 
        )
      }),
    [],
  )

  return (
    <Modal open={open} onClick={close}>
      <Container>
        <ItemsContainer>{renderItems()}</ItemsContainer>
      </Container>
    </Modal>  
  )
}

Sidebar.propTypes = {
  items: PropTypes.any,
  open: PropTypes.bool,
  close: PropTypes.func,
}

export default Sidebar
