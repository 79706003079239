import React from 'react'

import { useLocation } from 'react-router-dom'

import { Col, ModalTitle } from '@/common/baseStyledElements'
import { openAppKey } from '@/common/config'
import useModal from '@/common/hooks/useModal'
import { modalProps } from '@/common/utils/props'
import Button from '@/components/Button'
import Modal from '@/components/Modal'

OpenAppModal.propTypes = modalProps

function OpenAppModal(props) {
  const { id } = props

  const location = useLocation()
  const modal = useModal(id)

  const openOfficerApp = () => {
    const {pathname, search } = location
    window.open(`${openAppKey}:/${pathname}${search}`)
  }

  return (
    <Modal
      width={'40%'}
      onClose={modal.hide}
      visible={modal.visible}
      showGoBack={false}
      showClose={true}
    >
      <Col $width={'100%'} $alignItems={'center'}>
        <ModalTitle $marginBottom={15}>Open In</ModalTitle>
        <Button
          text={'Violation Officer'}
          onClick={openOfficerApp}
          $width={'260px'}
          $marginBottom={16}
        />
        {/*<Button
          text={'User Application'}
          onClick={}
          $width={'260px'}
          $marginBottom={16}
        /> */}
        <Button 
          appearance={'secondary'} 
          text={'Close'} 
          $width={'260px'} 
          onClick={modal.hide}
        />
      </Col>
    </Modal>
  )
}

export default OpenAppModal
