import * as yup from 'yup'

import regExpValidations from '@/common/utils/const/regExp'

const VendorContractSchema = yup.object().shape({
  vendorTypeId: yup.number().required('Vendor Type is required'),
  companyName: yup
    .string()
    .nullable()
    .when('vendorTypeId', {
      is: (val) => val === 1 || val === 2 || val === 3 || val === 5,
      then: yup.string().required('Company Name is required'),
    }),
  companyPhone: yup
    .string()
    .nullable()
    .when('vendorTypeId', {
      is: (val) => val === 1 || val === 2 || val === 3 || val === 5,
      then: yup
        .string()
        .required('Company Phone is required')
        .matches(regExpValidations?.phoneRegExp, 'Phone number is not valid'),
    }),
  companyEmail: yup
    .string()
    .nullable()
    .when('vendorTypeId', {
      is: (val) => val === 1 || val === 2 || val === 3 || val === 5,
      then: yup
        .string()
        .required('Company Email is required')
        .matches(regExpValidations?.emailRegExp, 'Email is not valid'),
    }),
  countryId: yup
    .string()
    .nullable()
    .when('vendorTypeId', {
      is: (val) => val === 1 || val === 2 || val === 3 || val === 5,
      then: yup.string().required('Country is required'),
    }),
  stateId: yup
    .string()
    .nullable()
    .when('vendorTypeId', {
      is: (val) => val === 1 || val === 2 || val === 3 || val === 5,
      then: yup.string().required('State is required'),
    }),
  city: yup
    .string()
    .nullable()
    .when('vendorTypeId', {
      is: (val) => val === 1 || val === 2 || val === 3 || val === 5,
      then: yup.string().required('City is required'),
    }),
  postalCode: yup
    .string()
    .nullable()
    .when('vendorTypeId', {
      is: (val) => val === 1 || val === 2 || val === 3 || val === 5,
      then: yup
        .string()
        .required('ZIP is required')
        .matches(regExpValidations?.postalRegExp, 'ZIP is not valid'),
    }),
  addressLine: yup
    .string()
    .nullable()
    .when('vendorTypeId', {
      is: (val) => val === 1 || val === 2 || val === 3 || val === 5,
      then: yup.string().required('Address is required'),
    }),
  contactName: yup.string().required('Contact Name is required'),
  contactPhone: yup
    .string()
    .required('Contact Phone is required')
    .matches(regExpValidations.phoneRegExp, 'Phone number is not valid'),
  contactEmail: yup
    .string()
    .required('Contact Email is required')
    .matches(regExpValidations.emailRegExp, 'Email is not valid'),
  startDate: yup
    .string()
    .required('Field is required')
    .when('vendorTypeId', {
      is: (val) => val === 1 || val === 2 || val === 3,
      then: yup
        .string()
        .required('Field is required')
        .dateValidation(yup.ref('endDate')),
    }),
  endDate: yup
    .string()
    .nullable()
    .when('vendorTypeId', {
      is: (val) => val === 1 || val === 2 || val === 3,
      then: yup
        .string()
        .required('Field is required')
        .dateValidation(yup.ref('startDate'), true),
    }),
})

export default VendorContractSchema
