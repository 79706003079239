const routes = {
  main: '/dashboard',

  signIn: '/signIn',
  signOut: '/signOut',
  termsAndConditions: '/termsAndConditions',
  privacyPolicy: '/privacyPolicy',

  /* Truth Tracker Page */
  truthTracker: '/truthTracker',
  addBankAccount: '/truthTracker/addBankAccount',

  /* Mobile mockup */
  permitStoreAnonym: '/lot/rates',

  /* Dashboard */
  dashboard: '/dashboard',

  /* Lot Management Page */
  lotManagement: '/lotManagement',
  addLot: '/lotManagement/addLot',
  editLot: '/lotManagement/editLot',

  /* User Profile Page */
  userProfile: '/profile',
  userDetails: '/profile/details',
  userCompanyDetails: '/profile/companyDetails',
  userPaymentInfo: '/profile/paymentInfo',

  /* Violatins Pages */
  violations: '/violations',
  violationsList: '/violations/list',
  violationsCompanies: '/violations/companies',
  violationsAddCompany: '/violations/addCompany',
  violationsEditCompany: '/violations/editCompany',

  /* Violatins Customer Pages */
  violationsCustomer: '/customer/violations',
  violationsSearch: '/customer/violations/search',
  violationsDetails: '/customer/violations/details',
  violationsAnonymSearch: '/violations/search',
  violationsAnonymDetails: '/violations/details',

  /* Long Term Contract */
  ltp: '/longTermContracts',
  ltpContracts: '/longTermContracts/contracts',
  waitList: '/longTermContracts/waitList',
  rewiewAgreement: '/longTermContracts/agreement/rewiew',
  ltpAddContract: '/longTermContracts/contracts/addContract',
  ltpEditContract: '/longTermContracts/contracts/editContract',
  ltpCustomers: '/longTermContracts/customers',

  /* Messages Pages */
  messages: '/messages',
  messagesEditTemplate: '/messages/editTemplate',

  /* Billing History */
  billingHistory: '/customer/bilingHistory',

  /*Violation Officer Pages */
  parkingLots: '/parkingLots',
  plateSearch: '/plateSearch',
  officerViolationsList: '/violationsList',

  /*Access Denied */
  accessDenied: '/accessDenied',

  /*Access Denied */
  notFound: '/notFound',

  /* Permit Store Customer Pages */
  permitStoreCustomer: '/customer/permitStore',

  /* My Permits Customer Pages */
  myPermitsCustomer: '/customer/myPermits',

  /*Long Term Contract Customer Pages */
  customerLtp: '/customer/longTermContracts',
  customerLtpContract: '/customer/longTermContracts/contract',

  userList: '/userList',

  /* Admin Panel Pages */
  adminPanel: '/adminPanel',
  userManagement: '/adminPanel/userManagement',
  companyManagement: '/adminPanel/companyManagement',
  addUser: '/adminPanel/addUser',
  editUser: '/adminPanel/editUser',
  addCompany: '/adminPanel/addCompany',
  editCompany: '/adminPanel/editCompany',
}

export default routes
