import * as yup from 'yup'

const ValidationCodesSchema = yup.object().shape({
  hours: yup
    .number()
    .required('Duration is required')
    .typeError('Duration is required'),
  codePrefix: yup
    .string()
    .when('codeLength', {
      is: 6,
      then: yup.string().max(2, 'Max length key word 2 symbols'),
    })
    .when('codeLength', {
      is: 7,
      then: yup.string().max(3, 'Max length key word 3 symbols'),
    })
    .when('codeLength', {
      is: 8,
      then: yup.string().max(4, 'Max length key word 4 symbols'),
    })
    .when('codeLength', {
      is: 9,
      then: yup.string().max(5, 'Max length key word 5 symbols'),
    })
    .when('codeLength', {
      is: 10,
      then: yup.string().max(6, 'Max length key word 6 symbols'),
    })
    .when('codeLength', {
      is: 11,
      then: yup.string().max(7, 'Max length key word 7 symbols'),
    })
    .when('codeLength', {
      is: 12,
      then: yup.string().max(8, 'Max length key word 8 symbols'),
    })
    .when('codeLength', {
      is: 13,
      then: yup.string().max(9, 'Max length key word 9 symbols'),
    })
    .when('codeLength', {
      is: (len) => len >= 14,
      then: yup.string().max(10, 'Max length key word 10 symbols'),
    }),
  codeLength: yup
    .number()
    .nullable()
    .typeError('Length must be a number')
    .min(6, 'Min length 6')
    .max(20, 'Max length 20'),
})

export default ValidationCodesSchema
