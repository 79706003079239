import styled, { css } from 'styled-components'

import {
  marginStyle,
  dimensionStyle,
  textAlignStyle,
} from '@/common/theme/styles'
import colors from '@/common/theme/variables/colors'

const fWeight = css`
  ${(props) => {
    if (typeof props.fontWeight === 'number') {
      return `font-weight: ${props.fontWeight}`
    } else {
      if (props.fontWeight === 'medium') {
        return `font-weight: 500;`
      } else if (props.fontWeight === 'bold') {
        return `font-weight: 700;`
      } else {
        return `font-weight: ${props.fontWeight};`
      }
    }
  }};
`

const textAlign = css`
  text-align: ${(props) => props.textAlign};
`

const lineHeight = css`
  line-height: ${(props) => props.lineHeight};
`

const uppercase = css`
  text-transform: uppercase;
`

const italic = css`
  font-style: italic;
`
const requiredAfter = css`
  &::after {
    margin-left: 1px;
    content: '*';
    display: inline;
    color: ${colors.trueBlue};
    font-weight: 500;
  }
`

const cursorStyle = css`
  cursor: ${(props) => props.cursor ? props.cursor : 'auto'};
`

const Text = styled.p`
  font-weight: 400;
  text-align: left;
  font-size: ${(props) => props.fontSize || '12px'};
  color: ${({ $color }) => $color || colors.lightGrayText};
  ${(props) => props.fontWeight && fWeight};
  ${(props) => props.textAlign && textAlign};
  ${(props) => props.uppercase && uppercase};
  ${(props) => props.lineHeight && lineHeight};
  ${(props) => props.italic && italic};
  ${marginStyle};
  ${dimensionStyle};
  ${textAlignStyle};
  ${cursorStyle};
  ${({ isRequired }) => isRequired && requiredAfter}
`

export default Text
