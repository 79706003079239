import { withQuery } from '@/common/utils/helpers'

function registerCustomerMyPermitsApi(api) {
  return {
    get: (status, plate) =>
      api.get(withQuery(`/permit/my/${status}`, { plate })),
  }
}

export default registerCustomerMyPermitsApi
