import React from 'react'

import { CommonContainer } from '@/common/baseStyledElements'
import Loader from '@/components/Loader'

function LoadingScreen() {
  return (
    <CommonContainer
      $width={'100vw'}
      $height={'100vh'}
      $display={'flex'}
      $alignItems={'center'}
      $justifyContent={'center'}
    >
      <Loader />
    </CommonContainer>
  )
}

export default LoadingScreen