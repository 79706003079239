import * as yup from 'yup'

const violationFormSchema = yup.object().shape({
  id: yup
    .string()
    .required('Validation Code required')
    .typeError('Validation Code required'),
})

export default violationFormSchema
