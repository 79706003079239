export function queryParser(query) {
  const noQuery = Object.keys(query).length === 0
  if (noQuery) return ''

  const queryArray = Object.keys(query).map(i => {
    if (typeof query[i] === 'object')
      return Object.keys(query[i]).map(j => `${i}=${query[i][j]}`).join('&')
    else
      return `${i}=${query[i]}`
  })

  return queryArray.join('&')
}

/**
 * @param api - api url
 * @param query - queries to be passed to request, ex: {page: 1, limit: 10}
 * @returns {string}
 */
export function withQuery(api, query = {}) {
  const parseQuery = queryParser(query)
  return `${api}?${parseQuery}`
}
