import { withQuery } from '@/common/utils/helpers'

function registerWaitListApi(api) {
  return {
    getAll: (query) => api.get(withQuery(`/contracts/waitlist`, query)),
    getAgreement: (id) => api.get(`/contracts/waitlist/${id}`),
    create: (data) => api.post(`/contracts/waitlist`, data),
    update: (id, data) => api.put(`/contracts/waitlist/${id}`, data),
    delete: (id) => api.delete(`/contracts/waitlist/${id}`),
    export: (query) =>
      api.get(withQuery(`/contracts/waitlist/export`, query), 
      { responseType: 'blob' }),
    approve: ({id, ...data}) => api.put(`/contracts/${id}/approve`, data),
  }
}

export default registerWaitListApi
