import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from 'react-query'
import { toast } from 'react-toastify'

import { waitListApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { AGREEMENTS, AGREEMENT_INFO } = QUERY_KEYS

/**
 * Fetch all agreements for lot
 * @param query for using pagination, sort, search and filters
 * @returns useQuery instance
 */
function useAgreements(query) {
  return useQuery([AGREEMENTS, query], () => waitListApi.getAll(query))
}

/**
 * Fetch all agreements for lot with infinite scroll methods
 * @param query for using pagination, sort, search and filters
 * @returns useInfiniteQuery instance
 */
function useAgreementsCustomer(query) {
  return useInfiniteQuery(
    [AGREEMENTS, query],
    (param) =>
      waitListApi.getAll({
        ...query,
        'Pagination.page': param?.pageParam ?? 1,
      }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.pageIndex < lastPage.totalPages
          ? lastPage.pageIndex + 1
          : undefined,
    },
  )
}

/**
 * Fetch agreement by id
 * @param id
 * @returns useQuery instance
 */
function useAgreement(id) {
  return useQuery([AGREEMENT_INFO, id], () =>
    waitListApi.getAgreement(id),
  )
}

/**
 * Create agreement
 * @param successCallback
 * @returns useMutation instance
 */
function useCreateAgreement(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => waitListApi.create(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(AGREEMENTS)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
        await queryClient.invalidateQueries(AGREEMENTS)
      }
    },
  })
}

/**
 * Update agreement
 * @param successCallback
 * @returns useMutation instance
 */
function useUpdateAgreement(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => waitListApi.update(data.id, data), {
    onMutate: async () => {
      await queryClient.cancelQueries(AGREEMENTS)
      await queryClient.cancelQueries(AGREEMENT_INFO)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.invalidateQueries(AGREEMENTS)
        await queryClient.invalidateQueries(AGREEMENT_INFO)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Delete agreement
 * @param successCallback
 * @returns useMutation instance
 */
function useDeleteAgreement(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => waitListApi.delete(id), {
    onMutate: async () => {
      await queryClient.cancelQueries(AGREEMENTS)
    },
    onSettled: async (data) => {
      if (data.responseStatus === 200) {
        toast.success('Contract deleted!')
        await queryClient.invalidateQueries(AGREEMENTS)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Get export file agreements by query
 * @param query
 * @returns useQuery instance
 */
function useAgreementsExport(successCallback) {
  return useMutation((query) => waitListApi.export(query), {
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        successCallback(data)
      }
    },
  })
}

/**
 * Approve agreement
 * @param successCallback
 * @returns useMutation instance
 */
function useApprove(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => waitListApi.approve(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(AGREEMENTS)
      await queryClient.cancelQueries(AGREEMENT_INFO)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.invalidateQueries(AGREEMENT_INFO)
        await queryClient.invalidateQueries(AGREEMENTS)
        runCallback(successCallback)
      }
    },
  })
}

export {
  useAgreements,
  useAgreementsCustomer,
  useAgreement,
  useCreateAgreement,
  useUpdateAgreement,
  useDeleteAgreement,
  useAgreementsExport,
  useApprove,
}
