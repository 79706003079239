import React from 'react'

import ReactDOM from 'react-dom'

import { registerResponseInterceptors } from '@/api/interceptors'

import App from './App'
import reportWebVitals from './reportWebVitals'

import './common/theme/scss/main.scss'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import 'react-image-lightbox/style.css'

registerResponseInterceptors()

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
