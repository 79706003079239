import styled from 'styled-components'

import { baseStyles } from '@/common/theme/styles'

const Col = styled.div`
  display: flex;
  flex-direction: column;
  ${baseStyles};

  ${({ $direction }) => $direction === 'row' && 'flex-direction: row'}
`

export default Col
