import * as yup from 'yup'

const addLtpCarSchema = yup.object().shape({
  licensePlate: yup.string().required('License Plate Number is required'),
  brand: yup.string(),
  model: yup.string(),
  color: yup.string(),
})

export default addLtpCarSchema
