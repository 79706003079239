const MODAL_KEYS = {
  DELETE_LOT: 'DELETE_LOT',
  TOTAL_AMOUNT: 'TOTAL_AMOUNT',

  OPEN_APP: 'OPEN_APP',

  VIEW_PHOTO: 'VIEW_PHOTO',

  SET_LOT_AVAILABILITY: 'SET_LOT_AVAILABILITY',
  EDIT_LOT_AVAILABILITY: 'EDIT_LOT_AVAILABILITY',
  SET_USER_AVAILABILITY: 'SET_USER_AVAILABILITY',

  SET_RATE: 'SET_RATE',

  LIST_FILTER: 'LIST_FILTER',

  LOT_MESSAGES_FILTER: 'LOT_MESSAGES_FILTER',

  VIOLATION_ACTIONS_FILTER: 'VIOLATION_ACTIONS_FILTER',
  VIOLATION_ACTIONS_CANCEL: 'VIOLATION_ACTIONS_CANCEL',
  VIOLATION_COMPANIES_FILTER: 'VIOLATION_COMPANIES_FILTER',
  VIOLATION_COMPANIES_DELETE: 'VIOLATION_COMPANIES_DELETE',
  VIOLATION_LIST_FILTER: 'VIOLATION_LIST_FILTER',

  VENDOR_CONTRACTS_FILTER: 'VENDOR_CONTRACTS_FILTER',
  VENDOR_CONTRACTS_DELETE: 'VENDOR_CONTRACTS_DELETE',

  LTP_CONTRACTS_ADD_ANONYM_CUSTOMER: 'LTP_CONTRACTS_ADD_ANONYM_CUSTOMER',
  LTP_CONTRACTS_ADD_CUSTOMER: 'LTP_CONTRACTS_ADD_CUSTOMER',
  LTP_CONTRACTS_ADD_CAR: 'LTP_CONTRACTS_ADD_CAR',

  LTP_CONTRACTS_FILTER: 'LTP_CONTRACTS_FILTER',
  LTP_CONTRACTS_DELETE: ' LTP_CONTRACTS_DELETE',
  LTP_SUBSCRIPTION_DELETE: 'LTP_SUBSCRIPTION_DELETE',

  WHITE_LIST_FILTER: 'WHITE_LIST_FILTER',
  WHITE_LIST_DELETE: 'WHITE_LIST_DELETE',

  PROMO_CODE_CREATE: 'PROMO_CODE_CREATE',

  VALIDATIONS: 'VALIDATIONS',
  VALIDATIONS_DELETE: 'VALIDATIONS_DELETE',

  OFFICER_COMPLETE_PAYMENT: 'OFFICER_COMPLETE_PAYMENT',
  OFFICER_CREATE_VIOLATION: 'OFFICER_CREATE_VIOLATION',
  OFFICER_DELETE_DOCUMENT: 'OFFICER_DELETE_DOCUMENT',
  OFFICER_CONFIRM_WITH_NOTES: 'OFFICER_CONFIRM_WITH_NOTES',

  VALIDATION_CODE_UI: 'VALIDATION_CODE_UI',

  CARD_UI: 'CARD_UI',
  PAID_IN_CASH: 'PAID_IN_CASH',
  ACCEPT_VIOLATION: 'ACCEPT_VIOLATION',
  ACCEPTED: 'ACCEPTED',

  FEES_AMOUNT_FILTER: 'FEES_AMOUNT_FILTER',

  CUSTOMER_COMPLETE_PERMIT_PAYMENT: 'CUSTOMER_COMPLETE_PERMIT_PAYMENT',
  CUSTOMER_COMPLETE_LTP_PAYMENT: 'CUSTOMER_COMPLETE_LTP_PAYMENT',

  REVENUE_FILTER: 'REVENUE_FILTER',
  REAL_TIME_FILTER: 'REAL_TIME_FILTER',
  LOTS_FILTER: 'LOTS_FILTER',

  COMPANY_FILTER: 'COMPANY_FILTER',
  COMPANY_DELETE: 'COMPANY_DELETE',
  USER_MODAL: 'USER_MODAL',
  USER_MANAGEMENT_FILTER: 'USER_MANAGEMENT_FILTER',
  USER_MANAGEMENT_DELETE: ' USER_MANAGEMENT_DELETE',

  VIOLATION_STATUS: 'VIOLATION_STATUS',

  COMMON_PAYMENT_MODAL: 'COMMON_PAYMENT_MODAL',

  REFERRAL_FEES_REPORT: 'REFERRAL_FEES_REPORT',
}
export default MODAL_KEYS
