import * as yup from 'yup'

import regExpValidations from '@/common/utils/const/regExp'

const companyFormSchema = yup.object().shape({
  name: yup.string().required('Company Name is required'),
  phone: yup
    .string()
    .required('Phone is required')
    .matches(regExpValidations?.phoneRegExp, 'Phone number is not valid'),
  email: yup
    .string()
    .required('Email is required')
    .matches(regExpValidations?.emailRegExp, 'Email is not valid'),
  countryId: yup.number().required('Please, select counrry'),
  stateId: yup.number().required('Please, select state'),
  city: yup.string().required('City is required'),
  postalCode: yup
    .string()
    .required('ZIP is required')
    .matches(regExpValidations.postalRegExp, 'ZIP should be valid'),
  addressLine: yup.string().required('Address Line is required'),
  primaryContactName: yup.string().nullable(),
  primaryContactPhone: yup
    .string().nullable()
    /*.matches(regExpValidations?.phoneRegExp, 'Phone number is not valid')*/,
  primaryContactEmail: yup
    .string().nullable()
    /*.matches(regExpValidations?.emailRegExp, 'Email is not valid')*/,
})

export default companyFormSchema
