import { useEffect } from 'react'

import cloneDeep from 'lodash.clonedeep'
import { useRecoilState } from 'recoil'

import { modalsControllerAtom } from '@/common/store/modals'

function useModal(id) {
  const [modals, setModalsData] = useRecoilState(modalsControllerAtom)
  const visible = modals[id]?.visible || false
  const _modalData = modals[id] || {}
  const _modalProps = modals[id]?.props || {}

  const updateCurrentModal = (data) => {
    setModalsData((current) => {
      return {
        ...current,
        ...data,
      }
    })
  }

  const withBaseData = (data) => ({
    [id]: {
      ..._modalData,
      ...data,
    },
  })

  const withProps = (props) => ({
    props: {
      ..._modalProps,
      ...props,
    },
  })

  const show = () => {
    const temp = cloneDeep(modals)
    for (let i in temp) {
      temp[i].visible = false
    }
    updateCurrentModal({
      ...temp,
      ...withBaseData({
        visible: true,
      }),
    })
  }

  const showWithProps = (props) => {
    const temp = cloneDeep(modals)
    for (let i in temp) {
      temp[i].visible = false
    }
    updateCurrentModal({
      ...temp,
      ...withBaseData({
        visible: true,
        ...withProps(props),
      }),
    })
  }

  const showByClick = (targetCoordinated, props) => {
    const temp = cloneDeep(modals)
    for (let i in temp) {
      temp[i].visible = false
    }

    if (targetCoordinated && _modalProps?.modalParent) {
      const { left, bottom, width } = targetCoordinated?.getBoundingClientRect()
      let centerTarget = left + width / 2

      const { top: topOfParent, left: leftOfParent } =
        _modalProps?.modalParent?.getBoundingClientRect()

      let topCoordinate = bottom - topOfParent
      let leftCoordinate = centerTarget - leftOfParent

      if (leftCoordinate < _modalProps?.modalWidth / 2) {
        leftCoordinate = _modalProps?.modalWidth / 2
        centerTarget = centerTarget - leftOfParent
      } else {
        centerTarget = false
      }

      updateCurrentModal({
        ...temp,
        ...withBaseData({
          visible: !visible,
          ...withProps({
            topCoordinate,
            leftCoordinate,
            centerTarget,
            ...props,
          }),
        }),
      })
    }
  }

  const hide = () => {
    updateCurrentModal(
      withBaseData({
        visible: false,
      }),
    )
  }

  const hideWithReset = () => {
    updateCurrentModal({
      [id]: {
        visible: false,
      },
    })
  }

  const passProps = (props) => {
    updateCurrentModal(withBaseData(withProps(props)))
  }

  const escFun = (event) => {
    if (visible && event.keyCode === 27) {
      hide()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', escFun, false)
    return () => {
      document.removeEventListener('keydown', escFun, false)
    }
  }, [modals[id]])

  return {
    id,
    show,
    showWithProps,
    showByClick,
    hide,
    hideWithReset,
    passProps,
    visible,
    props: _modalProps,
  }
}

export default useModal
