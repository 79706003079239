import * as yup from 'yup'

const MessageTemplateSchema = yup.object().shape({
  subject: yup.string().required('Message Type is required'),
	message: yup.string().required('Message Template is required')
	.when('mailTags', {
		is: (tags) => tags?.length > 0,
		then: yup.string().test('tags', 
		`Message should includes required mail-tags`, 
		(val, ctx) => 
		ctx?.parent?.mailTags?.every(tag => val?.includes(tag))
		)
	}),
	mailTags: yup.array(),
	isSms: yup.bool().when('isEmail', {
		is: (isEmail) => !isEmail,
		then:  yup
		.bool()
		.oneOf([ true ], 'At least one needs to be checked'),
		otherwise: yup.bool()
	}),
	isEmail: yup.bool().when('isSms', {
		is: (isSms) => !isSms,
		then:  yup
		.bool()
		.oneOf([ true ], 'At least one needs to be checked'),
		otherwise: yup.bool()
	}),
}, [['isSms', 'isEmail']])

export default MessageTemplateSchema