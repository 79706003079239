import { withQuery } from '@/common/utils/helpers'

function registerLotInfoApi(api) {
  return {
    getAll: (query) => api.get(withQuery(`/parking-lot`, query)),
    get: (id) => api.get(`/parking-lot/${id}`),
    create: (data) => api.post(`/parking-lot`, data),
    update: (id, data) => api.put(`/parking-lot/${id}`, data),
    delete: (id) => api.delete(`/parking-lot/${id}`),
    changeBlock: (id) => api.put(`/parking-lot/${id}/changeblocking`),
    getOwners: () => api.get(`/parking-lot/owners`),
    getManagers: () => api.get(`/parking-lot/lotmanagers`),
    export: (query) =>
      api.get(withQuery(`/parking-lot/export`, query), {
        responseType: 'blob',
      }),
  }
}

export default registerLotInfoApi
