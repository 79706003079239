import { withQuery } from '@/common/utils/helpers'

function registerVendorContractsApi(api) {
  return {
    getContracts: (query) => api.get(withQuery(`/vendor-contracts`, query)),
    getContract: (lotId, id) =>
      api.get(`/vendor-contracts/${id}?lotId=${lotId}`),
    create: (data) => api.post(`/vendor-contracts`, data),
    update: (id, data) => api.put(`/vendor-contracts/${id}`, data),
    export: (query) => 
      api.get(
        withQuery(`/vendor-contracts/lot/${query.lotId}/export`, query), 
        {responseType: 'blob'}
      ),
    delete: (lotId, id) =>
      api.delete(withQuery(`/vendor-contracts/${id}`, lotId)),
    getReferrals: (lotId) =>
      api.get(`/vendor-contracts/lot/${lotId}/referrals`),
  }
}

export default registerVendorContractsApi