import {
  endOfMonth,
  startOfMonth,
  format,
  isValid,
  parse,
  differenceInHours,
  addHours
} from 'date-fns'

import { FORMATS } from '@/common/utils/const'

/**
 * Get start date of the current month
 * @returns {Date}
 */
export function getStartOfMonth() {
  return startOfMonth(new Date())
}

/**
 * Get end date of the current month
 * @returns {Date}
 */
export function getEndOfMonth() {
  return endOfMonth(new Date())
}

/**
 * Convert date from string format "Sun Dec 26 2021" to yyyy-MM-dd string
 * @param {dateStr} string date in format "Sun Dec 26 2021"
 * @returns {string} date as yyyy-MM-dd string
 */
export function formatToYYYYMMDD(dateStr) {
  if (dateStr) {
    const date = new Date(dateStr)
    if (isValid(date)) {
      return format(date, 'yyyy-MM-dd')
    }
  }
  return null
}

/**
 * Check if yyyy-MM-dd string can be converted to valid date
 * @param {dateStr} string date in format "yyyy-MM-dd"
 * @returns {boolean} true if string can be converted to valid date
 */

export function isStrIsValidDate(dateStr) {
  return isValid(parse(dateStr, 'yyyy-MM-dd', new Date()))
}

/**
 * Convert date and time into Date object
 * @param {string} date date in yyyy-MM-dd format
 * @param {string} time time in hh:mm:ss format
 * @returns {Array| undefined} array of numbers [yyyy, MM, dd, hh, ss]
 */
export function getDateFromDateAndTimeStrings(date, time) {
  if (date && time && typeof date === 'string' && typeof time === 'string') {
    const dateArr = date.split('-')
    const lastDateItem = dateArr[2]
    dateArr[2] = lastDateItem.substring(0, lastDateItem.indexOf('T'))
    const datetimeArr = [...dateArr, ...time.split(':')]
    const newDate = new Date(...datetimeArr)
    if (isValid(newDate) && datetimeArr.length === 6) {
      return newDate
    }
  }
}

/**
 * Get the number of hours between the given dates
 * @param {Date} from the earlier date
 * @param {Date} to the later date
 * @returns {number} number of hours between the given dates
 */
export function getHoursFromInterval(from, to) {
  if (isValid(from) && isValid(to)) {
    return differenceInHours(to, from)
  }
}

/**
 * Get the number of working hours from object
 * @param {string} date the datetime in format yyyy-MM-ddThh:mm:ss
 * @param {string} workFrom the lot start working hour
 * @param {string} workTo the lot end working hour
 * @returns {number | undefined} number of hours
 */
export function getLotDailyWorkingHours(props) {
  if (props) {
    const { date, workFrom, workTo } = props
    if (
      date &&
      workFrom &&
      workTo &&
      typeof date === 'string' &&
      typeof workFrom === 'string' &&
      typeof workTo === 'string'
    ) {
      const lotAvailabilityDateFrom = getDateFromDateAndTimeStrings(
        date,
        workFrom,
      )
      const lotAvailabilityDateTo = getDateFromDateAndTimeStrings(date, workTo)
      return getHoursFromInterval(
        lotAvailabilityDateFrom,
        lotAvailabilityDateTo,
      )
    }
  }
}
/**
 * Convert HH:MM:SS to date with given time
 * @param {string} strHHMMSS string in HH:MM:SS
 * @returns {Date} today date with given time
 */
export function getDateFromHHMMSS(strHHMMSS, nextDay = false) {
  const timeArr = strHHMMSS.split(':')
  const now = new Date()
  const YYYY = format(now, 'yyyy')
  const MM = format(now, 'MM')
  const DD = +format(now, 'dd') + (nextDay ? 1 : 0)
  return new Date(YYYY, MM, DD, ...timeArr)
}

/** 
 * Get hours from time interval
 * @param {string} fromHHMMSS string in HH:MM:SS
 * @param {string} toHHMMSS string in HH:MM:SS
 * @returns number of hours
*/
export function getHoursFromHHMMSS(fromHHMMSS, toHHMMSS) {
  const fromBigger = 
    +fromHHMMSS.split(':').join('') > +toHHMMSS.split(':').join('')
  const from = getDateFromHHMMSS(fromHHMMSS) //?
  const to = getDateFromHHMMSS(toHHMMSS, fromBigger) //?
  if (isValid(from) && isValid(to)) {
    return getHoursFromInterval(from,to)
  }
}

export function getCurrentDate() {
  return format(new Date(), FORMATS.DATE_TIME_FORMAT)
}

export function formatAddHours(hours = 0, date = new Date()) {
  const newDate = addHours(date, hours)
  return format(newDate, FORMATS.DATE_TIME_FORMAT)
}

export function formatDate(eventDate = new Date()) {
  const date = new Date(eventDate)
  return format(date, FORMATS.DATE_TIME_FORMAT)
}

export function formatSimpleDate(
  eventDate = new Date(),
  requiredFormat = FORMATS.SIMPLE_DATE_FORMAT,
) {
  const date = new Date(eventDate)
  return format(date, requiredFormat)
}

export function getTimeOffset() {
  return -1 * new Date().getTimezoneOffset()
}

export function timeStringToFloat(time) {
  let hoursMinutes = time.split(/[.:]/);
  let hours = parseInt(hoursMinutes[0], 10);
  let minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  return hours + minutes / 60;
}

/**
 * Return string which represents duration of permit
 * @returns string
 */
 export function returnDuration(hours, isFullDay) {
  const durationFloatNumber = hours ? String(hours) : 0

  const durationString = isFullDay
    ? 'Full Day'
    : durationFloatNumber < 1.5
    ? `${durationFloatNumber} hour`
    : `${durationFloatNumber} hours`

  return durationString
}