import { atom } from 'recoil'

export const countryAtom = atom({
  key: 'dictionary/county',
  default: [],
})

export const stateAtom = atom({
  key: 'dictionary/state',
  default: [],
})

export const userRoleAtom = atom({
  key: 'dictionary/userRole',
  default: [],
})

export const exportTypesAtom = atom({
  key: 'dictionary/exportTypes',
  default: [],
})

export const violationTypesAtom = atom({
  key: 'dictionary/violationTypes',
  default: [],
})

export const vendorTypesAtom = atom({
  key: 'dictionary/vendorTypes',
  default: [],
})

export const validationsEmailsAtom = atom({
  key: 'dictionary/validationsEmails',
  default: [],
})

export const violationReasonsAtom = atom({
  key: 'dictionary/violationReasons',
  default: [],
})

export const timezonesAtom = atom({
  key: 'dictionary/timezones',
  default: [],
})
