import styled from 'styled-components'

import { colors } from '@/common/theme/variables'

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const UserInfo = styled.p`
  color: ${colors.davyGray};
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin-right: 8px;
  text-align: center;
`

export const UserImage = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  font-size: 12px;
`
