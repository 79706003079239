import { css } from 'styled-components'

const dimensionStyle = css`
  ${({ $width }) => {
    return $width &&
      `width: ${typeof $width === 'number' ? `${$width}px` : $width}`
  }};
  ${({ $height }) => {
    return (
      $height &&
      `height: ${typeof $height === 'number' ? `${$height}px` : $height}`
    )
  }};
  ${({ $fullSize }) => {
    return $fullSize && `width: 100%; height: 100%`
  }};
  ${({ $fullWidth }) => {
    return $fullWidth && `width: 100%;`
  }};
`

export default dimensionStyle
