import { withQuery } from '@/common/utils/helpers'

function permitStoreApi(api) {
  return {
    getRatesForNow: (lotId, query) =>
      api.get(withQuery(`/rates/lot/${lotId}/for-now`, query)),

    getActivePass: (lotId, query) =>
      api.get(withQuery(`/rates/lot/${lotId}/active-user-pass`, query)),

    postValidationCode: (query) =>
      api.post(withQuery(`/validation/use-code`, query)),

    getLotName: (lotId) =>
      api.get(`/parking-lot/${lotId}/name`),
  }
}

export default permitStoreApi
