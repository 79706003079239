import * as yup from 'yup'

const feesReportSchema = yup.object().shape({
  paymentReport: yup.string().required('Report type is required'),
  dateFrom: yup
    .string()
    .required('Start Date required')
    .dateValidation(yup.ref('dateTo')),
  dateTo: yup
    .string()
    .required('End Date required')
    .dateValidation(yup.ref('dateFrom'), true),
})

export default feesReportSchema
