import * as yup from 'yup'

const ltpAddContractSchema = yup.object().shape({
  ltpType: yup.number().required('Type contract is required'),
  lotId: yup.number().required('Parking Lot is required'),
  customer: yup.object().when('ltpType', {
    is: 1,
    then: yup.object().required('Customer is required'),
  }),
  monthlyRate: yup.string().required('Price is required'),
  noExpiration: yup.mixed().oneOf([true, false]),
  startDate: yup
    .string()
    .when('noExpiration', {
      is: true,
      then: yup.string().required('Start Date is required'),
    })
    .when('noExpiration', {
      is: false,
      then: yup
        .string()
        .required('Start Date is required')
        .dateValidation(yup.ref('expirationDate')),
    }),
  expirationDate: yup
    .string()
    .when('noExpiration', {
      is: true,
      then: yup.string().nullable(),
    })
    .when('noExpiration', {
      is: false,
      then: yup
        .string()
        .required('Expiration Date is required')
        .dateValidation(yup.ref('startDate'), true),
    }),
  licensePlate: yup
    .array()
    .of(
      yup.object().shape({
        licensePlate: yup
          .string()
          .max(10, 'Maximum length of license plate is 10 symbols'),
      }),
    )
    .min(1, 'Add at least one car')
    .required('Add at least one car'),
})

export default ltpAddContractSchema
