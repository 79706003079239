import * as yup from 'yup'

import { PASS_DURATION_TYPES } from '@/common/utils/const'
import regExpValidations from '@/common/utils/const/regExp'

const PromoCodesSchema = yup.object().shape({
  businessName: yup.string().required('Business name is required'),
  hours: yup.number().when('passDuration', {
    is: PASS_DURATION_TYPES.CUSTOM,
    then: yup
      .number()
      .typeError('Need enter hours')
      .required('Need enter hours')
      .positive('Hours can be only positive')
      .max(24, 'Max value it 24')
      .min(0.5, 'Min value it 0,5')
      .test('formatHours', 'Invalid hours. Example: 2 or 2,5 ...', (value) =>
        new RegExp(regExpValidations.floatHulfHours).test(value),
      ),
  }),
  prefix: yup
    .string()
    .when('length', {
      is: 10,
      then: yup.string().max(6, 'Max length key word 6 symbols'),
    })
    .when('length', {
      is: 11,
      then: yup.string().max(7, 'Max length key word 7 symbols'),
    })
    .when('length', {
      is: 12,
      then: yup.string().max(8, 'Max length key word 8 symbols'),
    })
    .when('length', {
      is: 13,
      then: yup.string().max(9, 'Max length key word 9 symbols'),
    })
    .when('length', {
      is: (len) => len >= 14,
      then: yup.string().max(10, 'Max length key word 10 symbols'),
    }),
  length: yup
    .number()
    .nullable()
    .typeError('Length must be a number')
    .min(10, 'Min length 10')
    .max(20, 'Max length 20'),
  count: yup
    .number()
    .required('Quantity is required')
    .typeError('Quantity is required'),
  startDate: yup
    .string()
    .required('Start Date is required')
    .dateValidation(yup.ref('endDate')),
  endDate: yup
    .string()
    .required('Expiration Date is required')
    .dateValidation(yup.ref('startDate'), true),
})

export default PromoCodesSchema
