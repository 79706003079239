import { isStrIsValidDate, withQuery } from '@/common/utils/helpers'

function registerLotAvailabilityApi(api) {
  return {
    getAllByWeek: (lotId, query) => 
      api.get(withQuery(`/lotavailability/${lotId}/week`, query)),
    getByWeek: (id, lotId) => api.get(`/lotavailability/${lotId}/week/${id}`),
    createByWeek: (lotId, data) =>
      api.post(`/lotavailability/${lotId}/week`, data),
    updateByWeek: (lotId, data) =>
      api.put(`/lotavailability/${lotId}/week/`, data),
    deleteByWeek: (lotId, id) =>
      api.delete(withQuery(`/lotavailability/week/${id}`, lotId)),

    getAllByDate: (lotId, query) =>
      api.get(withQuery(`/lotavailability/${lotId}/day`, query)),
    getByDate: (id, lotId) => api.get(`/lotavailability/${lotId}/day/${id}`),
    createByDate: (lotId, data) =>
      api.post(`/lotavailability/${lotId}/day`, data),
    updateByDate: (lotId, id, data) =>
      api.put(`/lotavailability/${lotId}/day/${id}`, data),
    deleteByDate: (lotId, id) =>
      api.delete(withQuery(`/lotavailability/day/${id}`, lotId)),
    getDayShedule: (lotId, startDate, stopDate) => {
      if (isStrIsValidDate(startDate) && isStrIsValidDate(stopDate))
        return api.get(
          // eslint-disable-next-line max-len
          `/lotavailability/${lotId}/day-schedule?DateFrom=${startDate}&DateTo=${stopDate}`,
        )
    },
    getWeekShedule: (lotId) =>
      api.get(`/lotavailability/${lotId}/week-schedule`),
    changeActivityByWeek: (lotId, id) =>
      api.put(withQuery(`/lotavailability/${id}/week/change-activity`, lotId)),
    changeActivityByDate: (lotId, id) =>
      api.put(withQuery(`/lotavailability/${id}/day/change-activity`, lotId)),
  }
}

export default registerLotAvailabilityApi
