import * as yup from 'yup'

const ReferralFeesReport = yup.object().shape({
  Type: yup.number().required('Type is required'),
  DateFrom: yup
    .string()
    .required('Field is required')
    .dateValidation(yup.ref('DateTo')),
  DateTo: yup
    .string()
    .required('Field is required')
    .dateValidation(yup.ref('DateFrom'), true),
})

export default ReferralFeesReport
