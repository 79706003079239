import { withQuery } from '@/common/utils/helpers'

function registerWhiteListApi(api) {
  return {
    getAll: (query) => api.get(withQuery(`/whitelist`, query)),
    create: (data) => api.post(`/whitelist`, data),
    update: (id, data) => api.put(`/whitelist/${id}`, data),
    delete: (lotId, id) => api.delete(withQuery(`/whitelist/${id}`, lotId)),
    export: (query) => 
      api.get(
        withQuery(`/whitelist/export`, query), {responseType: 'blob'}
      ),
  }
}

export default registerWhiteListApi