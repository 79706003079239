import * as dateFns from 'date-fns'
import * as yup from 'yup'

export function registerTimeValidation() {
  yup.addMethod(yup.string, 'timeValidation', function(errorMessage) {
    return this.test(`test-time-validation`, errorMessage, function(value) {
      const { path, createError } = this

      const splitValue = value?.split(':')
      const hours = splitValue?.[0]
      const minutes = splitValue?.[1]

      return (
        (!isNaN(Number(hours)) && !isNaN(Number(minutes)))
        || createError({ path, message: errorMessage })
      )
    })
  })
}

export function registerDateValidation() {
  yup.addMethod(
    yup.string,
    'dateValidation',
    function(ref, isEndDate = false) {
      return this.test(`date-validation`, function(value) {
        const { path, createError } = this

        /**
         * default value for dates are '' in SetMessagesFilterModal.jsx
         * for throwing placeholders in DatePickers
         * so we need to pass form validation in SetMessagesFilterModal.jsx
         * with date values equal exact ''
         */
        if (value==='' && this.resolve(ref)==='') {
          return true
        }

        const inputDate = new Date(value)
        const targetDate = new Date(this.resolve(ref))
        const areDatesEquals = dateFns.isEqual(targetDate, inputDate)

        /**
         * Is user passed isEndDate=true then
         * we check if startDate is before endDate
         * else
         * we check
         */
        if (isEndDate) {
          const isStartDateBeforeEndDate = dateFns.isBefore(
            targetDate,
            inputDate,
          )

          if (!isStartDateBeforeEndDate && !areDatesEquals) {
            return createError({
              path,
              message: 'End date is less that start date',
            })
          }
        } else {
          const isStartDateAfterEndDate = dateFns.isAfter(inputDate, targetDate)

          if (isStartDateAfterEndDate && !areDatesEquals) {
            return createError({
              path,
              message: 'Start date is greater that end date',
            })
          }
        }
        return true;
      })
    })
}