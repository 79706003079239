import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/creatable'

import styles from './styles'

const ReactCreatableSelect = forwardRef((props, ref) => {
  const { options, placeholder, ...rest } = props

  const handleNewCreate = (inputValue, valueWithLabel) => {
    return { value: inputValue, text: valueWithLabel }
  }

  return (
    <CreatableSelect
      isMulti
      ref={ref}
      getNewOptionData={handleNewCreate}
      getOptionLabel={({ text }) => text}
      options={options}
      placeholder={placeholder}
      formatCreateLabel={(data) => 'Add New License Plate: ' + data}
      styles={styles}
      {...rest}
    />
  )
})

ReactCreatableSelect.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any,
      value: PropTypes.any,
    }),
  ),
}
ReactCreatableSelect.defaultProps = {
  name: 'ReactCreatableSelect',
  placeholder: 'Select an option...',
}

export default ReactCreatableSelect
