import styled, { css } from 'styled-components'

import { colors } from '@/common/theme/variables'

const noShowModal = css`
  display: none;
`

const showModal = css`
  display: block;
`

export const Container = styled.div`
  height: auto;
  width: 270px;
  top: 50px;
  left: 20px;
  z-index: 6;
  position: absolute;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 
  0px 1px 2px rgba(0, 0, 0, 0.06);
  background-color: ${colors.white};
  border-right: 1px solid ${colors.lavender};
  padding: 20px 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 8px;
`

export const ItemsContainer = styled.div`
  margin-top: 25px;
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.lavender};
`

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  ${({ open }) => open ? showModal : noShowModal}
` 
