import React, {useState} from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { Row } from '@/common/baseStyledElements'
import {
  UserProfileSchema,
  officerProfileSchema,
  customerProfileSchema,
} from '@/common/schemas'
import { useChangePassword } from '@/common/services'
import { countryAtom, stateAtom } from '@/common/store/dictionary'
import { toPhoneWithoutCode } from '@/common/utils/helpers'
import { isViolationOfficer, isCustomer } from '@/common/utils/roleAccesses'
import Button from '@/components/Button'
import Form from '@/components/Form'
import Loading from '@/components/Loading'

import OfficerForm from './OfficerForm';
import UserForm from './UserForm';

PersonalDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
  isLoading: PropTypes.bool,
}

PersonalDetailsForm.defaultProps = {
  user: {},
  isLoading: true
}

const formGroupProps = {
  $marginTop: 10,
}

function PersonalDetailsForm(props) {
  const { onSubmit, user, isLoading } = props

  const history = useHistory()
  const countries = useRecoilValue(countryAtom)
  const states = useRecoilValue(stateAtom)
  const isOfficer = isViolationOfficer(user?.role)
  const isUserCustomer = isCustomer(user?.role)

  const [passwordLoading, setPasswordLoading] = useState(false)

const passwordUpdateHandler = ({ ticketLink }) => {
    setPasswordLoading(true)
    window.location.href = ticketLink 
  }

  const { mutate: changePassword, isLoading: changePasswordLoading } =
    useChangePassword(passwordUpdateHandler)

  const defaultAssociatedPlates = user.id
  ? user?.associatedPlates.map((plate) => {
      return { value: plate.trim(), text: plate.trim() }
    })
  : ''

  const currentValidationSchema = isOfficer
    ? officerProfileSchema
    : isUserCustomer
    ? customerProfileSchema
    : UserProfileSchema

  const form = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      ...user,
      phone: toPhoneWithoutCode(user?.phone),
      city: user?.address?.city,
      birthDay: user?.birthDay || '',
      countryId: user?.address?.country?.id,
      stateId: user?.address?.state?.id,
      postalCode: user?.address?.postalCode,
      addressLine: user?.address?.addressLine,
      addressLine2: user?.address?.addressLine2,
      associatedPlates: defaultAssociatedPlates,
    },
    resolver: yupResolver(currentValidationSchema),
  })

  if (isLoading || changePasswordLoading || passwordLoading)
    return <Loading />  
  
  return (
    <Form.Form $width={'100%'} onSubmit={form.handleSubmit(onSubmit)}>
      <Form.FormGroup childrenProps={formGroupProps}>
        {isOfficer ? (
          <OfficerForm
          form={form}
          handleChangePassword={changePassword}
        />
        ) : (
          <UserForm 
            user={user}
            form={form} 
            countries={countries} 
            states={states}
            handleChangePassword={changePassword}
          />
        )}
        <Row $marginTop={20}>
          <Button
            text={'Cancel'}
            $marginRight={20}
            appearance={'secondary'}
            onClick={history.goBack}
          />
          <Button text={'Save'} type={'submit'} />
        </Row>
      </Form.FormGroup>
    </Form.Form>
  )
}

export default React.memo(PersonalDetailsForm)
