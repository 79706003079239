import { useQuery, useMutation } from 'react-query'

import { stripeAccountApi } from '@/api'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'

const { STRIPE_ACCOUNT } = QUERY_KEYS

/**
 * Check info for creation stripe account
 * @param lotId
 * @returns useQuery instance
 */
function useGetStripeAccount(enabled = false) {
  return useQuery([STRIPE_ACCOUNT], () => stripeAccountApi.getStripeAccount(), {
    enabled,
  })
}

/**
 * Check info for creation stripe account
 * @param lotId
 * @returns useQuery instance
 */
function useCheckStripeAccount(successCallback) {
  return useMutation(() => stripeAccountApi.checkStripeAccount(), {
    onSuccess: (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Create stripe account
 * @param successCallback
 * @returns useMutation instance
 */
function useCreateStripeAccount(successCallback) {
  return useMutation((data) => stripeAccountApi.createStripeAccount(data), {
    onSuccess: (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Update stripe account
 * @param successCallback
 * @returns useMutation instance
 */
 function useUpdateStripeAccount(successCallback) {
  return useMutation(() => stripeAccountApi.updateStripeAccount(), {
    onSuccess: (data) => {
      if (data.responseStatus === 200) {
        runCallback(() => {
          successCallback(data)
        })
      }
    },
  })
}

export {
  useGetStripeAccount,
  useCheckStripeAccount,
  useCreateStripeAccount,
  useUpdateStripeAccount,
}
