import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSetRecoilState } from 'recoil'

import { lotEnforcementApi } from '@/api'
import { violationTypesAtom } from '@/common/store/dictionary'
import { QUERY_KEYS } from '@/common/utils/const'
import { runCallback } from '@/common/utils/helpers'


const { 
  RULES, 
  CREATE_RULE, 
  RULE,
  CHANGE_STATUS_RULE, 
  UPDATE_RULE, 
  VIOLATION_TYPES 
} = QUERY_KEYS

/**
 * Fetch all rules for lot
 * @param lotId
 * @returns useQuery instance
 */
function useRules(lotId) {
  return useQuery(
    [ RULES, lotId ],
    () => lotEnforcementApi.getAllRules(lotId),
  )
}

/**
 * Fetch lot by id
 * @param ruleId
 * @returns useQuery instance
 */
function useRule(ruleId) {
  return useQuery(
    [ RULE, ruleId ],
    () => lotEnforcementApi.getRule(ruleId),
  )
}

/**
 * Get export file lots by type
 * @param type
 * @returns useQuery instance
 */
 function useEnforcementExport(lotId, successCallback) {
  return useMutation(
    (type) => lotEnforcementApi.export(lotId, type),{
      onSuccess: async (data) => {
        if(data.responseStatus === 200){
          const type = data.fileName.split('.')[1]
          successCallback({...data, type})
        }
      },
    }
  )
}

/**
 * Create rule
 * @param successCallback
 * @returns useMutation instance
 */
function useCreateRule(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => lotEnforcementApi.create(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(CREATE_RULE)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        runCallback(successCallback)
        await queryClient.refetchQueries(CREATE_RULE)
      }
    },
  })
}

/**
 * Create rules
 * @param successCallback
 * @returns useMutation instance
 */
 function useCreateRules(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => 
    lotEnforcementApi.createAllRules(data.lotId, data.rules), {
      onMutate: async () => {
        await queryClient.cancelQueries(CREATE_RULE)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          runCallback(successCallback)
          await queryClient.refetchQueries(CREATE_RULE)
        }
      },
    }
  )
}

/**
 * Update rule
 * @param successCallback
 * @returns useMutation instance
 */
function useUpdateRule(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => lotEnforcementApi.update(data.id, data), {
    onMutate: async () => {
      await queryClient.cancelQueries(RULE)
      await queryClient.cancelQueries(UPDATE_RULE)
    },
    onSuccess: async (data) => {
      if (data.responseStatus === 200) {
        await queryClient.refetchQueries(RULE)
        await queryClient.refetchQueries(UPDATE_RULE)
        runCallback(successCallback)
      }
    },
  })
}

/**
 * Update rules
 * @param successCallback
 * @returns useMutation instance
 */
 function useUpdateRules(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => 
    lotEnforcementApi.updateAllRules(data.lotId, data.rules), {
      onMutate: async () => {
        await queryClient.cancelQueries(RULE)
        await queryClient.cancelQueries(UPDATE_RULE)
      },
      onSuccess: async (data) => {
        if (data.responseStatus === 200) {
          await queryClient.refetchQueries(RULE)
          await queryClient.refetchQueries(UPDATE_RULE)
          runCallback(successCallback)
        }
      },
    }
  )
}

/**
 * Delete rule
 * @param successCallback
 * @returns useMutation instance
 */
function useDeleteRule(lotId, successCallback) {
  const queryClient = useQueryClient()

  return useMutation((id) => lotEnforcementApi.delete(lotId, id), {
    onMutate: async () => {
      await queryClient.cancelQueries(RULE)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(RULE)
      runCallback(successCallback)
    },
  })
}

/**
 * Delete rules
 * @param successCallback
 * @returns useMutation instance
 */
 function useDeleteRules(successCallback) {
  const queryClient = useQueryClient()

  return useMutation((data) => lotEnforcementApi.deleteAllRules(data), {
    onMutate: async () => {
      await queryClient.cancelQueries(RULE)
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(RULE)
      runCallback(successCallback)
    },
  })
}

/**
 * Change status for rule - isActive
 * @param ruleId
 * @returns useQuery instance
 */
 function useChangeStatus(lotId, successCallback) {
  const queryClient = useQueryClient()

  return useMutation((ruleId) => 
    lotEnforcementApi.changeStatus(lotId, ruleId), {
      onMutate: async () => {
        await queryClient.cancelQueries(CHANGE_STATUS_RULE)
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(CHANGE_STATUS_RULE)
        runCallback(successCallback)
      },
    }
  )
}

/**
 * Fetch all lot owners
 * @returns useQuery instance
 */
function useViolationTypes() {
  const setViolationTypes = useSetRecoilState(violationTypesAtom)

  return useQuery(
    [ VIOLATION_TYPES ],
    () => lotEnforcementApi.getViolationTypes(),
    {
      onSuccess: (data) => {
        let types = []
        if(data)
          for(let i = 0; i < data.length; i++)
            types = [ ...types, data[i] ]
        setViolationTypes(types)
      }
    }
  )
}

export {
  useRules,
  useRule,
  useEnforcementExport,
  useCreateRule,
  useCreateRules,
  useUpdateRule,
  useUpdateRules,
  useDeleteRule,
  useDeleteRules,
  useChangeStatus,
  useViolationTypes,
}