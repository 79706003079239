import { IconChevronDown, IconChevronDownMini } from '@/common/assets/icons'
import colors from '@/common/theme/variables/colors'

export const Custom = {
  control: (base, state) => {
    const backgroundColor = state.isDisabled ? colors.ghostWhite : colors.white
    return {
      ...base,

      fontSize: '14px',
      minHeight: '34px',
      boxShadow: '0px 1px 2px 0px #0000000D',
      backgroundColor,
      border: `1px solid ${colors.backgroundTabs}`,
      borderRadius: '6px',
      '&:hover': {
        borderColor: colors.lightBlue,
      },
      '@media only screen and (max-width: 768px)': {
        minHeight: '40px',
        fontSize: '16px',
      },
    }
  },

  singleValue: (base) => ({
    ...base,

    paddingLeft: '2px',
    color: `${colors.bgContent}`,
  }),

  menu: (provided) => ({
    ...provided,

    border: `1px solid ${colors.lavender}`,
    boxShadow: 'none',
    '&:hover': {
      borderColor: colors.lightBlue,
    },
  }),

  menuList: (provided) => ({
    ...provided,

    fontSize: '14px',
    cursor: 'pointer',
    padding: `1px 4px`,
    '@media only screen and (max-width: 768px)': {
      fontSize: '16px',
    },
  }),

  option: (provided, state) => {
    const backgroundColor = state.isSelected
      ? colors.backgroundBlue
      : colors.white
    const color = colors.bgContent

    return {
      ...provided,
      backgroundColor,
      color,

      padding: `4px 8px`,
      borderRadius: `3px`,
      margin: '1px 0px',
      '&:hover': {
        backgroundColor: colors.lightBlue,
        color: colors.white,
      },
    }
  },

  indicatorSeparator: (base) => ({
    ...base,

    display: 'none',
  }),

  dropdownIndicator: (base) => ({
    ...base,

    background: `url(${IconChevronDown}) center no-repeat`,
    marginRight: `13px`,
    marginTop: `2px`,
    svg: {
      display: 'none',
    },
  }),

  placeholder: (base) => ({
    ...base,

    fontSize: '14px',
  }),

  valueContainer: (base) => ({
    ...base,

    padding: '0px 8px',
  }),
}

export const CustomMini = {
  control: (base, state) => {
    const backgroundColor = state.isDisabled ? colors.ghostWhite : colors.white
    return {
      ...base,

      fontSize: '14px',
      minHeight: '34px',
      width: '70px',
      margin: '0 !important',
      boxShadow: 0,
      border: `1px solid ${colors.backgroundTabs}`,
      borderRadius: '6px',
      borderRight: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      backgroundColor,
      '&:hover': {
        borderColor: colors.backgroundTabs,
      },
      '@media only screen and (max-width: 768px)': {
        minHeight: '40px',
        fontSize: '16px',
      },
    }
  },

  singleValue: (base, state) => {
    const color = state.isDisabled ? colors.lightGrayM : colors.lightBlue

    return {
      ...base,

      paddingLeft: '2px',
      color,
    }
  },

  menu: (provided) => ({
    ...provided,

    border: `1px solid ${colors.lavender}`,
    width: '70px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: colors.lightBlue,
    },
  }),

  menuList: (provided) => ({
    ...provided,

    fontSize: '14px',
    padding: `1px 4px`,
    '@media only screen and (max-width: 768px)': {
      fontSize: '16px',
    },
  }),

  option: (provided, state) => {
    const backgroundColor = state.isSelected
      ? colors.backgroundBlue
      : state.isFocused
      ? colors.lightBlue
      : colors.white
    const color = colors.bgContent

    return {
      ...provided,
      backgroundColor,
      color,

      padding: `4px 8px`,
      borderRadius: `3px`,
      margin: '1px 0px',
      '&:hover': {
        backgroundColor: colors.lightBlue,
        color: colors.white,
      },
    }
  },

  indicatorSeparator: (base) => ({
    ...base,

    display: 'none',
  }),

  dropdownIndicator: (base) => ({
    ...base,

    background: `url(${IconChevronDownMini}) center no-repeat`,
    marginRight: `6px`,
    marginTop: `2px`,
    svg: {
      display: 'none',
    },
  }),

  placeholder: (base) => ({
    ...base,

    fontSize: '14px',
  }),

  valueContainer: (base) => ({
    ...base,

    padding: '0px 6px',
  }),
}
