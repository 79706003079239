import { withQuery } from '@/common/utils/helpers'

function registerLtpContractsApi(api) {
  return {
    getAll: (query) => api.get(withQuery(`/contracts`, query)),
    getContract: (id) => api.get(`/contracts/${id}`),
    create: (data) => api.post(`/contracts`, data),
    update: (id, data) => api.put(`/contracts/${id}`, data),
    delete: (id) => api.delete(`/contracts/${id}`),
    export: (query) =>
      api.get(withQuery(`/contracts/export`, query), { responseType: 'blob' }),
    sign: (id) => api.put(`/contracts/${id}/signcontract`),
    reject: (id, rejectReason) =>
      api.put(`/contracts/${id}/rejectcontract`, rejectReason),
    payContract: (data) => api.post(`/subscription/ltpcontract`, data),
    deleteSubscription: (id) => api.delete(`/subscription/ltpcontract/${id}`),
    getCustomPage: (lotId) =>
      api.get(`/custom-page-contracts/info/lot/${lotId}`),
    createCustomPage: (lotId, data, options) =>
      api.post(`/custom-page-contracts/lot/${lotId}`, data, options),
    deleteCustomPage: (lotId, query) =>
      api.delete(withQuery(`/custom-page-contracts/lot/${lotId}`, query)),
  }
}

export default registerLtpContractsApi
