import InputMask from 'react-input-mask'
import styled, { css } from 'styled-components'

import { inputStyle } from '@/common/theme/styles'

const WithRightElements = css`
  border-radius: 6px 0px 0px 6px;
`
const WithLeftElements = css`
border-radius: 0px 6px 6px 0px;
`

const BaseInput = styled(InputMask)`
  ${inputStyle};
  ${({ copy }) => copy && WithRightElements };
  ${({ withright }) => withright && WithRightElements };
  ${({ withleft }) => withleft && WithLeftElements };
  
`

export default BaseInput

