import { css } from 'styled-components'

const paddingStyle = css`
  ${({ $paddingTop }) => {
    return $paddingTop && `padding-top: ${$paddingTop}px;`
  }}
  ${({ $paddingRight }) => {
    return $paddingRight && `padding-right: ${$paddingRight}px;`
  }}
  ${({ $paddingBottom }) => {
    return $paddingBottom && `padding-bottom: ${$paddingBottom}px;`
  }}
  ${({ $paddingLeft }) => {
    return $paddingLeft && `padding-left: ${$paddingLeft}px;`
  }}
  ${({ $paddingHorizontal }) => {
    return (
      $paddingHorizontal &&
      `padding-left: ${$paddingHorizontal}px; 
       padding-right: ${$paddingHorizontal}px;
      `
    )
  }}
  ${({ $paddingVertical }) => {
    return (
      $paddingVertical &&
      `padding-bottom: ${$paddingVertical}px; 
      padding-top: ${$paddingVertical}px;`
    )
  }}
  ${({ $padding }) => {
    if (!$padding) {
      return null
    }
    return typeof $padding === 'number'
      ? `padding: ${$padding}px`
      : `padding: ${$padding}`
  }}
`

export default paddingStyle
