import styled from 'styled-components'

import colors from '@/common/theme/variables/colors'
import device from '@/common/theme/variables/mediaQuery'

export const AuthImageContainer = styled.div`
  width: 50%;
  height: 100%;

  @media ${device.tablet} {
    display: none;
  }
`

export const AuthContentWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${colors.white};
  margin-left: 140px;

  @media ${device.tablet} {
    width: 100%;
    margin: 0;
    align-items: center;
  }
`

export const AuthContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 20px;
  width: 100%;
`

export const AuthContentChildren = styled.div`
  margin: auto 0;
`

export const LogoContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 40px;
`

export const HeaderLogoContainer = styled.div`
  display: none;
  width: 100%;
  height: 64px;
  min-height: 64px;
  align-items: center;
  justify-self: flex-start;
  padding: 0 16px;
  img {
    width: 44px;
    height: 32px;
  }

  @media ${device.tablet} {
    display: flex;
  }
`

export const Container = styled.div`
  display: flex; 
  width: 100%; 
  height: 100vh; 
  background-color: ${colors.white};
`
