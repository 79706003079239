import styled from 'styled-components'

import { Row } from '@/common/baseStyledElements'
import { colors } from '@/common/theme/variables'

export const ButtonContainer = styled(Row)`
  align-items: center;

  :hover {
    cursor: pointer;
  }
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: ${colors.backgroundTabs};
  border-radius: 50%;

  > svg {
    stroke: ${({theme}) => theme?.backgroundTabs || colors.darkGray};
  }
`

export const ButtonText = styled.p`
  margin-left: 7px;
  color: ${({theme}) => theme?.backgroundTabs || colors.backgroundTabs};
  font-size: 16px;
  line-height: 16px;
`
